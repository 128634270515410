import {combineReducers} from 'redux';
import catReducer from './catReducer';
import subCatReducer from './subCatReducer';
import productsReducer from './productsReducer'
import brandsReducer from './brandsReducer'
import citiesReducer from './citiesReducer'
import ordersReducer from './orderdReducer'
import shippersReducer from './shippersReducer';
import adminsReducer from './adminsReducer'
import usersReducer from './usersReducer';
import authReducer from './authReucer';
import discountsReducer from './discountsReducer';
import couponsReducer from './couponsReducer';
import articleCategoriesReducer from './articleCatReducer';
import articlesReducer from './articlesReducer';
import productsDiscountReducer from './proDiscountsReducer';
import slidersReducer from './slidersReducer'
import termsReducer from './termsReducer';
import inquiriesReducer from './inquiriesReducer';
import competitionsReducer from './competitionsReducer';
import newsLetterReducer from './newsLetterReducer';
import cartsReducer from './cartsReducer';
import sellCodeReducer from './sellCodeReducer';

export default combineReducers({
    categories: catReducer,
    subCategories: subCatReducer,
    products: productsReducer,
    brands: brandsReducer,
    cities: citiesReducer,
    orders: ordersReducer,
    shippers: shippersReducer,
    admins: adminsReducer,
    users: usersReducer,
    login:authReducer,
    discounts: discountsReducer,
    coupons: couponsReducer,
    articleCategories: articleCategoriesReducer,
    articles: articlesReducer,
    productsDiscount: productsDiscountReducer,
    sliders: slidersReducer,
    terms: termsReducer,
    inquiries: inquiriesReducer,
    competitions: competitionsReducer,
    newsLetter: newsLetterReducer,
    carts: cartsReducer,
    sellCode: sellCodeReducer
});