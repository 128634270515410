import React from "react";
import Logo from "../../assets/Logo.png";
import { useTranslation } from "react-i18next";
import "../../I18next.js";
import i18n from "../../I18next.js";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/authActions";
import { setLoading } from "../../redux/actions/loading";
import LoginSpinner from "../../components/Spinner/LoginSpinner";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const lang = localStorage.getItem('i18nextLng');
  const loading = useSelector(state => state.login.loading)
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(setLoading());
    dispatch(login({ email: email, password: password }));
  }
  return (
    <div className="login-page-wrapper">
      <ToastContainer rtl={lang === 'ar'? true: false} />
      {localStorage.getItem("i18nextLng") === 'en' ? (
        <span
          className="login-language-switch"
          onClick={() => {
            i18n.changeLanguage("ar");
            window.location.reload();
          }}
        >
          العربية
        </span>
      ) : (
        <span
          className="login-language-switch"
          onClick={() => {
            i18n.changeLanguage("en");
            window.location.reload();
          }}
        >
          English
        </span>
      )}
      {
        loading ? <LoginSpinner /> :
          <div className="login-card">
            <div className="login-logo-wrapper">
              <img alt='img' src={Logo} className="login-logo" />
            </div>
            <div className="login-texts-wrapper">
              <h3 className="hello-login">{t("Login.Hello")},</h3>
              <h5 className="login-texts">{t("Login.PleaseLogin")}</h5>
              <h5 className="login-texts">{t("Login.YourAccount")}!</h5>
            </div>
            <form onSubmit={handleLogin}>
              <input
                required
                placeholder={t("Login.Email")}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control app-input col-sm-6 login-input"
                autoFocus
              ></input>
              <input
                required
                placeholder={t("Login.Password")}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control app-input col-sm-6 login-input"
              ></input>
              <button type='submit' className="login-button">{t('Login.SignIn')}</button>
            </form>
          </div>
      }
    </div>
  );
};

export default Login;
