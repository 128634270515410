import React from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router';
import { AiFillHeart, AiFillStar } from 'react-icons/ai';
import { Hint } from './Tooltips';

const BasePage = ({ 
    pageTitle, childs, modalContent, buttonTitle, addButton, modalTitle, viewMore, viewMorePath,
    addModalClosing, searchForm, setSearchContent, searchAction, ordersFilter, currentPage, setCurrentPage, bestSellers, addRedirect, addPath
}) => {
    const [show, setShow] = React.useState(false);
    const history = useHistory();
    const {searchQ, orderStatus} = useParams();
    const { t } = useTranslation();
    console.log('Modal', modalContent)
    const handleSubmit = (e) => {
        e.preventDefault();
        searchAction();
    }
    const handleFilterChange = (e) => {
        history.push(`/orders/${currentPage}/:${e.target.value}`)
        setCurrentPage(1)
    }
    const bestSellersHandling = () => {
        history.push(`/best-sellers/1`)
    }
    const featuredHandling = () => {
        history.push(`/featured/1`)
    }
    const handleViewMore = () => {
        history.push(viewMorePath)
    }
    return (
        <div className='base-page-wrapper'>
            <div className='title-button-wrapper'>
                <h3 className='base-page-title'>{pageTitle}</h3>
                {
                    searchForm ?
                        <div>
                            <form onSubmit={handleSubmit}>
                                <input defaultValue={searchQ} onChange={(e) => setSearchContent(e.target.value)} className='search-input' type='text' placeholder='Search' />
                                <button type='submit' className='search-button' ><FaSearch /></button>
                            </form>
                        </div> : null
                }
                {
                    ordersFilter ?
                        <select className='filter-select' defaultValue={orderStatus} onChange={handleFilterChange}>
                            <option value=' '>{t("Order.All")}</option>
                            <option value='accepted'>{t("Order.Accepted")}</option>
                            <option value='pending'>{t("Order.Pending")}</option>
                            <option value='preparing'>{t("Order.Preparing")}</option>
                            <option value='ready'>{t("Order.Ready")}</option>
                            <option value='refused'>{t("Order.Refused")}</option>
                            <option value='shipping'>{t("Order.Shipping")}</option>
                            <option value='fulfilled'>{t("Order.Fullifilled")}</option>
                        </select> : null
                }
                {
                    bestSellers ?
                        <div className='best-featured-wrapper'>
                            <Hint title='Bestsellers' childs={
                                <AiFillHeart onClick={() => bestSellersHandling()} className='edit-icon-button' />
                            } />
                            <Hint title='FeaturedProducts' childs={
                                <AiFillStar onClick={() => featuredHandling()} className='edit-icon-button' />
                            } />
                        </div>: null
                }
                {
                    addButton ?
                        <button onClick={() => {
                            setShow(1);
                            setTimeout(() => {
                                setShow(true)
                            }, [])
                        }} className='btn add-button'>
                            {t("Buttons.AddFirst")} {buttonTitle}
                        </button> : null
                }
                {
                    addRedirect ?
                        <button onClick={() => {
                            history.push(addPath)
                        }} className='btn add-button'>
                            {t("Buttons.AddFirst")} {buttonTitle}
                        </button> : null
                }
            </div>
            <div className='base-page'>
                {childs}
                {
                    viewMore?
                        <div className='view-more-wrapper'>
                            <div></div>
                            <div onClick={handleViewMore} className='view-more'>{t("HomePage.ViewMore")}</div>
                        </div> : null
                }
            </div>
            <Modal modalClosing={addModalClosing} trueShow={show} content={modalContent} modalTitle={modalTitle} />
        </div>
    )
}

export default BasePage;