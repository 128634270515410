import { GET_SHIPPERS, GET_SHIPPER_BY_ID, ADD_SHIPPER, SHIPPERS_LOADING } from '../actions/types'

const initialState = {
    shippers: [],
    shipper: [],
    updated: [],
    loading: true
}

const shippersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHIPPERS:
            return {
                ...state,
                shippers: action.payload,
                loading: false
            };
        case GET_SHIPPER_BY_ID:
            return {
                ...state,
                shipper: action.payload?.data?.shipper,
                loading: false
            };
        case ADD_SHIPPER:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case SHIPPERS_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default shippersReducer;