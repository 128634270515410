import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewShipperContent from './ViewShipperContent';

const ViewShipper = () => {
    const { t } = useTranslation();
    return (
        <SubPage
            childs={<ViewShipperContent />}
            pageTitle={t('Tooltips.ShipperProfile')}
        />
    )
}

export default ViewShipper;