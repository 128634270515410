import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import AddBrand from './AddBrand';
import BrandsTable from './BrandsContent';

const Brands = () => {
    const [show, setShow] = React.useState();
    const {t} = useTranslation();
    return(
        <BasePage 
            modalContent={<AddBrand setShow={setShow} />} 
            childs={<BrandsTable />} 
            pageTitle={t("SideBar.Brands")}
            addModalClosing={show}
            buttonTitle={t("Labels.Brands")}
            addButton
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Brands")}
        />
    )
}

export default Brands;