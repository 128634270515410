import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getArticles = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/article?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.ARTICLE_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ARTICLES,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getArticleById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/article/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_ARTICLE_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addArticle =
  (
    title,
    category,
    content,
    coverImage,
    cardImage,
    metaDescriptionEn,
    metaDescriptionAr,
    metaKeywordEn,
    metaKeywordAr
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("title", title);
    inputsData.append("category", category);
    inputsData.append("content", content);
    inputsData.append("coverImage", coverImage);
    inputsData.append("cardImage", cardImage);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);
    axios
      .post(`https://matjrna.co/api/v1/article`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_ARTICLE,
          payload: res
        });
        myToast(lang === "ar" ? "تم إضافة مقالة جديدة" : "New article has been added");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const editArticle =
  (
    id,
    title,
    category,
    content,
    coverImage,
    cardImage,
    old_coverImage,
    old_cardImage,
    metaDescriptionEn,
    metaDescriptionAr,
    metaKeywordEn,
    metaKeywordAr
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("title", title);
    inputsData.append("category", category);
    inputsData.append("content", content);
    inputsData.append("coverImage", coverImage);
    inputsData.append("cardImage", cardImage);
    inputsData.append("old_coverImage", old_coverImage);
    inputsData.append("old_cardImage", old_cardImage);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);
    axios
      .patch(`https://matjrna.co/api/v1/article/${id}`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_ARTICLE,
          payload: res
        });
        myToast(lang === "ar" ? "تم تعديل المقالة" : "Article has been edited");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const deleteArticle = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/articleCategory/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_ARTICLE,
        payload: res
      });
      myToast(lang === "ar" ? "تم  حذف المقالة" : "Article has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
