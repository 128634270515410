import React from 'react';
import BasePage from '../../components/BasePage';
import OrdersTable from '../Orders/OrdersContent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrders } from '../../redux/actions/ordersActions';

const LatestOrders = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [show, setShow] = React.useState(false);
    const orders = useSelector(state => state.orders.allOrders)
    const loading = useSelector(state => state.orders.loading)
    const allOrders = orders?.data?.orders;
    const pagesNum = orders?.pagesNum;
    const filterFive = allOrders?.filter((order, index) => index < 5);
    React.useEffect(() => {
        dispatch(getAllOrders(1, 1000000000))
    }, [dispatch])
    return (
        <BasePage
            childs={<OrdersTable show={show} setShow={setShow} currentPage={currentPage} setCurrentPage={setCurrentPage} allOrders={filterFive} loading={loading} pagesNum={pagesNum} />}
            pageTitle={t("HomePage.LatestOrders")}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            viewMore
            viewMorePath='orders/1/:'

        />
    )
}

export default LatestOrders;