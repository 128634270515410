import React from 'react';
import { MdDelete, MdVisibility } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Spinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal'
import { deleteCompetition, getCompetitions } from '../../redux/actions/competitionsActions';
import { Hint } from '../../components/Tooltips';

const CompetitionsContent = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [id, setId] = React.useState();
    const [deleteShow, setDeleteShow] = React.useState();
    const [paginated, setPaginated] = React.useState(false);
    const competitions = useSelector(state => state.competitions.competitions)
    const loading = useSelector(state => state.competitions.loading)
    const updated = useSelector(state => state.competitions.updated)
    const allCompetitions = competitions?.data?.docs;
    const pagesNum = competitions?.pagesNum
    React.useEffect(() => {
        dispatch(getCompetitions(currentPage))
    }, [currentPage, updated, dispatch])
    const handleDelete = (id) => {
        setId(id)
        setDeleteShow(true)
    }
    const viewSliderHandling = (id) => {
        history.push(`/view-competition/${id}`)
    }
    return (
        loading? <Spinner /> :
            <>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("Competitions.Name")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allCompetitions?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row.name}</td>
                                        <td className='table-cell' >
                                            <Hint title='DeleteCompetition' childs={
                                                <MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='ViewCompetition' childs={
                                                <MdVisibility onClick={() => viewSliderHandling(row._id)} className='edit-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <AppPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginated={paginated}
                    setPaginated={setPaginated}
                    pagesNum={pagesNum}
                />
                <DeleteModal 
                    itemId={id}
                    deleteItem={deleteCompetition}
                    content='Sliders'
                    show={deleteShow}
                    setShow={setDeleteShow}
                />
            </>
    )
}

export default CompetitionsContent;