import React from "react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Spinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";
import EditArticles from "./EditArticle";
import { getArticles, deleteArticle } from "../../redux/actions/articlesActions";
import { getArticleCats } from "../../redux/actions/articleCatActions";
import { Hint } from "../../components/Tooltips";

const ArticlesContent = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [id, setId] = React.useState();
  const [row, setRow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [paginated, setPaginated] = React.useState(false);
  const articles = useSelector((state) => state.articles.articles);
  const loading = useSelector((state) => state.articles.loading);
  const updated = useSelector((state) => state.articles.updated);
  const allArticles = articles?.data?.docs;
  const pagesNum = articles?.pagesNum;
  React.useEffect(() => {
    dispatch(getArticles(currentPage));
    dispatch(getArticleCats());
  }, [currentPage, updated, dispatch]);
  const handleDelete = (id) => {
    setId(id);
    setDeleteShow(true);
  };
  const viewSliderHandling = (id) => {
    history.push(`/view-article/${id}`);
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditArticles id={id} row={row} setShow={setShow} />}
          modalTitle='Edit Slider'
          modalClosing={setShow}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Slider.Title")}</th>
            <th scope='col'>{t("Article.Category")}</th>
            <th scope='col'>{t("Article.CreationDate")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allArticles?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/article/${row?.coverImage}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {row.title}
                </td>
                <td className='table-cell'>{lang === "en" ? row?.category?.nameEn : row?.category?.nameAr}</td>
                <td className='table-cell'>{new Date(row?.createdAt).toDateString()}</td>
                <td className='table-cell'>
                  <Hint
                    title='EditArticle'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setShow(1);
                          setId(row._id);
                          setRow(row);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteArticle'
                    childs={<MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />}
                  />{" "}
                  <Hint
                    title='ViewArticle'
                    childs={<MdVisibility onClick={() => viewSliderHandling(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal
        itemId={id}
        deleteItem={deleteArticle}
        modalTitle='Delete Slider'
        content='Delete Slider'
        show={deleteShow}
        setShow={setDeleteShow}
      />
    </>
  );
};

export default ArticlesContent;
