import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import DiscountsTable from './DiscountsContent';
import AddDiscount from './AddDiscount';

const Discounts = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddDiscount setShow={setShow} />} 
            childs={<DiscountsTable />} 
            pageTitle={t("SideBar.Discounts")}
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Discounts")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Discounts")}
        />
    )
}

export default Discounts;