import React from 'react';

const AppPagination = ({currentPage, setCurrentPage, pagesNum, paginated, setPaginated}) => {
    const [numbers, setNumbers] = React.useState([
        currentPage -3, currentPage -2, currentPage -1, currentPage, currentPage +1, currentPage +2, currentPage +3
    ], [currentPage])
    React.useEffect(() => {
        if(currentPage > 3) {
            setNumbers([
                 currentPage -2, currentPage -1, currentPage, currentPage +1, currentPage +2
            ])
        }
        if(currentPage === 3) {
            setNumbers([currentPage -2, currentPage-1, currentPage, currentPage +1, currentPage +2])
        }
        if(currentPage === 2) {
            setNumbers([currentPage-1, currentPage, currentPage +1, currentPage +2, currentPage +3])
        }
        if(currentPage === 1) {
            setNumbers([ currentPage, currentPage +1, currentPage +2, currentPage +3, currentPage+4])
        }
        if(currentPage === pagesNum) {
            setNumbers([currentPage -4, currentPage -3, currentPage-2, currentPage-1, currentPage])
        }
        if(currentPage === pagesNum -1) {
            setNumbers([currentPage-3, currentPage -2, currentPage-1, currentPage, currentPage+1])
        }
        if(currentPage === pagesNum -2) {
            setNumbers([ currentPage -2, currentPage -1, currentPage, currentPage +1, currentPage+2])
        }
        if (pagesNum === 1) {
            setNumbers([1])
        }
        if (pagesNum === 2) {
            setNumbers([1, 2])
        }
        if (pagesNum === 3) {
            setNumbers([1, 2, 3])
        }
        if (pagesNum === 4) {
            setNumbers([1, 2, 3, 4])
        }
    }, [currentPage, pagesNum])
    
    return (
        <div className="pagination">
            <span onClick={() => {
                if(currentPage > 1) {
                    setCurrentPage(currentPage-1)
                    setPaginated(true)
                }
                }}>&laquo;</span>
            {numbers.map(number => {
                return(
                    <span key={number} onClick={() => {
                        setCurrentPage(number)
                        setPaginated(true)
                    }}className={number === currentPage? 'active' : null}>{number}</span>
                )
            })}
            <span onClick={() => {
                if(currentPage < pagesNum) {
                    setCurrentPage(currentPage+1)
                    setPaginated(true)
                }
            }}>&raquo;</span>
        </div>
    )
}

export default AppPagination;