import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Hint = ({childs, title}) => {
    const {t} = useTranslation();
    const editTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t(`Tooltips.${title}`)}
        </Tooltip>
    );

    return(
        <OverlayTrigger placement='top' overlay={editTooltip}>
            {childs}
        </OverlayTrigger>
    )
}