import { LOADING, LOGIN, LOGIN_ERROR } from '../actions/types'

const initialState = {
     user: [],
     logged: false,
     loading: false
}

const brandsReducer = (state = initialState, action) => {
     switch (action.type) {
          case LOGIN:
               return {
                    ...state,
                    user: action.payload,
                    logged: true,
                    loading: false,
               };
          case LOGIN_ERROR:
               return {
                    ...state,
                    loading: false,
               };
          case LOADING:
               return {
                    ...state,
                    loading: true,
               };
          default:
               return state
     }

}

export default brandsReducer;