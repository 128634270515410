import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getCompetitions = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/competition?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.COMPETITIONS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_COMPETITIONS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getCompetitionById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/competition/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_COMPETITION_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addCompetition = (myPayload) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/competition`, myPayload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then((res) => {
      dispatch({
        type: types.ADD_COMPETITION,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة مسابقة جديدة" : "New competition has been added");
    })
    .catch((error) => myError(error?.response?.message));
};

export const editCompetition = (id, myPayload, notification) => (dispatch) => {
  axios
    .patch(`https://matjrna.co/api/v1/competition/${id}`, myPayload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then((res) => {
      dispatch({
        type: types.ADD_COMPETITION,
        payload: res
      });
      myToast(notification);
    })
    .catch((error) => myError(error?.response?.message));
};

export const editSlider = (id, title, description, link, mobileURL, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("title", title);
  inputsData.append("description", description);
  inputsData.append("link", link);
  inputsData.append("mobileURL", mobileURL);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/slider/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_SLIDER,
        payload: res
      });
      myToast("Slider has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteCompetition = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/comptetion/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then((res) => {
      dispatch({
        type: types.ADD_COMPETITION,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف المسابقة" : "Competition has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
