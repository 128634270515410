import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CoverImagePicker from "../../components/CoverImage";
import CardImagePicker from "../../components/CardImage";
import { addArticle } from "../../redux/actions/articlesActions";
import JoditEditor from "jodit-react";

const AddArticle = ({ setShow, id, row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const articleCats = useSelector((state) => state?.articleCategories?.articleCategories?.data?.docs);
  const firstArticle = articleCats ? articleCats[0]?._id : null;
  const [title, setTitle] = React.useState();
  const [category, setCategory] = React.useState(firstArticle);
  const [content, setContent] = React.useState();
  const [coverImage, setCoverImage] = React.useState();
  const [cardImage, setCardImage] = React.useState();
  const [metaKeywordEn, setMetaKeywordEn] = React.useState();
  const [metaKeywordAr, setMetaKeywordAr] = React.useState();
  const [metaDescriptionEn, setMetaDescriptionEn] = React.useState();
  const [metaDescriptionAr, setMetaDescriptionAr] = React.useState();
  const editor = React.useRef(null);
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addArticle(
        title,
        category,
        content,
        coverImage,
        cardImage,
        metaDescriptionEn,
        metaDescriptionAr,
        metaKeywordEn,
        metaKeywordAr
      )
    );
    setShow(1);
    setTimeout(() => {
      setShow(false);
    }, []);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-sm-6'>
          <label>{t("Slider.Title")}</label>
          <input
            placeholder={t("Slider.Title")}
            type='text'
            className='form-control app-input col-sm-6'
            autoFocus
            onChange={(e) => setTitle(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Article.Category")}</label>
          <select
            onChange={(e) => setCategory(e.target.value)}
            class='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'>
            {articleCats?.map((row, index) => {
              return (
                <option value={row._id} key={index}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-12'>
          <label>{t("Article.Content")}</label>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newcontentEn) => setContent(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
            onChange={(newcontentEn) => {}}
          />
        </div>
        <div className='col-sm-6'>
          <CoverImagePicker onChange={(e) => setCoverImage(e.target.files[0])} />
        </div>
        <div className='col-sm-6'>
          <CardImagePicker onChange={(e) => setCardImage(e.target.files[0])} />
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaDescriptionEn")}</label>
          <br></br>
          <label id='metaDescriptionEn' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaDescriptionEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaDescriptionEn").textContent = e.target.value.length)}
            onChange={(e) => setMetaDescriptionEn(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaDescriptionAr")}</label>
          <br></br>
          <label id='metaDescriptionAr' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaDescriptionAr")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaDescriptionAr").textContent = e.target.value.length)}
            onChange={(e) => setMetaDescriptionAr(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaKeywordEn")}</label>
          <br></br>
          <label id='metaKeywordEn' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaKeywordEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaKeywordEn").textContent = e.target.value.length)}
            onChange={(e) => setMetaKeywordEn(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaKeywordAr")}</label>
          <br></br>
          <label id='metaKeywordAr' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaKeywordAr")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaKeywordAr").textContent = e.target.value.length)}
            onChange={(e) => setMetaKeywordAr(e.target.value)}></input>
        </div>
      </div>
      <button className='save-button' type='submit'>
        {t("Buttons.Save")}
      </button>
    </form>
  );
};

export default AddArticle;
