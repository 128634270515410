import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";

export const getInquiries = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/inquiry?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.INQUIRIES_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_INQUIRIES,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getInquiryById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/inquiry/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_INQUIRY_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addTerm = (inputsData) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/term`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_TERM,
        payload: res
      });
      myToast("New term was added");
    })
    .catch((error) => myError(error?.response?.message));
};

export const answerInquiry = (id, answer, asnwerImage, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("answer", answer);
  inputsData.append("answerImage", asnwerImage);
  inputsData.append("old_answerImage", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/inquiry/${id}/answer`, inputsData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then((res) => {
      dispatch({
        type: types.ANSWER_INQUIRY,
        payload: res
      });
      myToast("Inquiry was answered");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteInquiry = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/inquiry/${id}`)
    .then((res) => {
      dispatch({
        type: types.ANSWER_INQUIRY,
        payload: res
      });
      myToast("Inquiry was deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
