import React from 'react';
import Modal from './Modal';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const SubPage = ({ show, setShow, pageTitle, childs, modalContent, buttonTitle, addButton, modalTitle, addModalClosing }) => {
    const history = useHistory();
    const {t} = useTranslation();
    const lang = localStorage.getItem('i18nextLng');
    return (
        <div className='base-page-wrapper'>
            <div className='title-button-wrapper'>
                <div>
                    <h3 className='base-page-title'> 
                        <span onClick={() => {history.goBack()}} className='back-button'>{lang === 'en' ? <MdArrowBack /> : <MdArrowForward />}</span> 
                        {pageTitle}
                    </h3>
                </div>
                <div>
                    {
                        addButton ?
                            <button onClick={() => {
                                setShow(1);
                                setTimeout(() => {
                                    setShow(true)
                                }, [])
                            }} className='btn add-button'>
                                {t("Buttons.AddFirst")} {t("Labels.Products")}
                            </button> : null
                    }
                </div>
            </div>
            <div className='base-page'>
                {childs}
            </div>
            <Modal trueShow={show} modalClosing={addModalClosing} content={modalContent} modalTitle={modalTitle} />
        </div>
    )
}

export default SubPage;