import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addArticleCategory } from '../../redux/actions/articleCatActions';


const AddCategory = ({ setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [nameEn, setNameEn] = React.useState();
    const [nameAr, setNameAr] = React.useState();
    const [image, setImage] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addArticleCategory(nameEn, nameAr, image))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Category.NameAr")}</label>
                    <input
                        placeholder={t("Category.NameAr")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setNameAr(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Category.NameEn")}</label>
                    <input
                        placeholder={t("Category.NameEn")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setNameEn(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default AddCategory;