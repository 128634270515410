import {
    BsFillGridFill, BsFillGrid3X3GapFill, BsGiftFill, BsFileText, BsFillPersonFill, BsBuilding, 
    BsFillPeopleFill, BsFillFlagFill
} from 'react-icons/bs';
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {SiBrandfolder} from 'react-icons/si'
import { MdDirectionsBike } from 'react-icons/md'
import {FaCoins, FaPercentage, FaThumbsUp} from 'react-icons/fa';
import { IoMdFootball, IoMdNotificationsOutline } from 'react-icons/io'
import { GiTrophyCup } from 'react-icons/gi'
import { RiArticleFill } from 'react-icons/ri';
import { GoLaw } from 'react-icons/go';
import { TiShoppingCart } from 'react-icons/ti'

const lang = localStorage.getItem('i18nextLng');
export const SideData = [
    {
        title:lang === 'en'? 'Main Categories' : 'الأقسام الرئيسية',
        icon: BsFillGridFill,
        path: `/categories/1`
    },
    {
        title: lang === 'en' ? 'Subcategories' : 'الأقسام الفرعية',
        icon: BsFillGrid3X3GapFill,
        path: '/subcategories/1'
    },
    {
        title: lang === 'en' ? 'Products' : 'المنتجات',
        icon: BsGiftFill,
        path: '/products/1'
    },
    {
        title: lang === 'en' ? 'Orders' : 'الطلبات',
        icon: BsFileText,
        path: `/orders/1/:`
    },
    {
        title: lang === 'en' ? 'Customers' : 'العملاء',
        icon: BsFillPersonFill,
        path: '/customers/1'
    },
    {
        title: lang === 'en' ? 'Cities' : 'المدن',
        icon: BsBuilding,
        path: '/cities/1'
    },
    {
        title: lang === 'en' ? 'Admins' : 'المشرفين',
        icon: BsFillPeopleFill,
        path: '/admins/1'
    },
    {
        title: lang === 'en' ? 'Shippers' : 'موظفين الشحن',
        icon: MdDirectionsBike,
        path: '/shippers/1'
    },
    {
        title: lang === 'en' ? 'Abandoned Carts' : 'السلات المهملة',
        icon: TiShoppingCart,
        path: '/carts'
    },
    {
        title: lang === 'en' ? 'Brands' : 'العلامات التجارية',
        icon: SiBrandfolder,
        path: '/brands/1'
    },
    {
        title: lang === 'en' ? 'Discounts' : 'الخصومات',
        icon: FaPercentage,
        path: '/discounts/1'
    },
    {
        title: lang === 'en' ? 'Coupons' : 'اكواد الخصم',
        icon: FaCoins,
        path: '/coupons/1'
    },
    {
        title: lang === 'en' ? 'Discounts Products' : 'منتجات الخصومات',
        icon: FaThumbsUp,
        path: '/discounts-products/1'
    },
    {
        title: lang === 'en' ? 'Sliders' : 'البنارات الاعلانية',
        icon: BsFillFlagFill,
        path: '/sliders/1'
    },
    {
        title: lang === 'en' ? 'Article Categories' : 'اقسام المقالات',
        icon: IoMdFootball,
        path: '/article-categories/1'
    },
    {
        title: lang === 'en' ? 'Articles' : 'المقالات',
        icon: RiArticleFill,
        path: '/articles/1'
    },
    {
        title: lang === 'en' ? 'Terms & Conditions' : 'الشروط والاحكام',
        icon: GoLaw,
        path: '/terms/1'
    },
    {
        title: lang === 'en' ? 'Notifications' : 'الإشعارات',
        icon: IoMdNotificationsOutline,
        path: '/notifications'
    },
    {
        title: lang === 'en' ? 'Inquiries' : 'الاستفسارات',
        icon: AiOutlineQuestionCircle,
        path: '/inquiries/1'
    },
    {
        title: lang === 'en' ? 'Competitions' : 'المسابقات',
        icon: GiTrophyCup,
        path: '/competitions/1'
    }
]