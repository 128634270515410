import React from 'react'
import { connect } from 'react-redux';
import { useState } from 'react';
import { addCompetition } from '../../redux/actions/competitionsActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'


function UpdateCat({ ids, addCompetition, setShow, term }) {
    React.useEffect(() => {
        //console.log('The id s', ids)
    }, [])
    const { t } = useTranslation();


    // update ----------------------
    const [name, setName] = useState();
    const [header, setHeader] = useState();
    const [answer, setAnswer] = useState('');
    const [answers, setAnswers] = useState([])
    const [question, setQuestion] = useState()
    const [allQuestions, setAllQuestions] = useState([])
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();


    const setInput = setter => event => {
        setter(event.currentTarget.value)
    }

    toast.configure()

    const addToTodo = () => {
        if (answer === '') {
            return
        } else {
            answers.push(answer)
            console.log(answers)
            setAnswer('')
        }
    }

    const handleDeleteItem = (answ) => {
        setAnswers(answers.filter(val => val !== answ))
        console.log('New Arr', answers)
    }

    const handleDeleteQuestion = (answ) => {
        setAllQuestions(allQuestions.filter(val => val !== answ))
        console.log('New Arr', answers)
    }

    const myPayload = {
        name: name,
        questions: allQuestions,
        startDate: startDate,
        endDate: endDate
    }


    const updateData = (e) => {
        e.preventDefault();
        addCompetition(myPayload)
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
        //console.log('ID Is', ids)
    }

    const lang = localStorage.getItem('i18nextLng')

    const handleCheckboxChange = (e) => {
            answers.map(correctAnswer => correctAnswer.content === e.target.value ? correctAnswer.is_right = true : console.log('no'))
            answers.map(correctAnswer => correctAnswer.content !== e.target.value ? correctAnswer.is_right = false : console.log('no'))
    }

    const questionForm = (
        <>
            <div item xs={12}>
                <label>{t("Competitions.Question")}</label>
                <input className='form-control app-input' fullWidth placeholder={t('Competitions.Question')}
                    onChange={setInput(setHeader)} autoComplete="nameAr" value={header} />
            </div>
            
            <label>{t("Competitions.Answer")}</label>
            <div className='add-input-wrapper'>
                <input className='form-control app-input' fullWidth placeholder={t('Competitions.Answer')} value={answer.content}
                    onChange={(e) => { setAnswer({ content: e.target.value }) }} autoComplete="nameAr" />
                <button 
                    id='addbutton'
                    className='add-answer-button'
                    type='button'
                    onClick={() => {
                        addToTodo();
                        setQuestion({ header: header, answers: answers });
                        setAnswer({ content: '' })
                    }}
                >
                    {t('Actions.Add')}
                </button>
            </div>
            <div item xs={12}>
                {
                    answers?.map((answ) => {
                        return (
                            <span className='text-center' style={{ marginRight: '15px', fontSize: '16px' }}>
                                {answ.content}
                                <input value={answ.content} className='comp-checkbox' type='radio' name='add-ques' onClick={handleCheckboxChange} />
                                <span onClick={() => { handleDeleteItem(answ) }}
                                    style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}> &times;
                                </span>
                            </span>
                        )
                    })
                }
            </div>
            <p className='text-center'>Questions</p>
            <div item xs={12}>
                {
                    allQuestions?.map((answ) => {
                        return (
                            <div>
                                <span style={{ marginRight: '15px', fontSize: '16px' }}>
                                    {answ.header}
                                </span>
                                <span onClick={() => { handleDeleteQuestion(answ) }}
                                    style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}> &times;
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            <div className='submit-wrapper'>
                <button
                    type='button'
                    className='submit-ques'
                    onClick={() => {
                        allQuestions.push(question)
                        console.log(allQuestions)
                        setHeader('')
                        setAnswers([])
                    }}
                    style={{ marginTop: '20px' }}
                >
                    Submit Question
                </button>
            </div>
        </>
    )
    

    return (
        <form
            style={
                lang === "en"
                    ? { maxWidth: 600 }
                    : { maxWidth: 600, direction: "rtl" }
            }
            className="add-form"
            onSubmit={updateData}
        >
            {/* {sendData} */}
            <div container spacing={2}>
                <div item xs={12}>
                    <label>{t("Competitions.Name")}</label>
                    <input
                        className='form-control app-input'
                        fullWidth
                        placeholder={t("Competitions.Name")}
                        defaultValue={name}
                        onChange={setInput(setName)}
                        autoComplete="nameEn"
                        autoFocus
                    />
                </div>

                {questionForm}

                <div item xs={12} sm={6}>
                    <label>{t("Discounts.From")}</label>
                    <input
                        className='form-control app-input'
                        id="datetime-local"
                        placeholder={t("Discounts.From")}
                        type="datetime-local"
                        onChange={(e) => {
                            setStartDate(e.target.value);
                        }}
                        InputplaceholderProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div item xs={12} sm={6}>
                    <label>{t("Discounts.To")}</label>
                    <input
                        className='form-control app-input'
                        id="datetime-local"
                        placeholder={t("Discounts.To")}
                        type="datetime-local"
                        onChange={(e) => {
                            setEndDate(e.target.value);
                        }}
                        InputplaceholderProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div>
                    <div>
                        {/* {update()} */}
                        <button
                            variant="contained"
                            color="primary"
                            type="submit"
                            id="formbutton"
                            className='save-button'
                        // type="button"
                        >
                            {t("Buttons.Save")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = state => ({
    term: state.terms.term,
    loading: state.terms.loading
})

export default connect(mapStateToProps, { addCompetition })(UpdateCat)