import { myError, myToast } from "../../components/Toast";
import axios from "axios";
const lang = localStorage.getItem("i18nextLng");

export const addNotification = (data) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/notification/send-to-all`, data)
    .then((res) => {
      myToast(lang === "ar" ? "تم إضافة الاشعار" : "Notification has been added");
    })
    .catch((res) => {
      myError("Notification Error");
    });
};

/*export const deleteTerm = id =>  dispatch => {

      fetch(`https://matjrna.co/api/v1/term/${id}`, {
         method: 'DELETE',
         })
         .then(res => toast.error(<CustomDeleteToast />, {
          position: toast.POSITION.BOTTOM_RIGHT,
          style: {background: '#FFF', color: '#000', borderRadius: '5px'},
          progressStyle: {color: '#1976d2', background: '#1976d2'},
          }))
         .then(_ =>
           dispatch({
             type: DELETE_TERM,
               id
           }),
         )
         .catch(error => toast.error(<CustomErrorToast />, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {background: '#FFF', color: '#000', borderRadius: '5px'},
                    progressStyle: {color: '#F84C5A', background: '#F84C5A'},
               }))
}

export function updateTerm(payload){
     return function(dispatch) {
          fetch(`https://matjrna.co/api/v1/term/${payload.ids}`, {
               method: 'PATCH', //'PATCH' 'PUT',
               body: JSON.stringify(payload.cat),
               headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                  }           
          })
          .then(_ =>{dispatch({type: UPDATE_TERM,payload})})
          .then(res => toast.error(<CustomEditToast />, {
               position: toast.POSITION.BOTTOM_RIGHT,
               style: {background: '#FFF', color: '#000', borderRadius: '5px'},
               progressStyle: {color: '#1976d2', background: '#1976d2'},
               }))
          .catch(error => toast.error(<CustomErrorToast />, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {background: '#FFF', color: '#000', borderRadius: '5px'},
                    progressStyle: {color: '#F84C5A', background: '#F84C5A'},
               }))
     }
     
}*/
