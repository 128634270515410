import React from 'react';
import { useTranslation } from 'react-i18next';
import SubPage from '../../components/SubPage';
import EditPromoCodeContent from './EditPromoCodeContent';

const EditPromoCode = () => {
    const {t} = useTranslation();
    return (
        <SubPage
            childs={<EditPromoCodeContent />}
            pageTitle={t("Tooltips.EditCoupon")}
        />
    )
}

export default EditPromoCode;