import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewTermContent from './ViewTermContent';

const ViewTerm = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewTermContent />} 
            pageTitle={t('Tooltips.ViewTerm')}
        />
    )
}

export default ViewTerm;