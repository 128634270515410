import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import NotificationsContent from './NotificationsContent';

const Notifications = () => {
    const { t } = useTranslation();
    return (
        <BasePage
            childs={<NotificationsContent />}
            pageTitle={t("SideBar.Notifications")}
        />
    )
}

export default Notifications;