import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ViewProductContent from './ViewProductContent';
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';

const ViewProduct = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewProductContent />} 
            pageTitle={t("Tooltips.ViewProduct")}
        />
    )
}

export default ViewProduct;