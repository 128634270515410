import React from 'react';
import { MdEdit, MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCoupon, getCoupons } from '../../redux/actions/couponsActions';
import Spinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal';
import { useParams, useHistory } from 'react-router';
import { Hint } from '../../components/Tooltips';

const CouponsTable = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {pageNum} = useParams();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [paginated, setPaginated] = React.useState(false);
    const coupons = useSelector(state => state.coupons.coupons)
    const loading = useSelector(state => state.coupons.loading)
    const updated = useSelector(state => state.coupons.updated)
    const [id, setId] = React.useState(1);
    const [deleteShow, setDeleteShow] = React.useState();
    const allCoupons = coupons?.data?.docs;
    const pagesNum = coupons?.pagesNum;
    React.useEffect(() => {
        if (paginated) {
            history.push(`/coupons/${currentPage}`)
        }
    }, [currentPage, paginated, history])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    React.useEffect(() => {
        dispatch(getCoupons(currentPage))
    }, [currentPage, updated, dispatch])
    const hanadleDelete = (id) => {
        setId(id)
        setDeleteShow(true)
    }
    return (
        loading? <Spinner /> : 
            <>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("Admin.Name")}</th>
                            <th scope="col">{t("Discounts.Limit")}</th>
                            <th scope="col">{t("Discounts.Usage")}</th>
                            <th scope="col">{t("Discounts.Type")}</th>
                            <th scope="col">{t("Discounts.Value")}</th>
                            <th scope="col">{t("Discounts.From")}</th>
                            <th scope="col">{t("Discounts.To")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allCoupons?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row.name}</td>
                                        <td className='table-cell' >{row.limit}</td>
                                        <td className='table-cell' >{row.usage}</td>
                                        <td className='table-cell' >{row.type}</td>
                                        <td className='table-cell' >{row.value}</td>
                                        <td className='table-cell' >{new Date(row?.startDate).toDateString()}</td>
                                        <td className='table-cell' >{new Date(row?.endDate).toDateString()}</td>
                                        <td className='table-cell' >
                                            <Hint title='EditCoupon' childs={
                                                <MdEdit onClick={() => {
                                                    history.push(`/edit-promo/${row._id}`)
                                                }} className='edit-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='DeleteCoupon' childs={
                                                <MdDelete onClick={() => hanadleDelete(row._id)} className='delete-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <AppPagination currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} paginated={paginated} setPaginated={setPaginated} />
                <DeleteModal
                    itemId={id}
                    deleteItem={deleteCoupon}
                    content='Coupons'
                    show={deleteShow}
                    setShow={setDeleteShow}
                />
            </>
    )
}

export default CouponsTable;