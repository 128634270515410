import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import SlidersContent from './SlidersContent';
import AddSliders from './AddSlider';

const Sliders = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
   
    return(
        <BasePage 
            modalContent={<AddSliders setShow={setShow} />}
            childs={<SlidersContent />} 
            pageTitle={t("SideBar.Sliders")} 
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Sliders")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Sliders")}
        />
    )
}

export default Sliders;