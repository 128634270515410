import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import DiscountsProductsTable from './DiscountsProductsContent';

const DiscountsProducts = () => {
    const {t} = useTranslation();
    return(
        <BasePage 
            childs={<DiscountsProductsTable />} 
            pageTitle={t("SideBar.ProductsDiscounts")}
        />
    )
}

export default DiscountsProducts;