import React from "react";
import ImagePicker from "../../components/ImagePicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addCat } from "../../redux/actions/catActions";

const AddCategory = ({ setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nameEn, setNameEn] = React.useState();
  const [nameAr, setNameAr] = React.useState();
  const [image, setImage] = React.useState();
  const [active, setActive] = React.useState(true);
  const [metaKeywordEn, setMetaKeywordEn] = React.useState();
  const [metaKeywordAr, setMetaKeywordAr] = React.useState();
  const [metaDescriptionEn, setMetaDescriptionEn] = React.useState();
  const [metaDescriptionAr, setMetaDescriptionAr] = React.useState();

  const type = "main";
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addCat(nameEn, nameAr, type, image, active, metaDescriptionEn, metaDescriptionAr, metaKeywordEn, metaKeywordAr)
    );
    setShow(1);
    setTimeout(() => {
      setShow(false);
    }, []);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-sm-6'>
          <label>{t("Category.NameAr")}</label>
          <input
            placeholder={t("Category.NameAr")}
            type='text'
            className='form-control app-input col-sm-6'
            autoFocus
            onChange={(e) => setNameAr(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Category.NameEn")}</label>
          <input
            placeholder={t("Category.NameEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setNameEn(e.target.value)}></input>
        </div>
        <div className='col-sm-5 my-3'>
          <div className='toggle-button-wrapper'>
            <label class='switch'>
              <input defaultChecked={active} onClick={() => setActive(!active)} type='checkbox' />
              <span class='slider round'></span>
            </label>
            <span className='active-coupon'>{t("Category.Active")}</span>
          </div>
        </div>
        <div className='col-sm-12'>
          <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
        </div>
      </div>
      <div className='col-sm-12'>
        <label>{t("Product.MetaDescriptionEn")}</label>
        <br></br>
        <label id='metaDescriptionEn' className='text-muted'>
          0
        </label>
        <input
          placeholder={t("Product.MetaDescriptionEn")}
          type='text'
          className='form-control app-input col-sm-6'
          onKeyUp={(e) => (document.querySelector("#metaDescriptionEn").textContent = e.target.value.length)}
          onChange={(e) => setMetaDescriptionEn(e.target.value)}></input>
      </div>
      <div className='col-sm-12'>
        <label>{t("Product.MetaDescriptionAr")}</label>
        <br></br>
        <label id='metaDescriptionAr' className='text-muted'>
          0
        </label>
        <input
          placeholder={t("Product.MetaDescriptionAr")}
          type='text'
          className='form-control app-input col-sm-6'
          onKeyUp={(e) => (document.querySelector("#metaDescriptionAr").textContent = e.target.value.length)}
          onChange={(e) => setMetaDescriptionAr(e.target.value)}></input>
      </div>
      <div className='col-sm-12'>
        <label>{t("Product.MetaKeywordEn")}</label>
        <br></br>
        <label id='metaKeywordEn' className='text-muted'>
          0
        </label>
        <input
          placeholder={t("Product.MetaKeywordEn")}
          type='text'
          className='form-control app-input col-sm-6'
          onKeyUp={(e) => (document.querySelector("#metaKeywordEn").textContent = e.target.value.length)}
          onChange={(e) => setMetaKeywordEn(e.target.value)}></input>
      </div>
      <div className='col-sm-12'>
        <label>{t("Product.MetaKeywordAr")}</label>
        <br></br>
        <label id='metaKeywordAr' className='text-muted'>
          0
        </label>
        <input
          placeholder={t("Product.MetaKeywordAr")}
          type='text'
          className='form-control app-input col-sm-6'
          onKeyUp={(e) => (document.querySelector("#metaKeywordAr").textContent = e.target.value.length)}
          onChange={(e) => setMetaKeywordAr(e.target.value)}></input>
      </div>

      <button className='save-button' type='submit'>
        {t("Buttons.Save")}
      </button>
    </form>
  );
};

export default AddCategory;
