import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getUsers = (currentPage, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/user?page=${currentPage}&limit=${limit}&sort=descending`)
    .then((res) => {
      dispatch({
        type: types.USERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_USERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getUserById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/user/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_USER_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteUser = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/user/${id}`)
    .then((res) => {
      dispatch({
        type: types.DELETE_USER,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف المستخدم" : "User has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
