import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Categories from './modules/Categories/Categories';
import Products from './modules/Products/Products';
import SubCategories from './modules/Subcategories/SubCategories';
import Orders from './modules/Orders/Orders'
import Customers from './modules/Customers/Customers';
import Cities from './modules/Cities/Cities';
import Admins from './modules/Admins/Admins';
import Shippers from './modules/Shippers/Shippers';
import Brands from './modules/Brands/Brands'
import Discounts from './modules/Discounts/Discounts';
import Coupons from './modules/Coupons/Coupons';
import DiscountsProducts from './modules/DiscountsProducts/DiscountsProducts';
import ViewProduct from './modules/Products/ViewProduct';
import ViewOrder from './modules/Orders/ViewOrder';
import ViewInquiry from './modules/Inquiries/ViewInquiry';
import ViewAdmin from './modules/Admins/ViewAdmin';
import Sliders from './modules/Sliders/Sliders';
import SellCode from './modules/Products/SellCode';
import MainCategoryProducts from './modules/Categories/MainCategoryProducts';
import SubCategoryProducts from './modules/Subcategories/SubCategoryProducts';
import MainSub from './modules/Categories/MainSub';
import MainSubProducts from './modules/Categories/MainSubProducts';
import SearchProducts from './modules/Products/SearchProducts';
import ViewShipper from './modules/Shippers/ViewShipper';
import ViewSlider from './modules/Sliders/ViewSlider';
import ArticleCategories from './modules/ArticleCategories/Categories';
import ViewCustomer from './modules/Customers/ViewCustomer';
import Articles from './modules/Articles/Articles'
import ViewArticle from './modules/Articles/ViewArticle';
import Notifications from './modules/Notifications/Notifications';
import Terms from './modules/Terms/Terms';
import ViewTerm from './modules/Terms/ViewTerm';
import Inquiries from './modules/Inquiries/Inquiries';
import Competitions from './modules/Competitions/Competitions';
import ViewCompetition from './modules/Competitions/ViewCompetition';
import BestSellers from './modules/Products/Bestsellers';
import HomePage from './modules/HomePage/HomePage';
import TodayOrders from './modules/Orders/TodayOrders';
import FeaturedProducts from './modules/Products/FeaturedProducts';
import AddPromoCode from './modules/Coupons/AddPromoCode';
import Carts from './modules/AbandonedCarts/Carts';
import EditPromoCode from './modules/Coupons/EditPromoCode';
import AddTerm from './modules/Terms/AddTerm';
import EditTerm from './modules/Terms/EditTerm';
import AddProduct from './modules/Products/AddProduct';
import ViewCart from './modules/AbandonedCarts/ViewCart';

const Routes = () => {
    return(
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/categories/:pageNum' component={Categories} />
                <Route exact path='/subcategories/:pageNum' component={SubCategories} />
                <Route exact path='/article-categories/:pageNum' component={ArticleCategories} />
                <Route exact path='/products/:pageNum' component={Products} />
                <Route exact path='/orders/:pageNum/:orderStatus' component={Orders} />
                <Route exact path='/customers/:pageNum' component={Customers} />
                <Route exact path='/carts' component={Carts} />
                <Route exact path='/inquiries/:pageNum' component={Inquiries} />
                <Route exact path='/competitions/:pageNum' component={Competitions} />
                <Route exact path='/best-sellers/:pageNum' component={BestSellers} />
                <Route exact path='/featured/:pageNum' component={FeaturedProducts} />
                <Route exact path='/cities/:pageNum' component={Cities} />
                <Route exact path='/admins/:pageNum' component={Admins} />
                <Route exact path='/articles/:pageNum' component={Articles} />
                <Route exact path='/shippers/:pageNum' component={Shippers} />
                <Route exact path='/terms/:pageNum' component={Terms} />
                <Route exact path='/notifications' component={Notifications} />
                <Route exact path='/brands/:pageNum' component={Brands} />
                <Route exact path='/discounts/:pageNum' component={Discounts} />
                <Route exact path='/coupons/:pageNum' component={Coupons} />
                <Route exact path='/discounts-products/:pageNum' component={DiscountsProducts} />
                <Route exact path='/view-product/:productId' component={ViewProduct} />
                <Route exact path='/view-order/:orderId' component={ViewOrder} />
                <Route exact path='/view-customer/:userId' component={ViewCustomer} />
                <Route exact path='/view-inquiry/:inquiryId' component={ViewInquiry} />
                <Route exact path='/view-admin/:adminId' component={ViewAdmin} />
                <Route exact path='/view-shipper/:shipperId' component={ViewShipper} />
                <Route exact path='/view-term/:termId' component={ViewTerm} />
                <Route exact path='/view-slider/:sliderId' component={ViewSlider} />
                <Route exact path='/view-competition/:competitionId' component={ViewCompetition} />
                <Route exact path='/view-article/:articleId' component={ViewArticle} />
                <Route exact path='/sliders/:pageNum' component={Sliders} />
                <Route exact path='/sell-code/:sellCode/:pageNum' component={SellCode} />
                <Route exact path='/category/products/:mainId' component={MainCategoryProducts} />
                <Route exact path='/subcategory/products/:subId' component={SubCategoryProducts} />
                <Route exact path='/category/:mainId/subcategories' component={MainSub} />
                <Route exact path='/category/:mainId/subcategory/:subId/products' component={MainSubProducts} />
                <Route exact path='/products/search-about/:searchQ/:pageNum' component={SearchProducts} />
                <Route exact path='/add-promo' component={AddPromoCode} />
                <Route exact path='/edit-promo/:promoId' component={EditPromoCode} />
                <Route exact path='/today-orders' component={TodayOrders} />
                <Route exact path='/add-term' component={AddTerm} />
                <Route exact path='/edit-term/:termId' component={EditTerm} />
                <Route exact path='/add-product' component={AddProduct} />
                <Route exact path='/view-cart/:cartId' component={ViewCart} />
            </Switch>
    )
}

export default Routes;