import React from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';
import { BsGiftFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal'
import EditSubCategory from '../Subcategories/EditSubCategory';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubCat, getSubInMain } from '../../redux/actions/subCatActions';
import AppSpinner from '../../components/Spinner/Spinner';
import { getCatById, getCats } from '../../redux/actions/catActions';
import DeleteModal from '../../components/DeleteModal';
import { useHistory, useParams } from 'react-router';
import { setLoading } from '../../redux/actions/loading';
import { Hint } from '../../components/Tooltips';

const MainSubContent = () => {
    const { t } = useTranslation();
    const {mainId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [id, setId] = React.useState(1);
    const [row, setRow] = React.useState();
    const subCategories = useSelector(state => state.subCategories.subForMain)
    const loading = useSelector(state => state.subCategories.loading)
    const updated = useSelector(state => state.subCategories.updated)
    const allSubCategories = subCategories?.data?.subCategories;
    const pagesNum = subCategories?.pagesNum;
    const [show, setShow] = React.useState();
    const [deleteShow, setDeleteShow] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getSubInMain(mainId, currentPage))
        dispatch(getCats())
        dispatch(getCatById(mainId))
    }, [currentPage, updated, dispatch, mainId])
    const productsFilter = (id) => {
        history.push(`/category/${mainId}/subcategory/${id}/products`)
    }
    return (
        loading ? <AppSpinner /> :
            <>
                <table className="table table-hover">
                    <Modal
                        trueShow={show}
                        content={<EditSubCategory id={id} row={row} setShow={setShow} />}
                        modalTitle={t("Actions.Edit") + ' ' + t('Labels.SubCategories')}
                        modalClosing={show}
                    />
                    <thead>
                        <tr>
                            <th scope="col">{t("Category.Name")}</th>
                            <th scope="col">{t("Category.Type")}</th>
                            <th scope="col">{t("Module.MainCategories")}</th>
                            <th scope="col">{t("Category.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allSubCategories?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' > <img alt='img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png' className='table-image rounded-circle"' />
                                            {' '} {localStorage.getItem('i18nextLng') === 'en' ? row.nameEn : row.nameAr}</td>
                                        <td className='table-cell' >{row.type}</td>
                                        <td className='table-cell' >{localStorage.getItem('i18nextLng') === 'en' ? row?.parentId?.nameEn : row?.parentId?.nameAr}</td>
                                        <td className='table-cell' >
                                            <Hint title='EditSubcategory' childs={
                                                <MdEdit onClick={() => {
                                                    setShow(1)
                                                    setRow(row)
                                                    setId(row._id)
                                                    setTimeout(() => {
                                                        setShow(true)
                                                    }, [])
                                                }} className='edit-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='DeleteSubcategory' childs={
                                                <MdDelete onClick={() => {
                                                    setId(row._id)
                                                    setDeleteShow(true)
                                                }} className='delete-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='Products' childs={
                                                <BsGiftFill onClick={() => productsFilter(row._id)} className='edit-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <AppPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginated={paginated}
                    setPaginated={setPaginated}
                    pagesNum={pagesNum}
                />
                <DeleteModal
                    content='ArticleCategories'
                    show={deleteShow} setShow={setDeleteShow}
                    itemId={id}
                    deleteItem={deleteSubCat}
                />
            </>
    )
}

export default MainSubContent;