import axios from "axios";
import { myError } from "../../components/Toast";
import * as types from "./types";

export const getCarts = () => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/cart/get-abandoned-carts`)
    .then((res) => {
      dispatch({
        type: types.CARTS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_CARTS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getCartById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/cart/get-abandoned-cart/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_CART_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};
