import React from 'react';
import './spinner.css'
function LoginSpinner() {
    return (
        <div className='spinner-wrapper'>
            <div class="login-loader">Loading...</div>
        </div>
    )
}

export default LoginSpinner