import { GET_SLIDERS, GET_SLIDER_BY_ID, ADD_SLIDER, SLIDERS_LOADING } from '../actions/types'

const initialState = {
     sliders: [],
     slider: [],
     updated: [],
     loading: true
}

const slidersReducer = (state = initialState, action) => {
     switch (action.type) {
          case GET_SLIDERS:
               return {
                    ...state,
                    sliders: action.payload,
                    loading: false
               };
          case GET_SLIDER_BY_ID:
               return {
                    ...state,
                    slider: action.payload?.data?.slide,
                    loading: false
               };
          case ADD_SLIDER:
               return {
                    ...state,
                    updated: Math.random(),
                    loading: false
               };
          case SLIDERS_LOADING:
               return {
                    loading: true
               };
          default:
               return state
     }

}

export default slidersReducer;