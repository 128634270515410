import React from "react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import AppPagination from "../../components/Pagination";
import EditSliders from "./EditSlider";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteSlider, getSliders } from "../../redux/actions/slidersActions";
import Spinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";
import { Hint } from "../../components/Tooltips";

const SlidersContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [id, setId] = React.useState();
  const [row, setRow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [paginated, setPaginated] = React.useState(false);
  const sliders = useSelector((state) => state.sliders.sliders);
  const loading = useSelector((state) => state.sliders.loading);
  const updated = useSelector((state) => state.sliders.updated);
  const allSliders = sliders?.data?.docs;
  const pagesNum = sliders?.pagesNum;
  React.useEffect(() => {
    dispatch(getSliders(currentPage));
  }, [currentPage, updated, dispatch]);
  const handleDelete = (id) => {
    setId(id);
    setDeleteShow(true);
  };
  const viewSliderHandling = (id) => {
    history.push(`/view-slider/${id}`);
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditSliders id={id} row={row} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Module.Sliders")}
          modalClosing={setShow}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Slider.Title")}</th>
            <th scope='col'>{t("Slider.Link")}</th>
            <th scope='col'>{t("Slider.MobileUrl")}</th>
            <th scope='col'>{t("Slider.Description")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allSliders?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/slider/${row?.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {row.title}
                </td>
                <td className='table-cell'>{row.link}</td>
                <td className='table-cell'>{row.mobileURL}</td>
                <td className='table-cell'>{row.description}</td>
                <td className='table-cell'>
                  <Hint
                    title='EditSlider'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setShow(1);
                          setId(row._id);
                          setRow(row);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteSlider'
                    childs={<MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />}
                  />{" "}
                  <Hint
                    title='ViewSlider'
                    childs={<MdVisibility onClick={() => viewSliderHandling(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal itemId={id} deleteItem={deleteSlider} content='Sliders' show={deleteShow} setShow={setDeleteShow} />
    </>
  );
};

export default SlidersContent;
