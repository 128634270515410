import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { MdEdit } from 'react-icons/md'
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MyModal from '../../components/Modal';
import EditTerm from './EditTerm';
import { getTermById } from '../../redux/actions/termsActions';
import parse from 'html-react-parser'

const ViewTermContent = () => {
    const {t} = useTranslation();
    const lang = localStorage.getItem('i18nextLng')
    const {termId} = useParams();
    const [show, setShow] = React.useState();
    const dispatch = useDispatch();
    const term = useSelector(state => state.terms.term)
    const updated = useSelector(state => state.terms.updated)
    React.useEffect(() => {
        dispatch(getTermById(termId))
    }, [updated, dispatch, termId])
    // const handleEdit = () => {
    //     setShow(1)
    //     setTimeout(() => {
    //         setShow(true)
    //     }, [])
    // }
 
    return (
        <div className='admin-profile'>
            <MyModal trueShow={show} modalTitle={t('Tooltips.EditTerm')} content={<EditTerm id={term?._id} row={term} setShow={setShow} />}/>
            <div className=' view-product-properties'>
                {/* <div className='view-product-edit'>
                    <div></div>
                    <MdEdit onClick={handleEdit} className='edit-icon-button' />
                </div> */}
                <div className='term-information'>
                    <p className='term-about'><span>{t('Terms.About')}:</span> {lang === 'ar'? term?.aboutAr : term?.aboutEn}</p>
                    {
                        term?.termsAndConditionsAr?.length > 2?
                        parse(lang === 'ar'? term?.termsAndConditionsAr : term?.termsAndConditionsEn) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewTermContent;