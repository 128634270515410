import React from "react";
import ImagePicker from "../../components/ImagePicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../redux/actions/proudctsActions";
import { getSubInMain } from "../../redux/actions/subCatActions";
import { Form } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const EditFromInside = ({ setShow, id, row, setEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mainCategories = useSelector((state) => state.categories.categories?.data?.docs);
  const subCategories = useSelector((state) => state.subCategories.subForMain?.data?.subCategories);
  const brands = useSelector((state) => state.brands.brands?.data?.docs);
  const [nameEn, setNameEn] = React.useState(row.nameEn);
  const [nameAr, setNameAr] = React.useState(row.nameAr);
  const [mainCategory, setMainCategory] = React.useState(row.mainCategory?._id);
  const [subCategory, setSubCategory] = React.useState(row.subCategory?._id);
  const [descriptionEn, setDescriptionEn] = React.useState(row.descriptionEn);
  const [descriptionAr, setDescriptionAr] = React.useState(row.descriptionAr);
  const [brand, setBrand] = React.useState(brands ? brands[0]._id : null);
  const [price, setPrice] = React.useState(row.price);
  const [color, setColor] = React.useState(row.color);
  const [size, setSize] = React.useState(row.size);
  const [stocks, setStocks] = React.useState(row.stocks);
  const [image, setImage] = React.useState(row.imageCover);
  const [images, setImages] = React.useState(row.images);
  const [sellCode, setSellCode] = React.useState(row.saleCode);
  const [videoLink, setVideoLink] = React.useState(row.video);
  const [tag, setTag] = React.useState();
  const [arr, setArr] = React.useState(row.tags);
  const [active, setActive] = React.useState(row.active);
  const [metaTag, setMetaTag] = React.useState();
  const [metaDescription, setMetaDescription] = React.useState(row.metaDescription);
  const [arrMeta, setArrMeta] = React.useState(row.metaTags);
  const [wordCount, setWordCount] = React.useState(row.metaDescription.length);
  const oldImage = row.imageCover;
  const oldImages = row?.images.toString();
  const thePrice = parseInt(price);
  const theColor = color.toLowerCase();
  const editor = React.useRef(null);
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editProduct(
        id,
        nameEn,
        nameAr,
        mainCategory,
        subCategory,
        descriptionAr,
        descriptionEn,
        brand,
        thePrice,
        theColor,
        size,
        stocks,
        image,
        images,
        sellCode,
        videoLink,
        oldImage,
        arr,
        active,
        oldImages,
        metaDescription,
        arrMeta
      )
    );
    setEdit(false);
  };
  React.useEffect(() => {
    dispatch(getSubInMain(mainCategory, 1, 5000));
  }, [mainCategory, dispatch]);
  const imagesHandler = (e) => {
    console.log(e.target.files);
    setImages(Array.from(e.target.files));
  };

  const handleDeleteItem = (tg) => {
    setArr(arr.filter((val) => val !== tg));
    console.log(tg?.id);
    console.log("New Arr", arr);
    setTag("");
  };

  const addToTodo = () => {
    if (tag === "") {
      return;
    } else {
      arr.push(tag);
      setTag("");
      console.log(arr);
    }
  };

  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const addToTodoMeta = () => {
    if (metaTag === "") {
      return;
    } else {
      arrMeta.push(metaTag);
      setMetaTag("");
    }
  };
  const handleDeleteMeta = (tg) => {
    setArrMeta(arrMeta.filter((val) => val !== tg));
    setMetaTag("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-sm-6'>
          <label>{t("Category.NameAr")}</label>
          <input
            required
            placeholder={t("Category.NameAr")}
            type='text'
            className='form-control app-input col-sm-6'
            autoFocus
            onChange={(e) => setNameAr(e.target.value)}
            defaultValue={nameAr}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Category.NameEn")}</label>
          <input
            required
            placeholder={t("Category.NameEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setNameEn(e.target.value)}
            defaultValue={nameEn}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Module.MainCategories")}</label>
          <select
            onChange={(e) => setMainCategory(e.target.value)}
            class='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'
            defaultValue={mainCategory}>
            {mainCategories?.map((row, index) => {
              return (
                <option key={index} slected={row._id === subCategory ? true : false} value={row?._id}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-6'>
          <label>{t("Module.SubCategories")}</label>
          {subCategories?.length > 0 ? (
            <select
              onChange={(e) => setSubCategory(e.target.value)}
              class='custom-select app-select mr-sm-2'
              id='inlineFormCustomSelect'
              defaultValue={subCategory}>
              {subCategories?.map((row, index) => {
                return (
                  <option key={index} value={row?._id}>
                    {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                  </option>
                );
              })}
            </select>
          ) : null}
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Brand")}</label>
          <select
            onChange={(e) => setBrand(e.target.value)}
            class='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'>
            {brands?.map((row, index) => {
              return (
                <option key={index} value={row?._id}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Price")}</label>
          <input
            required
            placeholder={t("Product.Price")}
            type='number'
            className='form-control app-input col-sm-6'
            onChange={(e) => setPrice(e.target.value)}
            defaultValue={price}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Colors")}</label>
          <input
            placeholder={t("Product.Colors")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setColor(e.target.value)}
            defaultValue={color}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Sizes")}</label>
          <input
            placeholder={t("Product.Sizes")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setSize(e.target.value)}
            defaultValue={size}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.SellCode")}</label>
          <input
            required
            placeholder={t("Product.SellCode")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setSellCode(e.target.value)}
            defaultValue={sellCode}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.VideoLink")}</label>
          <input
            placeholder={t("Product.VideoLink")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setVideoLink(e.target.value)}
            defaultValue={videoLink}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.Stocks")}</label>
          <input
            required
            placeholder={t("Product.Stocks")}
            type='number'
            className='form-control app-input col-sm-6'
            onChange={(e) => setStocks(e.target.value)}
            defaultValue={stocks}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaDescription")}</label>
          <br></br>
          <label>{wordCount}</label>
          <input
            placeholder={t("Product.MetaDescription")}
            type='text'
            className='form-control app-input col-sm-6'
            value={metaDescription}
            onKeyUp={(e) => setWordCount(e.target.value.length)}
            onChange={(e) => setMetaDescription(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <div className='add-input-wrapper'>
            <input
              fullWidth
              placeholder='Meta Tag'
              onChange={(e) => {
                setMetaTag(e.target.value);
              }}
              className='form-control app-input'
              value={metaTag}
            />
            <button className='add-answer-button' id='addButton' type='button' onClick={addToTodoMeta}>
              {t("Actions.Add")}
            </button>
          </div>
          <div>
            {arrMeta?.map((tg) => {
              return (
                <span style={{ marginRight: "15px", fontSize: "16px" }}>
                  {tg}
                  <span
                    onClick={() => {
                      handleDeleteMeta(tg);
                    }}
                    style={{ cursor: "pointer", color: "red" }}>
                    {" "}
                    &times;
                  </span>
                </span>
              );
            })}
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>{t("Product.DescriptionAr")}</Tab>
            <Tab>{t("Product.DescriptionEn")}</Tab>
          </TabList>

          <TabPanel>
            <div>
              <JoditEditor
                ref={editor}
                value={descriptionAr}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newcontentEn) => setDescriptionAr(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                onChange={(newcontentEn) => {}}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <JoditEditor
                ref={editor}
                value={descriptionEn}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newcontentEn) => setDescriptionEn(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                onChange={(newcontentEn) => {}}
              />
            </div>
          </TabPanel>
        </Tabs>
        <div className='add-input-wrapper'>
          <input
            fullWidth
            placeholder='Tag'
            onChange={(e) => {
              setTag(e.target.value);
            }}
            className='form-control app-input'
            value={tag}
          />
          <button className='add-answer-button' id='addButton' type='button' onClick={addToTodo}>
            {t("Actions.Add")}
          </button>
        </div>
        <div>
          {arr?.map((tg) => {
            return (
              <span style={{ marginRight: "15px", fontSize: "16px" }}>
                {tg}
                <span
                  onClick={() => {
                    handleDeleteItem(tg);
                  }}
                  style={{ cursor: "pointer", color: "red" }}>
                  {" "}
                  &times;
                </span>
              </span>
            );
          })}
        </div>
        <div className='col-sm-12'>
          <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
        </div>
        <div className='col-sm-12 multiple-wrapper'>
          <input onChange={imagesHandler} type='file' multiple />
        </div>
        <div class='col-sm-12 active-check-wrapper'>
          <Form.Check onChange={handleCheck} label='Active' defaultChecked={active} />
        </div>
      </div>
      <div className='delete-buttons-wrapper'>
        <button className='confirm-delete' type='submit'>
          {t("Buttons.Save")}
        </button>
        <button onClick={() => setEdit(false)} className='cancel-delete' type='submit'>
          {t("Buttons.Cancel")}
        </button>
      </div>
    </form>
  );
};

export default EditFromInside;
