import { ADD_DISCOUNT, DISCOUNTS_LOADING, GET_DISCOUNT_BY_ID, GET_DISSCOUNTS } from '../actions/types'

const initialState = {
    discounts: [],
    discount: [],
    updated: [],
    loading: true
}

const discountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DISSCOUNTS:
            return {
                ...state,
                discounts: action.payload,
                loading: false
            };
        case GET_DISCOUNT_BY_ID:
            return {
                ...state,
                discount: action.payload?.data?.discount,
                loading: false
            };
        case ADD_DISCOUNT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case DISCOUNTS_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default discountsReducer;