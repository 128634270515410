import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewCompetitionContent from './ViewCompetitionContent';

const ViewCompetition = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewCompetitionContent />} 
            pageTitle={t('Tooltips.ViewCompetition')}
        />
    )
}

export default ViewCompetition;