import { GET_COMPETITIONS, GET_COMPETITION_BY_ID, ADD_COMPETITION, COMPETITIONS_LOADING } from '../actions/types'

const initialState = {
    competitions: [],
    competition: [],
    updated: [],
    loading: true
}

const competitionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPETITIONS:
            return {
                ...state,
                competitions: action.payload,
                loading: false
            };
        case GET_COMPETITION_BY_ID:
            return {
                ...state,
                competition: action.payload?.data?.competition,
                loading: false
            };
        case ADD_COMPETITION:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case COMPETITIONS_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default competitionsReducer;