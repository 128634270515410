import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewArticleContent from './ViewArticleContent';

const ViewArticle = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewArticleContent />} 
            pageTitle={t('Tooltips.ViewArticle')}
        />
    )
}

export default ViewArticle;