import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import AddCoupon from './AddCoupon';
import CouponsTable from './CouponsContent';

const Coupons = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddCoupon setShow={setShow} />} 
            childs={<CouponsTable />} 
            pageTitle={t("SideBar.Coupons")}
            addRedirect
            addPath='/add-promo'
            addModalClosing={show}
            buttonTitle={t("Labels.Coupons")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Coupons")}
        />
    )
}

export default Coupons;