import { LOADING, GET_ADMINS, GET_ADMIN_BY_ID, ADD_ADMIN } from '../actions/types'

const initialState = {
    admins: [],
    admin: [],
    updated: [],
    loading: true
}

const adminsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMINS:
            return {
                ...state,
                admins: action.payload,
                loading: false
            };
        case GET_ADMIN_BY_ID:
            return {
                ...state,
                admin: action.payload?.data?.admin,
                loading: false
            };
        case ADD_ADMIN:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default adminsReducer;