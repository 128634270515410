import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getCities = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/city?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_CITIES,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getCityById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/city/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_CITY_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addCity =
  (nameEn, nameAr, fastCharge, normalCharge, cod, daysToShip, bostaCode, startDate, endDate) => (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("fastCharge", fastCharge);
    inputsData.append("normalCharge", normalCharge);
    inputsData.append("CODFee", cod);
    inputsData.append("daysToShip", daysToShip);
    inputsData.append("bostaCode", bostaCode);
    inputsData.append("startDate", startDate);
    inputsData.append("endDate", endDate);
    axios
      .post(`https://matjrna.co/api/v1/city`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_CITY,
          payload: res
        });
        myToast(lang === "ar" ? "تم إضافة مدينة جديدة" : "New city has been added");
      })
      .catch((error) => myError(error.response.data.message));
  };

export const editCity =
  (id, nameEn, nameAr, fastCharge, normalCharge, cod, daysToShip, bostaCode, startDate, endDate) => (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("fastCharge", fastCharge);
    inputsData.append("normalCharge", normalCharge);
    inputsData.append("CODFee", cod);
    inputsData.append("daysToShip", daysToShip);
    inputsData.append("bostaCode", bostaCode);
    inputsData.append("startDate", startDate);
    inputsData.append("endDate", endDate);
    axios
      .patch(`https://matjrna.co/api/v1/city/${id}`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_CITY,
          payload: res
        });
        myToast(lang === "ar" ? "تم تعديل المدينة" : "City has been edited");
      })
      .catch((error) => myError(error.message));
  };

export const deleteCity = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/city/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_CITY,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف المدينة" : "City has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
