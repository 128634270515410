import React from 'react';
import { useTranslation } from 'react-i18next';
import SubPage from '../../components/SubPage';
import EditTermContent from './EditTermContent';

const EditTerm = () => {
    const { t } = useTranslation();
    return (
        <SubPage
            childs={<EditTermContent />}
            pageTitle={t("Actions.Edit") + ' ' + t("Labels.Terms")}
        />
    )
}

export default EditTerm;