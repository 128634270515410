import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import AddShipper from './AddShipper';
import ShippersTable from './ShippersContent';

const Shippers = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddShipper setShow={setShow} />}
            childs={<ShippersTable />} 
            pageTitle={t("SideBar.Shippers")} 
            addModalClosing={show}
            addButton
            buttonTitle={t("Labels.Shippers")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Shippers")}
        />
    )
}

export default Shippers;