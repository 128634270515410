import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addAdmin } from '../../redux/actions/adminsActions';


const AddAdmin = ({ setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [name,setName] = React.useState('');
    const [country,setCountry] = React.useState('');
    const [phone,setPhone] = React.useState('');
    const [email,setEmail] = React.useState('');
    const [role,setRole] = React.useState('admin');
    const [address,setAddress] = React.useState('');
    const [gender,setGender] = React.useState('male');
    const [password,setPassword] = React.useState('');
    const [passwordConfirm,setPasswordConfirm] = React.useState('');
    const [image, setImage] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addAdmin(name, country, phone, email, role, address, gender, password, passwordConfirm, image))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Admin.Name")}</label>
                    <input
                        placeholder={t("Admin.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Email")}</label>
                    <input
                        placeholder={t("Admin.Email")}
                        type="email"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Phone")}</label>
                    <input
                        placeholder={t("Admin.Phone")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPhone(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Country")}</label>
                    <input
                        placeholder={t("Admin.Country")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setCountry(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Role")}</label>
                    <select
                        placeholder={t("Admin.Role")}
                        type="text"
                        className="custom-select app-select"
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value='admin'>{t('Admin.Admin')}</option>
                        <option value='moderator'>{t('Admin.Moderator')}</option>
                        <option value='data-entry'>{t('Admin.DataEntry')}</option>
                    </select>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Gender")}</label>
                    <select
                        placeholder={t("Admin.Gender")}
                        type="text"
                        className="custom-select app-select"
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <option value='male'>{t("Admin.Male")}</option>
                        <option value='female'>{t("Admin.Female")}</option>
                    </select>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Address")}</label>
                    <input
                        placeholder={t("Admin.Address")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setAddress(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Password")}</label>
                    <input
                        placeholder={t("Admin.Password")}
                        type="password"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPassword(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <label>{t("Admin.PasswordConfirm")}</label>
                    <input
                        placeholder={t("Admin.PasswordConfirm")}
                        type="password"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default AddAdmin;