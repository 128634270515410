import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewCustomerContent from './ViewCustomerContent';

const ViewCustomer = () => {
    const { t } = useTranslation();
    return (
        <SubPage
            childs={<ViewCustomerContent />}
            pageTitle={t('Tooltips.CustomerProfile')}
        />
    )
}

export default ViewCustomer;