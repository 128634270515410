import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import AddTerm from './AddTerm';
import TermsContent from './TermsContent';

const Terms = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
   
    return(
        <BasePage 
            modalContent={<AddTerm setShow={setShow} />}
            childs={<TermsContent />}
            pageTitle={t("SideBar.Terms")}
            addRedirect
            addPath='/add-term'
            addModalClosing={show}
            buttonTitle={t("Labels.Terms")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Terms")}
        />
    )
}

export default Terms;