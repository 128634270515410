import React from 'react';
import { MdEdit, MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal'
import EditDiscount from './EditDiscount';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDiscount, getDiscounts } from '../../redux/actions/discountsActions';
import Spinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal';
import { useHistory, useParams } from 'react-router';
import { Hint } from '../../components/Tooltips';

const DiscountsTable = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {pageNum} = useParams();
    const dispatch = useDispatch();
    const discounts = useSelector(state => state.discounts.discounts)
    const updated = useSelector(state => state.discounts.updated)
    const loading = useSelector(state => state.discounts.loading)
    const allDiscounts = discounts?.data?.discounts;
    const pagesNum = discounts?.pagesNum;
    const [show, setShow] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [deleteShow, setDeleteShow] = React.useState();
    const [paginated, setPaginated] = React.useState(false);
    const [id, setId] = React.useState();
    const [row, setRow] = React.useState();
    React.useEffect(() => {
        if (paginated) {
            history.push(`/discounts/${currentPage}`)
        }
    }, [currentPage, paginated, history])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    React.useEffect(() => {
        dispatch(getDiscounts(currentPage))
    }, [currentPage, updated, dispatch])
    const handleDelete = (id) => {
        setId(id)
        setDeleteShow(true)
    }
    return (
        loading? <Spinner /> : 
            <>
                <table className="table table-hover">
                    <Modal
                        trueShow={show}
                        content={<EditDiscount row={row} id={id} setShow={setShow} />}
                        modalClosing={show}
                        modalTitle={t("Actions.Edit") + ' ' + t("Labels.Discounts")} />
                    <thead>
                        <tr>
                            <th scope="col">{t("Admin.Name")}</th>
                            <th scope="col">{t("Discounts.Type")}</th>
                            <th scope="col">{t("Discounts.Value")}</th>
                            <th scope="col">{t("Discounts.From")}</th>
                            <th scope="col">{t("Discounts.To")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allDiscounts?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row.name}</td>
                                        <td className='table-cell' >{row.type}</td>
                                        <td className='table-cell' >{row.value}</td>
                                        <td className='table-cell' >{new Date(row?.startDate).toDateString()}</td>
                                        <td className='table-cell' >{new Date(row?.endDate).toDateString()}</td>
                                        <td className='table-cell' >
                                            <Hint title='EditDiscount' childs={
                                                <MdEdit onClick={() => {
                                                    setShow(1)
                                                    setId(row._id)
                                                    setRow(row)
                                                    setTimeout(() => {
                                                        setShow(true)
                                                    }, [])
                                                }} className='edit-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='DeleteDiscount' childs={
                                                <MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <AppPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginated={paginated}
                    setPaginated={setPaginated}
                    pagesNum={pagesNum}
                />
                <DeleteModal 
                    itemId={id}
                    deleteItem={deleteDiscount}
                    content='Discounts'
                    show={deleteShow}
                    setShow={setDeleteShow}
                />
            </>
    )
}

export default DiscountsTable;