import React from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import { BsGiftFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditSubCategory from "./EditSubCategory";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubCat, getSubCats } from "../../redux/actions/subCatActions";
import AppSpinner from "../../components/Spinner/Spinner";
import { getCats } from "../../redux/actions/catActions";
import DeleteModal from "../../components/DeleteModal";
import { useHistory, useParams } from "react-router";
import { Image } from "react-bootstrap";
import { Hint } from "../../components/Tooltips";

const SubCategoriesTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pageNum } = useParams();
  const dispatch = useDispatch();
  const [id, setId] = React.useState(1);
  const [row, setRow] = React.useState();
  const subCategories = useSelector((state) => state.subCategories.subCategories);
  const loading = useSelector((state) => state.subCategories.loading);
  const updated = useSelector((state) => state.subCategories.updated);
  const allSubCategories = subCategories?.data?.subCategories;
  const pagesNum = subCategories?.pagesNum;
  const [show, setShow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState(false);
  React.useEffect(() => {
    if (paginated) {
      history.push(`/subcategories/${currentPage}`);
    }
  }, [currentPage, paginated, history]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum, setCurrentPage]);
  React.useEffect(() => {
    dispatch(getSubCats(currentPage));
    dispatch(getCats());
  }, [currentPage, updated, dispatch]);
  const productsFilter = (id) => {
    history.push(`/subcategory/products/${id}`);
  };
  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditSubCategory id={id} row={row} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Labels.SubCategories")}
          modalClosing={show}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Category.Name")}</th>
            <th scope='col'>{t("Category.Type")}</th>
            <th scope='col'>{t("Module.MainCategories")}</th>
            <th scope='col'>{t("Category.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allSubCategories?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <Image
                    src={`https://matjrna.co/imgs/category/${row?.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </td>
                <td className='table-cell'>{row.type}</td>
                <td className='table-cell'>
                  {localStorage.getItem("i18nextLng") === "en" ? row?.parentId?.nameEn : row?.parentId?.nameAr}
                </td>
                <td className='table-cell'>
                  <Hint
                    title='EditSubcategory'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setShow(1);
                          setId(row._id);
                          setRow(row);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteSubcategory'
                    childs={
                      <MdDelete
                        onClick={() => {
                          setId(row._id);
                          setDeleteShow(true);
                        }}
                        className='delete-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='Products'
                    childs={<BsGiftFill onClick={() => productsFilter(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal
        content='ArticleCategories'
        show={deleteShow}
        setShow={setDeleteShow}
        itemId={id}
        deleteItem={deleteSubCat}
      />
    </>
  );
};

export default SubCategoriesTable;
