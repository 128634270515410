import React from "react";
import ImagePicker from "../../components/ImagePicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../redux/actions/proudctsActions";
import { getSubInMain } from "../../redux/actions/subCatActions";
import JoditEditor from "jodit-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getBrands } from "../../redux/actions/brandsActions";

const AddProduct = ({ setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mainCategories = useSelector((state) => state.categories.categories?.data?.docs);
  const subCategories = useSelector((state) => state.subCategories.subForMain?.data?.subCategories);
  const brands = useSelector((state) => state.brands.brands?.data?.docs);
  const [nameEn, setNameEn] = React.useState("");
  const [nameAr, setNameAr] = React.useState("");
  const [mainCategory, setMainCategory] = React.useState(mainCategories ? mainCategories[0]?._id : null);
  const [subCategory, setSubCategory] = React.useState(subCategories ? subCategories[0]?._id : null);
  console.log("SUB_CATTT", subCategory);
  const [descriptionEn, setDescriptionEn] = React.useState("");
  const [descriptionAr, setDescriptionAr] = React.useState("");
  const [brand, setBrand] = React.useState(brands ? brands[0]._id : null);
  const [price, setPrice] = React.useState("");
  const [color, setColor] = React.useState("");
  const [size, setSize] = React.useState("");
  const [stocks, setStocks] = React.useState("");
  const [image, setImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [sellCode, setSellCode] = React.useState("");
  const [videoLink, setVideoLink] = React.useState("");
  const [tag, setTag] = React.useState();
  const [arr, setArr] = React.useState([]);
  const [metaKeywordEn, setMetaKeywordEn] = React.useState();
  const [metaKeywordAr, setMetaKeywordAr] = React.useState();
  const [metaDescriptionEn, setMetaDescriptionEn] = React.useState();
  const [metaDescriptionAr, setMetaDescriptionAr] = React.useState();
  const thePrice = parseInt(price);
  const theColor = color.toLowerCase();
  const editor = React.useRef(null);
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addProduct(
        nameEn,
        nameAr,
        mainCategory,
        subCategory,
        descriptionAr,
        descriptionEn,
        brand,
        thePrice,
        theColor,
        size,
        stocks,
        image,
        images,
        sellCode,
        videoLink,
        arr,
        metaDescriptionEn,
        metaDescriptionAr,
        metaKeywordEn,
        metaKeywordAr
      )
    );
    setShow(1);
    setTimeout(() => {
      setShow(false);
    }, []);
  };
  React.useEffect(() => {
    dispatch(getSubInMain(mainCategory, 1, 5000));
    dispatch(getBrands());
  }, [mainCategory, dispatch]);
  const imagesHandler = (e) => {
    setImages(Array.from(e.target.files));
  };

  React.useEffect(() => {
    setSubCategory(subCategories ? subCategories[0]?._id : null);
  }, [subCategories, setSubCategory]);

  const handleDeleteItem = (tg) => {
    setArr(arr.filter((val) => val !== tg));
    console.log(tg?.id);
    console.log("New Arr", arr);
    setTag("");
  };
  React.useEffect(() => {
    dispatch(getSubInMain(mainCategory, 1, 5000));
  }, [mainCategory, dispatch]);

  const addToTodo = () => {
    if (tag === "") {
      return;
    } else {
      arr.push(tag);
      setTag("");
      console.log(arr);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-sm-6'>
          <label>{t("Category.NameAr")}</label>
          <input
            required
            placeholder={t("Category.NameAr")}
            type='text'
            className='form-control app-input col-sm-6'
            autoFocus
            onChange={(e) => setNameAr(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Category.NameEn")}</label>
          <input
            required
            placeholder={t("Category.NameEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setNameEn(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Module.MainCategories")}</label>
          <select
            required
            onChange={(e) => setMainCategory(e.target.value)}
            className='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'>
            {mainCategories?.map((row, index) => {
              return (
                <option value={row?._id}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-6'>
          <label>{t("Module.SubCategories")}</label>
          <select
            required
            onChange={(e) => setSubCategory(e.target.value)}
            class='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'>
            {subCategories?.map((row, index) => {
              return (
                <option value={row?._id}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Brand")}</label>
          <select
            onChange={(e) => setBrand(e.target.value)}
            class='custom-select app-select mr-sm-2'
            id='inlineFormCustomSelect'>
            {brands?.map((row, index) => {
              return (
                <option value={row?._id}>
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Price")}</label>
          <input
            required
            placeholder={t("Product.Price")}
            type='number'
            className='form-control app-input col-sm-6'
            onChange={(e) => setPrice(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Colors")}</label>
          <input
            placeholder={t("Product.Colors")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setColor(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.Sizes")}</label>
          <input
            placeholder={t("Product.Sizes")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setSize(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.SellCode")}</label>
          <input
            required
            placeholder={t("Product.SellCode")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setSellCode(e.target.value)}></input>
        </div>
        <div className='col-sm-6'>
          <label>{t("Product.VideoLink")}</label>
          <input
            placeholder={t("Product.VideoLink")}
            type='text'
            className='form-control app-input col-sm-6'
            onChange={(e) => setVideoLink(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.Stocks")}</label>
          <input
            required
            placeholder={t("Product.Stocks")}
            type='number'
            className='form-control app-input col-sm-6'
            onChange={(e) => setStocks(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaDescriptionEn")}</label>
          <br></br>
          <label id='metaDescriptionEn' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaDescriptionEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaDescriptionEn").textContent = e.target.value.length)}
            onChange={(e) => setMetaDescriptionEn(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaDescriptionAr")}</label>
          <br></br>
          <label id='metaDescriptionAr' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaDescriptionAr")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaDescriptionAr").textContent = e.target.value.length)}
            onChange={(e) => setMetaDescriptionAr(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaKeywordEn")}</label>
          <br></br>
          <label id='metaKeywordEn' className='text-muted'>
            0
          </label>
          <input
            placeholder={t("Product.MetaKeywordEn")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaKeywordEn").textContent = e.target.value.length)}
            onChange={(e) => setMetaKeywordEn(e.target.value)}></input>
        </div>
        <div className='col-sm-12'>
          <label>{t("Product.MetaKeywordAr")}</label>
          <br></br>
          <label id='metaKeywordAr' className='text-muted'>
            0
          </label>{" "}
          <input
            placeholder={t("Product.MetaKeywordAr")}
            type='text'
            className='form-control app-input col-sm-6'
            onKeyUp={(e) => (document.querySelector("#metaKeywordAr").textContent = e.target.value.length)}
            onChange={(e) => setMetaKeywordAr(e.target.value)}></input>
        </div>
        <Tabs>
          <TabList>
            <Tab>{t("Product.DescriptionAr")}</Tab>
            <Tab>{t("Product.DescriptionEn")}</Tab>
          </TabList>

          <TabPanel>
            <div>
              <JoditEditor
                ref={editor}
                value={descriptionAr}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newcontentEn) => setDescriptionAr(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                onChange={(newcontentEn) => {}}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <JoditEditor
                ref={editor}
                value={descriptionEn}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newcontentEn) => setDescriptionEn(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                onChange={(newcontentEn) => {}}
              />
            </div>
          </TabPanel>
        </Tabs>
        <div className='add-input-wrapper'>
          <input
            fullWidth
            placeholder='Tag'
            onChange={(e) => {
              setTag(e.target.value);
            }}
            className='form-control app-input'
            value={tag}
          />
          <button className='add-answer-button' id='addButton' type='button' onClick={addToTodo}>
            {t("Actions.Add")}
          </button>
        </div>
        <div>
          {arr?.map((tg) => {
            return (
              <span style={{ marginRight: "15px", fontSize: "16px" }}>
                {tg}
                <span
                  onClick={() => {
                    handleDeleteItem(tg);
                  }}
                  style={{ cursor: "pointer", color: "red" }}>
                  {" "}
                  &times;
                </span>
              </span>
            );
          })}
        </div>
        <div className='col-sm-12'>
          <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
        </div>
        <div className='col-sm-12 multiple-wrapper'>
          <input onChange={imagesHandler} type='file' multiple />
        </div>
      </div>
      <button className='save-button' type='submit'>
        {t("Buttons.Save")}
      </button>
    </form>
  );
};

export default AddProduct;
