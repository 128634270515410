import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";

export const getDiscountProducts = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/discount/morphs?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.DISCOUNTS_PRODUCTS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_PRODUCTS_DISCOUNT,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const removeDiscount = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/discount/delete-morph/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_PRODUCT_DISCOUNT,
        payload: res
      });
      myToast("Discount was removed");
    })
    .catch((error) => myError(error?.response?.message));
};
