import React from "react";
import { useTranslation } from "react-i18next";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../redux/actions/brandsActions";
import { getCats } from "../../redux/actions/catActions";
import { getSubCats } from "../../redux/actions/subCatActions";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineHeart,
  AiOutlineStar
} from "react-icons/ai";
import AppSpinner from "../../components/Spinner/Spinner";
import { useHistory } from "react-router";
import { Hint } from "../../components/Tooltips";
import { setBestSeller, setFeatured } from "../../redux/actions/proudctsActions";
import { getDiscounts } from "../../redux/actions/discountsActions";
import { BsCircle } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";

const ProductsContent = ({
  theProducts,
  currentPage,
  setCurrentPage,
  pagesNum,
  paginated,
  setPaginated,
  pagination
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.products.loading);
  const lang = localStorage.getItem("i18nextLng");

  React.useEffect(() => {
    dispatch(getCats());
    dispatch(getSubCats());
    dispatch(getBrands());
    dispatch(getDiscounts(1, 1000));
  }, [dispatch]);
  const handleSellCode = (sellCode) => {
    history.push(`/sell-code/${sellCode}/1`);
  };
  const handleBestSeller = (id) => {
    dispatch(setBestSeller(id));
  };

  const handleFeaturedProduct = (id) => {
    dispatch(setFeatured(id));
  };
  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Product.Price")}</th>
            <th scope='col'>{t("Product.SellCode")}</th>
            <th scope='col'>{t("Product.Active")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {theProducts?.map((row, index) => {
            return (
              <tr>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/product/${row.imageCover}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </td>
                <td className='table-cell'>{row?.price}</td>
                <td className='table-cell'>{row._id}</td>
                <td className='table-cell'>
                  {row.active ? (
                    <FaCircle className='b-success text-success' />
                  ) : (
                    <FaCircle className='b-danger text-danger' />
                  )}
                  {row.active ? " Active " : " Not Active "}
                </td>
                <td className='table-cell'>
                  <Hint
                    title={row.isBestseller ? "RemoveBestSeller" : "SetBestSeller"}
                    childs={
                      row.isBestseller ? (
                        <AiFillHeart onClick={() => handleBestSeller(row._id)} className='edit-icon-button' />
                      ) : (
                        <AiOutlineHeart onClick={() => handleBestSeller(row._id)} className='edit-icon-button' />
                      )
                    }
                  />{" "}
                  <Hint
                    title={row.isFeatured ? "RemoveFeatured" : "SetFeatured"}
                    childs={
                      row.isFeatured ? (
                        <AiFillStar onClick={() => handleFeaturedProduct(row._id)} className='edit-icon-button' />
                      ) : (
                        <AiOutlineStar onClick={() => handleFeaturedProduct(row._id)} className='edit-icon-button' />
                      )
                    }
                  />{" "}
                  <Hint
                    title='Products'
                    childs={
                      lang === "en" ? (
                        <AiOutlineArrowRight onClick={() => handleSellCode(row._id)} className='edit-icon-button' />
                      ) : (
                        <AiOutlineArrowLeft onClick={() => handleSellCode(row._id)} className='edit-icon-button' />
                      )
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination ? (
        <AppPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          paginated={paginated}
          setPaginated={setPaginated}
          pagesNum={pagesNum}
        />
      ) : null}
    </>
  );
};

export default ProductsContent;
