import React from 'react';
import BasePage from '../../components/BasePage';
import SubCategoriesTable from './SubCategoriesContent';
import { useTranslation } from 'react-i18next';
import AddSubCategory from './AddSubCategory';

const SubCategories = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddSubCategory setShow={setShow} />} 
            childs={<SubCategoriesTable />} 
            pageTitle={t("SideBar.SubCategories")}
            buttonTitle={t("Labels.SubCategories")}
            addModalClosing={show}
            addButton
            modalTitle={t("Actions.Add") + ' ' + t("Labels.SubCategories")}
        />
    )
}

export default SubCategories;