import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getArticleCats = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/articleCategory?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.ARTICLE_CAT_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ARTICLE_CATS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getArticleCatById = (id) => (dispatch) => {
  axios.get(`https://matjrna.co/api/v1/articleCategory/${id}`).then((res) => {
    dispatch({
      type: types.GET_ARTICLE_CAT_BY_ID,
      payload: res.data
    });
  });
};

export const addArticleCategory = (nameEn, nameAr, image) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("nameEn", nameEn);
  inputsData.append("nameAr", nameAr);
  inputsData.append("image", image);
  axios
    .post(`https://matjrna.co/api/v1/articleCategory`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_ARTICLE_CAT,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة قسم جديد" : "New category has been added");
    })
    .catch((error) => myError(error?.response?.message));
};

export const editArticleCategory = (id, nameEn, nameAr, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("nameEn", nameEn);
  inputsData.append("nameAr", nameAr);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/articleCategory/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_ARTICLE_CAT,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل القسم" : "Category has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteArticleCategory = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/articleCategory/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_ARTICLE_CAT,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف القسم" : "Category has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
