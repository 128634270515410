import React from 'react';
import BestSellers from './Bestsellers';
import LatestCustomers from './LatestCustomers';
import LatestOrders from './LatestOrders';
import TodayOrders from './TodayOrders';
import Widget from './Widget';

const HomePage = () => {
    return(
        <div>
            <Widget />
            <LatestCustomers />
            <BestSellers />
            <LatestOrders />
            <TodayOrders />
        </div>
    )
}

export default HomePage;