import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getUserById } from "../../redux/actions/usersActions";
import { getUserOrders } from "../../redux/actions/ordersActions";
import OrdersTable from "../Orders/OrdersContent";

const ViewCustomerContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("i18nextLng");
  const [ordersCurrent, setOrdersCurrent] = React.useState();
  const { userId } = useParams();
  const user = useSelector((state) => state.users.user);
  const updated = useSelector((state) => state.users.updated);
  const orders = useSelector((state) => state.orders.orders?.data?.orders);
  const componentRef = React.useRef();
  React.useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getUserOrders(userId));
  }, [updated, dispatch, userId]);
  return (
    <>
      <div ref={componentRef} className='view-product-wrapper admin-profile'>
        <div className='admdin-image-wrapper'>
          <img alt='img' src={`https://matjrna.co/imgs/user/${user?.image}`} className='admin-image rounded-circle' />
        </div>
        <div style={{ flex: "1" }} className=' view-product-properties'>
          <div className='user-information'>
            <p className='admin-name'>{user?.name}</p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("Admin.Country")}:</span> {user?.country}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("Admin.Email")}:</span> {user?.email}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("Admin.Address")}:</span> {user?.address}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("Admin.Gender")}:</span> {user?.gender}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("Module.Cities")}:</span>{" "}
              {lang === "en" ? user?.city?.nameEn : user?.city?.nameAr}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>ZIP:</span> {user?.zipCode}
            </p>
            <p className='customer-answer'>
              <span className='customer-title'>{t("User.Children")}: </span>
              {user?.children?.map((child, index) => {
                return (
                  <p key={index} className='child-data'>
                    {index + 1}- {child.name} {child.date} {child.gender}
                  </p>
                );
              })}
            </p>
          </div>
        </div>
      </div>
      {orders?.length > 0 ? (
        <div>
          <h3 className='customer-orders'>{user?.name}' Orders</h3>
          <OrdersTable currentPage={ordersCurrent} setCurrentPage={setOrdersCurrent} allOrders={orders} />
        </div>
      ) : null}
    </>
  );
};

export default ViewCustomerContent;
