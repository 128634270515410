import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import ArticlesContent from './ArticlesContent';
import AddArticles from './AddArticle';

const Sliders = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
   
    return(
        <BasePage 
            modalContent={<AddArticles setShow={setShow} />}
            childs={<ArticlesContent />} 
            pageTitle={t("SideBar.Articles")} 
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Articles")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Articles")}
        />
    )
}

export default Sliders;