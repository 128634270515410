import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewCartContent from './ViewCartContent';

const ViewCart = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewCartContent />} 
            pageTitle={t("Tooltips.ViewCart")}
        />
    )
}

export default ViewCart;