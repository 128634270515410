import React from "react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditShipper from "./EditShipper";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { deleteShipper, getShippers } from "../../redux/actions/shippersActions";
import { useHistory, useParams } from "react-router";
import DeleteModal from "../../components/DeleteModal";
import Spinner from "../../components/Spinner/Spinner";
import { Hint } from "../../components/Tooltips";

const ShippersTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pageNum } = useParams();
  const [id, setId] = React.useState(1);
  const [row, setRow] = React.useState();
  const dispatch = useDispatch();
  const shippers = useSelector((state) => state.shippers.shippers);
  const loading = useSelector((state) => state.shippers.loading);
  const updated = useSelector((state) => state.shippers.updated);
  const [deleteShow, setDeleteShow] = React.useState();
  const allShippers = shippers?.data?.docs;
  const pagesNum = shippers?.pagesNum;
  const [show, setShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState(false);
  React.useEffect(() => {
    if (paginated) {
      history.push(`/shippers/${currentPage}`);
    }
  }, [currentPage, paginated, history]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum, setCurrentPage]);
  React.useEffect(() => {
    dispatch(getShippers(currentPage));
  }, [currentPage, updated, dispatch]);
  const viewShipper = (id) => {
    history.push(`/view-shipper/${id}`);
  };
  const handleDelete = (id) => {
    setId(id);
    setDeleteShow(true);
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditShipper row={row} id={id} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Module.Shippers")}
          modalClosing={show}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Admin.Phone")}</th>
            <th scope='col'>{t("Admin.Email")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allShippers?.map((row, index) => {
            return (
              <tr>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/shipper/${row?.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {row?.name}
                </td>
                <td className='table-cell'>{row?.phone}</td>
                <td className='table-cell'>{row?.email}</td>
                <td className='table-cell'>
                  <Hint
                    title='EditShipper'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setId(row._id);
                          setRow(row);
                          setShow(1);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteShipper'
                    childs={<MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />}
                  />{" "}
                  <Hint
                    title='ShipperProfile'
                    childs={<MdVisibility onClick={() => viewShipper(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        deleteItem={deleteShipper}
        itemId={id}
        content='Shippers'
      />
    </>
  );
};

export default ShippersTable;
