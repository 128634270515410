import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addShipper } from '../../redux/actions/shippersActions';


const AddShipper = ({ setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [name,setName] = React.useState('');
    const [company,setCompany] = React.useState('');
    const [phone,setPhone] = React.useState('');
    const [email,setEmail] = React.useState('');
    const address = ''
    const [password,setPassword] = React.useState('');
    const [passwordConfirm,setPasswordConfirm] = React.useState('');
    const [image, setImage] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addShipper(name, company, email, phone, password, passwordConfirm, address, image))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Admin.Name")}</label>
                    <input
                        required
                        placeholder={t("Admin.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Email")}</label>
                    <input
                        required
                        placeholder={t("Admin.Email")}
                        type="email"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Phone")}</label>
                    <input
                        required
                        placeholder={t("Admin.Phone")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPhone(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Shipper.Company")}</label>
                    <input
                        required
                        placeholder={t("Shipper.Company")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setCompany(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Password")}</label>
                    <input
                        required
                        placeholder={t("Admin.Password")}
                        type="password"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPassword(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.PasswordConfirm")}</label>
                    <input
                        required
                        placeholder={t("Admin.PasswordConfirm")}
                        type="password"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default AddShipper;