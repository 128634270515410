import React from 'react';
import { MdDelete, MdVisibility } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Spinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal'
import { deleteInquiry, getInquiries } from '../../redux/actions/inquiriesActions';
import { Hint } from '../../components/Tooltips';

const InquiriesContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [id, setId] = React.useState();
    const [deleteShow, setDeleteShow] = React.useState();
    const [paginated, setPaginated] = React.useState(false);
    const inquiries = useSelector(state => state.inquiries.inquiries)
    const loading = useSelector(state => state.inquiries.loading)
    const updated = useSelector(state => state.inquiries.updated)
    const allInquiries = inquiries?.docs;
    const pagesNum = inquiries?.pagesNum;
    React.useEffect(() => {
        dispatch(getInquiries(currentPage))
    }, [currentPage, updated, dispatch])
    const handleDelete = (id) => {
        setId(id)
        setDeleteShow(true)
    }
    const viewSliderHandling = (id) => {
        history.push(`/view-inquiry/${id}`)
    }
    return (
        loading ? <Spinner /> :
            <>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("Module.Users")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allInquiries?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row?.userId?.name}</td>
                                        <td className='table-cell' >
                                            <Hint title='DeleteInquiry' childs={
                                                <MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='ViewInquiry' childs={
                                                <MdVisibility onClick={() => viewSliderHandling(row._id)} className='edit-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    /*
                    <AppPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginated={paginated}
                    setPaginated={setPaginated}
                    pagesNum={pagesNum}
                />
                    */
                }
                <AppPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginated={paginated}
                    setPaginated={setPaginated}
                    pagesNum={pagesNum}
                />
                <DeleteModal
                    itemId={id}
                    deleteItem={deleteInquiry}
                    content='Inquiries'
                    show={deleteShow}
                    setShow={setDeleteShow}
                />
            </>
    )
}

export default InquiriesContent;