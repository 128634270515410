import React from 'react';
import BasePage from '../../components/BasePage';
import WidgetContent from './WidgetContent';

const Widget = () => {
    return (
        <BasePage
            childs={<WidgetContent />}
        />
    )
}

export default Widget;