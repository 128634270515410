import React from "react";
import { MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from "react-i18next";
import AppPagination from "../../components/Pagination";
import { useHistory } from "react-router";
import AppSpinner from "../../components/Spinner/Spinner";
import { Hint } from "../../components/Tooltips";

const CustomersTable = ({
  pagesNum,
  currentPage,
  setCurrentPage,
  paginated,
  setPaginated,
  allCustomers,
  loading,
  pagination
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const viewCustomer = (id) => {
    history.push(`/view-customer/${id}`);
  };
  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Admin.Phone")}</th>
            <th scope='col'>{t("User.Email")}</th>
            <th scope='col'>{t("User.Country")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allCustomers?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/user/${row.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {row.name}
                </td>
                <td className='table-cell'>{row.phone}</td>
                <td className='table-cell'>{row.email}</td>
                <td className='table-cell'>{row.country}</td>
                <td className='table-cell'>
                  <Hint title='DeleteCustomer' childs={<MdDelete className='delete-icon-button' />} />{" "}
                  <Hint
                    title='CustomerProfile'
                    childs={<MdVisibility onClick={() => viewCustomer(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination ? (
        <AppPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          paginated={paginated}
          setPaginated={setPaginated}
          pagesNum={pagesNum}
        />
      ) : null}
    </>
  );
};

export default CustomersTable;
