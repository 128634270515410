import { GET_ARTICLES, GET_ARTICLE_BY_ID, ADD_ARTICLE, ARTICLE_LOADING } from '../actions/types'

const initialState = {
    articles: [],
    article: [],
    updated: [],
    loading: true
}

const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLES:
            return {
                ...state,
                articles: action.payload,
                loading: false
            };
        case GET_ARTICLE_BY_ID:
            return {
                ...state,
                article: action.payload?.data?.article,
                loading: false
            };
        case ADD_ARTICLE:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case ARTICLE_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default articlesReducer;