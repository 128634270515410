import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import ViewOrderContent from './ViewOrderContent';
import { useTranslation } from 'react-i18next';

const ViewOrder = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewOrderContent />} 
            pageTitle={t("Tooltips.ViewOrder")}
        />
    )
}

export default ViewOrder;