import React from 'react';
import BasePage from '../../components/BasePage';
import ProductsTable from '../Products/ProductsContent';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getBestSellers } from '../../redux/actions/proudctsActions';

const BestSellers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState();
    const allProducts = useSelector(state => state.products.products?.data?.docs)
    React.useEffect(() => {
        dispatch(getBestSellers(1, 5))
    }, [dispatch])
    return (
        <BasePage
            childs={<ProductsTable  theProducts={allProducts} />}
            pageTitle={t("Product.BestSellers")}
            buttonTitle={t("Labels.Products")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Products")}
            searchContent={search}
            setSearchContent={setSearch}
            viewMorePath='best-sellers/1'
            viewMore
        />
    )
}

export default BestSellers;