import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getAdminById } from "../../redux/actions/adminsActions";
import MyModal from "../../components/Modal";
import EditAdmin from "./EditAdmin";

const ViewAdminContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { adminId } = useParams();
  const admin = useSelector((state) => state.admins.admin);
  const updated = useSelector((state) => state.admins.updated);
  const [show, setShow] = React.useState();
  const componentRef = React.useRef();
  React.useEffect(() => {
    dispatch(getAdminById(adminId));
  }, [updated, dispatch, adminId]);
  const handleEdit = () => {
    setShow(1);
    setTimeout(() => {
      setShow(true);
    }, []);
  };
  return (
    <div ref={componentRef} className='view-product-wrapper admin-profile'>
      <MyModal
        modalTitle={t("Tooltips.EditAdmin")}
        trueShow={show}
        content={<EditAdmin setShow={setShow} id={admin?._id} row={admin} />}
      />
      <div className='admdin-image-wrapper'>
        <img alt='img' src={`https://matjrna.co/imgs/admin/${admin?.image}`} className='admin-image rounded-circle' />
      </div>
      <div style={{ flex: "1" }} className=' view-product-properties'>
        <div className='view-product-edit'>
          <MdEdit onClick={handleEdit} className='edit-icon-button' />
        </div>
        <div className='admin-information'>
          <p className='admin-name'>{admin?.name}</p>
          <p>
            <span>{t("Admin.Country")}:</span> {admin?.country}
          </p>
          <p>
            <span>{t("Admin.Role")}:</span> {admin?.role}
          </p>
          <p>
            <span>{t("Admin.Email")}:</span> {admin?.email}
          </p>
          <p>
            <span>{t("Admin.Address")}:</span> {admin?.address}
          </p>
          <p>
            <span>{t("Admin.Gender")}:</span> {admin?.gender}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewAdminContent;
