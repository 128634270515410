import React from 'react';
import { useTranslation } from 'react-i18next';
import {BsCamera} from 'react-icons/bs'

const ImagePicker = ({onChange, style}) => {
    const {t} = useTranslation();
    return(
        <>
            <label style={style} for="files" className="file-button">{t("Buttons.ChooseImage")} <BsCamera className='file-input-icon' /></label>
            <input onChange={onChange} id="files" className='file-input' type="file"></input>
        </>
    )
}

export default ImagePicker;