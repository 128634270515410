import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import SellCodeContent from './SellCodeContent';
import { useParams } from 'react-router';
import {useSelector} from 'react-redux'
import AddSameSell from './AddSameSell';

const SellCode = () => {
    const { t } = useTranslation();
    const {sellCode} = useParams();
    const products = useSelector(state => state.products.products?.data?.docs);
    const product = products?.length > 0? products[0] : {};
    const [show, setShow] = React.useState();
    return (
        <SubPage
            show={show}
            setShow={setShow}
            addButton
            childs={<SellCodeContent />}
            pageTitle={`${t("Product.SellCode")} (${sellCode})`}
            modalContent={<AddSameSell setShow={setShow} row={product} />}
        />
    )
}

export default SellCode;