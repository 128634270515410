import React from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditCategory from "./EditCategory";
import Pagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { deleteArticleCategory, getArticleCats } from "../../redux/actions/articleCatActions";
import { useSelector } from "react-redux";
import AppSpinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";
import { useHistory, useParams } from "react-router";
import { Hint } from "../../components/Tooltips";

const CategoriesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pageNum } = useParams();
  const history = useHistory();
  const [id, setId] = React.useState(1);
  const categories = useSelector((state) => state.articleCategories.articleCategories);
  const loading = useSelector((state) => state.articleCategories.loading);
  const updated = useSelector((state) => state.articleCategories.updated);
  const allCategories = categories?.data?.docs;
  const pagesNum = categories?.pagesNum;
  const [show, setShow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState();
  const [row, setRow] = React.useState();
  React.useEffect(() => {
    if (paginated) {
      history.push(`/categories/${currentPage}`);
    }
  }, [currentPage, paginated, history]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum, setCurrentPage]);
  React.useEffect(() => {
    dispatch(getArticleCats(currentPage));
  }, [currentPage, updated, dispatch]);
  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditCategory row={row} id={id} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Labels.MainCategories")}
          modalClosing={show}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allCategories?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/articleCategory/${row?.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </td>
                <td className='table-cell'>
                  <Hint
                    title='EditCategory'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setId(row._id);
                          setRow(row);
                          setShow(1);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='EditCategory'
                    childs={
                      <MdDelete
                        onClick={() => {
                          setId(row._id);
                          setDeleteShow(true);
                        }}
                        className='delete-icon-button'
                      />
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        paginated={paginated}
        setPaginated={setPaginated}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesNum={pagesNum}
      />
      <DeleteModal
        content='ArticleCategories'
        show={deleteShow}
        setShow={setDeleteShow}
        itemId={id}
        deleteItem={deleteArticleCategory}
      />
    </>
  );
};

export default CategoriesTable;
