import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import MyModal from "../../components/Modal";
import EditArticle from "./EditArticle";
import { getArticleById } from "../../redux/actions/articlesActions";

const ViewArticleContent = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const { articleId } = useParams();
  const [show, setShow] = React.useState();
  const dispatch = useDispatch();
  const article = useSelector((state) => state.articles.article);
  const updated = useSelector((state) => state.articles.updated);
  React.useEffect(() => {
    dispatch(getArticleById(articleId));
  }, [updated, dispatch, articleId]);
  const handleEdit = () => {
    setShow(1);
    setTimeout(() => {
      setShow(true);
    }, []);
  };
  return (
    <div className='admin-profile'>
      <MyModal
        trueShow={show}
        modalTitle={t("Tooltips.EditArticle")}
        content={<EditArticle id={article?._id} row={article} setShow={setShow} />}
      />
      <div className='row'>
        <div className='article-image-wrapper col-sm-6'>
          <img alt='img' src={`https://matjrna.co/imgs/article/${article?.cardImage}`} className='slider-image' />
        </div>
        <div className='col-sm-6'>
          <div className='view-product-edit'>
            <div></div>
            <MdEdit onClick={handleEdit} className='edit-icon-button' />
          </div>
          <p className='article-texts'>
            <span>{t("Slider.Title")}:</span> {article?.title}
          </p>
          <p className='article-texts'>
            <span>{t("Module.ArticleCategories")}:</span>{" "}
            {lang === "en" ? article?.category?.nameEn : article?.category?.nameAr}
          </p>
          <p className='article-texts'>
            <span>{t("Article.CreationDate")}:</span> {article?.title}
          </p>
          <p className='article-texts'>
            <span>{t("Article.Likes")}:</span> {article?.likes}
          </p>
        </div>
      </div>
      <div className=' view-product-properties'>
        <div className='admin-information'>
          <p>{article?.content}</p>
        </div>
      </div>
      <div>
        <h3>{t("Article.Comments")}</h3>
        {article?.comments?.map((row, index) => {
          return (
            <div className='comment-wrapper'>
              <p className='comment-owner'>{row?.owner}</p>
              <p className='comment-content'>{row?.content}</p>
              <p className='comment-date'>{new Date(row?.createdAt).toDateString()}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewArticleContent;
