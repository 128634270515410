import React from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditBrand from "./EditBrand";
import AppPagination from "../../components/Pagination";
import { deleteBrand, getBrandById, getBrands } from "../../redux/actions/brandsActions";
import { useDispatch, useSelector } from "react-redux";
import AppSpinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";
import { setLoading } from "../../redux/actions/loading";
import { useParams, useHistory } from "react-router";
import { Hint } from "../../components/Tooltips";

const BrandsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pageNum } = useParams();
  const brands = useSelector((state) => state.brands.brands);
  const loading = useSelector((state) => state.brands.loading);
  const updated = useSelector((state) => state.brands.updated);
  const allBrands = brands?.data?.docs;
  const pagesNum = brands?.pagesNum;
  const [show, setShow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState(false);
  const [id, setId] = React.useState(1);
  const [row, setRow] = React.useState();
  React.useEffect(() => {
    if (paginated) {
      history.push(`/brands/${currentPage}`);
    }
  }, [currentPage, paginated, history]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum, setCurrentPage]);
  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getBrands(currentPage));
  }, [currentPage, updated, dispatch]);

  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          modalClosingsetShow
          trueShow={show}
          content={<EditBrand row={row} id={id} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Labels.Brands")}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allBrands?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/brand/${row.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </td>
                <td className='table-cell'>
                  <Hint
                    title='EditBrand'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setShow(1);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                          setRow(row);
                          setId(row._id);
                          dispatch(getBrandById(row._id));
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteBrand'
                    childs={
                      <MdDelete
                        onClick={() => {
                          setDeleteShow(true);
                          setId(row._id);
                        }}
                        className='delete-icon-button'
                      />
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
        <DeleteModal show={deleteShow} setShow={setDeleteShow} content='Brands' deleteItem={deleteBrand} itemId={id} />
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesNum={pagesNum}
        paginated={paginated}
        setPaginated={setPaginated}
      />
    </>
  );
};

export default BrandsTable;
