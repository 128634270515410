import React from 'react'
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux'
import { applyDiscount } from '../redux/actions/discountsActions';

function ApplyDiscounts({ show, setShow, content, modalTitle, modalClosing, catType, itemId, type }) {

    const dispatch = useDispatch();
    const discounts = useSelector(state => state.discounts.discounts?.data?.discounts)
    const [discoun, setDiscount] = React.useState(discounts? discounts[0]?._id : null);
    React.useEffect(() => {
        setShow(modalClosing)
    }, [modalClosing, setShow])
   
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(applyDiscount({discountId: discoun, morphId: [itemId], morphType: type, categoryType: catType}))
        setShow(false)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >

                <div className='title-close-wrapper'>
                    <Modal.Title>
                        <span>{modalTitle}</span>
                    </Modal.Title>
                    <MdClose onClick={() => setShow(false)} className='close-modal' />
                </div>
                <hr />

                <Modal.Body>
                    <div>
                       <form onSubmit={handleSubmit}>
                            <select
                                onChange={(e) => setDiscount(e.target.value)}
                                class="custom-select app-select mr-sm-2"
                                id="inlineFormCustomSelect"
                            >
                                {
                                    discounts?.map((row, index) => {
                                        return (
                                            <option key={index} value={row?._id}>{row.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <button type='submit' className='save-button'>SAVE</button>
                       </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default ApplyDiscounts