import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { assignToShipper, editOrderStatus } from '../../redux/actions/ordersActions';

const EditOrder = ({ setShow, id, row }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const shippers = useSelector(state => state.shippers.shippers?.data?.docs)
    const [status, setStatus] = React.useState(row.status);
    const [shipper, setShipper] = React.useState(row?.shipperId?._id);
    const handleSubmit = (e) => {
        e.preventDefault();
        if(status !== row.status){
            dispatch(editOrderStatus(id, status))
        }
        if(shipper !== row?.shipperId?._id) {
            dispatch(assignToShipper(id, shipper))
        }
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-12'>
                    <select defaultValue={status} className='app-select' onChange={(e) => setStatus(e.target.value)}>
                        <option value='accepted'>{t("Order.Accepted")}</option>
                        <option value='pending'>{t("Order.Pending")}</option>
                        <option value='preparing'>{t("Order.Preparing")}</option>
                        <option value='ready'>{t("Order.Ready")}</option>
                        <option value='refused'>{t("Order.Refused")}</option>
                        <option value='shipping'>{t("Order.Shipping")}</option>
                        <option value='fulfilled'>{t("Order.Fullifilled")}</option>
                    </select>
                </div>
                <div className='col-sm-12'>
                    <select defaultValue={shipper} className='app-select' onChange={(e) => setShipper(e.target.value)}>
                        {
                            shippers?.map((shipper, index) => {
                                return(
                                    <option key={index} value={shipper?._id}>{shipper?.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditOrder;