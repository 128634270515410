import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getSliders = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/slider?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.SLIDERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_SLIDERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getSliderById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/slider/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_SLIDER_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addSlider = (title, description, link, mobileURL, image) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("title", title);
  inputsData.append("description", description);
  inputsData.append("mobileURL", mobileURL);
  inputsData.append("link", link);
  inputsData.append("image", image);
  axios
    .post(`https://matjrna.co/api/v1/slider`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_SLIDER,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة بنار جديد" : "New slider has been added");
    })
    .catch((error) => myError(error?.response?.message));
};

export const editSlider = (id, title, description, link, mobileURL, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("title", title);
  inputsData.append("description", description);
  inputsData.append("link", link);
  inputsData.append("mobileURL", mobileURL);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/slider/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_SLIDER,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل البنار" : "Slider has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteSlider = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/slider/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_SLIDER,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف البنار" : "Slider has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
