import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ImagePicker from "../../components/ImagePicker";
import { answerInquiry, getInquiryById } from "../../redux/actions/inquiriesActions";

const ViewInquiryContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { inquiryId } = useParams();
  const inquiry = useSelector((state) => state?.inquiries?.inquiry);
  const updated = useSelector((state) => state?.inquiries?.updated);
  const [answer, setAnswer] = React.useState();
  const [image, setImage] = React.useState();
  const oldImage = inquiry?.answerImage;
  React.useEffect(() => {
    dispatch(getInquiryById(inquiryId));
  }, [updated, dispatch, inquiryId]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(answerInquiry(inquiryId, answer, image, oldImage));
  };
  return (
    <div className='view-inquiry-page'>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <div className='bold'>
            {inquiry?.userId?.name}: <span>{inquiry?.inquiry}</span>
          </div>
          <img alt='img' className='inquiry-image' src={`https://matjrna.co/imgs/inquiry/${inquiry?.inquiryImage}`} />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          {typeof inquiry?.answer == "string" && inquiry?.answer !== "undefined" ? (
            <div className='bold'>
              {t("Inquiries.Answer")}: <span>{inquiry?.answer}</span>
            </div>
          ) : null}
          {typeof inquiry?.answerImage == "string" && inquiry?.answerImage !== "undefined" ? (
            <img alt='img' className='inquiry-image' src={`https://matjrna.co/imgs/inquiry/${inquiry?.answerImage}`} />
          ) : null}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          onChange={(e) => setAnswer(e.target.value)}
          placeholder='Please Answer The Inquiry'
          class='form-control inquiry-answer-field'
          id='exampleFormControlTextarea1'
          rows='3'></textarea>
        <div className='answer-inquiry-wrapper'>
          <button type='submit' className='btn answer-inquiry-button'>
            Submit Answer
          </button>
          <ImagePicker onChange={(e) => setImage(e.target.files[0])} style={{ width: "200px", marginTop: "10px" }} />
        </div>
      </form>
    </div>
  );
};

export default ViewInquiryContent;
