import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getDiscounts = (currentPage, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/discount?page=${currentPage}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: types.DISCOUNTS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_DISSCOUNTS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getDiscountById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/discount/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_DISCOUNT_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addDiscount = (discountData) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/discount`, discountData)
    .then((res) => {
      dispatch({
        type: types.ADD_DISCOUNT,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة خصم جديد" : "New discount has been added");
    })
    .catch((error) => myError(error.response.data.message));
};

export const editDiscount = (id, inputsData) => (dispatch) => {
  axios
    .patch(`https://matjrna.co/api/v1/discount/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_DISCOUNT,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل الخصم" : "Discount has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteDiscount = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/discount/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_DISCOUNT,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف الخصم" : "Discount has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};

export const applyDiscount = (data) => (dispaatch) => {
  axios
    .post(`https://matjrna.co/api/v1/discount/add-discount`, data)
    .then((res) => myToast(lang === "ar" ? "تم تطبيق الخصم بنجاح" : "Discount has been applied successfully"))
    .catch((error) => myError(error?.response?.message));
};
