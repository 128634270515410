import React from 'react';
import BasePage from '../../components/BasePage';
import ProductsTable from './ProductsContent';
import { useTranslation } from 'react-i18next';
import AddProduct from './AddProduct';
import { useSelector, useDispatch } from 'react-redux';
import { searchForProducts } from '../../redux/actions/proudctsActions';
import { setLoading } from '../../redux/actions/loading';
import { useHistory, useParams } from 'react-router';

const SearchProducts = () => {
    const { t } = useTranslation();
    const {searchQ, pageNum} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [show, setShow] = React.useState();
    const [search, setSearch] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const allProducts = useSelector(state => state.products.products?.data?.products)
    const pagesNum = useSelector(state => state.products.products?.pagesNum)
    const updated = useSelector(state => state.products.updated)
    React.useEffect(() => {
        dispatch(setLoading());
        dispatch(searchForProducts({q : searchQ}, currentPage))
    }, [currentPage, updated, searchQ, dispatch])
    const searchAction = () => {
        history.push(`/products/search-about/${search}/1`)
    }
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        if (paginated) {
            history.push(`/products/search-about/${searchQ}/${currentPage}`)
        }
    }, [currentPage, paginated, history, searchQ])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    return (
        <BasePage
            modalContent={<AddProduct setShow={setShow} />}
            childs={<ProductsTable paginated={paginated} setPaginated={setPaginated} currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} theProducts={allProducts} />}
            pageTitle={t("SideBar.Products")}
            buttonTitle={t("Labels.Products")}
            addModalClosing={show}
            searchForm
            addButton
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Products")}
            searchContent={search}
            setSearchContent={setSearch}
            searchAction={searchAction}
        />
    )
}

export default SearchProducts;