import React from 'react'
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md'

function MyModal({ trueShow, content, modalTitle, modalClosing }) {
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        setShow(trueShow)
    }, [trueShow])

    React.useEffect(() => {
        setShow(modalClosing)
    }, [modalClosing])


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >

                <div className='title-close-wrapper'>
                    <Modal.Title>
                        <span>{modalTitle}</span>
                    </Modal.Title>
                    <MdClose onClick={() => setShow(false)} className='close-modal' />
                </div>
                <hr />

             <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        </>

    )
}

export default MyModal