import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { editTerm, getTermById } from '../../redux/actions/termsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';


const EditTermContent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const term = useSelector(state => state.terms.term)
    const {termId} = useParams();
    const editor = useRef(null)
    const [contentEn, setContentEn] = useState(term?.termsAndConditionsEn);
    const [contentAr, setContentAr] = useState(term?.termsAndConditionsAr);
    const [aboutEn, setAboutEn] = useState(term?.aboutEn);
    const [aboutAr, setAboutAr] = useState(term?.aboutAr);

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    const handleSubmit = () => {
        dispatch(editTerm(termId, { termsAndConditionsAr: contentAr, termsAndConditionsEn: contentEn, aboutAr, aboutEn }))
        history.push('/terms/1')
    }
    React.useEffect(() => {
        dispatch(getTermById(termId))
    }, [termId, dispatch])
    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>{t("Lang.English")}</Tab>
                    <Tab>{t("Lang.Arabic")}</Tab>
                </TabList>

                <TabPanel>
                    <input defaultValue={term?.aboutEn} onChange={(e) => setAboutEn(e.target.value)} autoFocus className='form-control app-input' placeholder='About En' />
                    <div>
                        <JoditEditor
                            ref={editor}
                            value={term?.termsAndConditionsEn}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newcontentEn => setContentEn(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                            onChange={newcontentEn => { }}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <input defaultValue={term?.aboutAr} onChange={(e) => setAboutAr(e.target.value)} autoFocus className='form-control app-input' placeholder='About Ar' />
                    <div>
                        <JoditEditor
                            ref={editor}
                            value={term?.termsAndConditionsAr}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newcontentEn => setContentAr(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                            onChange={newcontentEn => { }}
                        />
                    </div>
                </TabPanel>
            </Tabs>
            <button className='save-button mt-2' onClick={handleSubmit}>{t("Buttons.Save")}</button>
        </>
    );
}

export default EditTermContent;