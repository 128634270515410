import React from 'react';
import BasePage from '../../components/BasePage';
import CitiesTabe from './CitiesContent';
import { useTranslation } from 'react-i18next';
import AddCity from './AddCity';

const Cities = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddCity setShow={setShow} />} 
            childs={<CitiesTabe />} 
            pageTitle={t("SideBar.Cities")}
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Cities")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Cities")}
        />
    )
}

export default Cities;