import React from 'react';
import BasePage from '../../components/BasePage';
import OrdersTable from './OrdersContent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../redux/actions/ordersActions';
import { useParams } from 'react-router';
import { setLoading } from '../../redux/actions/loading';

const Orders = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {orderStatus, pageNum} = useParams();
    const status = orderStatus.substring(1);
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const [show, setShow] = React.useState(false);
    const orders = useSelector(state => state.orders.orders)
    const loading = useSelector(state => state.orders.loading)
    const updated = useSelector(state => state.orders.updated)
    const allOrders = orders?.data?.orders;
    const pagesNum = orders?.pagesNum;
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getOrders(currentPage, status, 9))
    }, [updated, currentPage, orderStatus, dispatch, status])
    return(
        <BasePage 
            childs={<OrdersTable show={show} setShow={setShow} ifOrders currentPage={currentPage} setCurrentPage={setCurrentPage} allOrders={allOrders} loading={loading} pagesNum={pagesNum} />} 
            pageTitle={t("SideBar.Orders")}
            ordersFilter={true}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            
        />
    )
}

export default Orders;