import React from 'react';
import BasePage from '../../components/BasePage';
import CustomersTable from './CustomersContent';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../redux/actions/usersActions';
import { setLoading } from '../../redux/actions/loading';

const Customers = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pageNum } = useParams();
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const [paginated, setPaginated] = React.useState(false);
    const customers = useSelector(state => state.users.users)
    const loading = useSelector(state => state.users.loading)
    const updated = useSelector(state => state.users.updated)
    const allCustomers = customers?.data?.docs;
    const pagesNum = customers?.pagesNum;
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getUsers(currentPage))
    }, [currentPage, updated, dispatch])
    React.useEffect(() => {
        if (paginated) {
            history.push(`/customers/${currentPage}`)
        }
    }, [currentPage, paginated, history])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    return(
        <BasePage 
            childs={<CustomersTable pagination pagesNum={pagesNum} loading={loading} allCustomers={allCustomers} currentPage={currentPage} setCurrentPage={setCurrentPage} paginated={paginated} setPaginated={setPaginated} />} 
            pageTitle={t("SideBar.Users")}
        />
    )
}

export default Customers;