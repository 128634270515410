import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import InquiriesContent from './InquiriesContent';

const Inquiries = () => {
    const { t } = useTranslation();

    return (
        <BasePage
            childs={<InquiriesContent />}
            pageTitle={t("SideBar.Inquiries")}
            buttonTitle={t("Labels.Inquiries")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Inquiries")}
        />
    )
}

export default Inquiries;