import React from 'react';
import { useTranslation } from 'react-i18next';
import SubPage from '../../components/SubPage';
import AddPromoCodeContent from './AddPromoCodeContent';

const AddPromoCode = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<AddPromoCodeContent />}
            pageTitle={t("Actions.Add") + ' ' + t("Labels.Coupons")}
        />
    )
}

export default AddPromoCode;