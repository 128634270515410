import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getSubCats = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/category/all/subcategory?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.SUB_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_SUB_CATS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getListOfSubCats = (ids) => (dispatch) => {
  axios.post(`https://matjrna.co/api/v1/category/get-list-with-ids`, ids).then((res) => {
    dispatch({
      type: types.GET_LIST_OF_CATEGORIES,
      payload: res.data
    });
  });
};

export const getSubInMain = (mainId, currentPage, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/category/${mainId}/subcategory?page=${currentPage}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: types.SUB_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_SUB_IN_MAIN,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getSubCatById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/category/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_SUB_CAT_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addSubCat =
  (nameEn, nameAr, type, parentId, image, active, metaDescriptionEn, metaDescriptionAr, metaKeywordEn, metaKeywordAr) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("type", type);
    inputsData.append("parentId", parentId);
    inputsData.append("image", image);
    inputsData.append("active", active);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);

    axios
      .post(`https://matjrna.co/api/v1/category`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_SUB_CAT,
          payload: res
        });
        myToast(lang === "ar" ? "تم إضافة قسم فرعي جديد" : "New subcategory has been added");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const editSubCat =
  (
    id,
    nameEn,
    nameAr,
    type,
    parentId,
    image,
    oldImage,
    active,
    metaDescriptionEn,
    metaDescriptionAr,
    metaKeywordEn,
    metaKeywordAr
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("type", type);
    inputsData.append("parentId", parentId);
    inputsData.append("image", image);
    inputsData.append("old_image", oldImage);
    inputsData.append("active", active);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);
    axios
      .patch(`https://matjrna.co/api/v1/category/${id}`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_SUB_CAT,
          payload: res
        });
        myToast(lang === "ar" ? "تم تعديل القسم الفرعي" : "Subcategory has been edited");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const deleteSubCat = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/category/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_SUB_CAT,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف القسم الفرعي" : "Subcategory has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
