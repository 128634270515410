import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editShipper } from '../../redux/actions/shippersActions';


const EditShipper = ({ id, setShow, row}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [name,setName] = React.useState(row.name);
    const [company,setCompany] = React.useState(row.company);
    const [phone,setPhone] = React.useState(row.phone);
    const [email,setEmail] = React.useState(row.email);
    const [image, setImage] = React.useState();
    const oldImage = row.image;
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editShipper(id, name, company, email, phone, image, oldImage))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    console.log('ROOOOW', row)
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Admin.Name")}</label>
                    <input
                        placeholder={t("Admin.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Email")}</label>
                    <input
                        placeholder={t("Admin.Email")}
                        type="email"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={email}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Phone")}</label>
                    <input
                        placeholder={t("Admin.Phone")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPhone(e.target.value)}
                        defaultValue={phone}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Shipper.Company")}</label>
                    <input
                        placeholder={t("Shipper.Company")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setCompany(e.target.value)}
                        defaultValue={company}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditShipper;