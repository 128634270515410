import { ADD_COUPON, GET_COUPONS, GET_COUPON_BY_ID, LOADING } from '../actions/types'

const initialState = {
    coupons: [],
    coupon: [],
    updated: [],
    loading: true
}

const couponsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COUPONS:
            return {
                ...state,
                coupons: action.payload,
                loading: false
            };
        case GET_COUPON_BY_ID:
            return {
                ...state,
                coupon: action.payload?.data?.coupon,
                loading: false
            };
        case ADD_COUPON:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default couponsReducer;