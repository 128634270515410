import React from 'react';
import BasePage from '../../components/BasePage';
import OrdersTable from '../Orders/OrdersContent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TodayOrders = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [show, setShow] = React.useState(false);
    const orders = useSelector(state => state.orders.allOrders)
    const loading = useSelector(state => state.orders.loading)
    const allOrders = orders?.data?.orders;
    const pagesNum = orders?.pagesNum;
    const newDate = new Date().setHours(0, 0, 0, 0)
    const todayOrders = allOrders?.filter(order => new Date(order.createdAt) > newDate);
    const filterFive = todayOrders?.filter((order, index) => index < 5);
    return (
        <BasePage
            childs={ todayOrders?.length > 0?
                <OrdersTable show={show} setShow={setShow} currentPage={currentPage} setCurrentPage={setCurrentPage} allOrders={filterFive} loading={loading} pagesNum={pagesNum} />:
                <h3 className='text-center'>{t('Order.NoOrders')}</h3>
            }
            pageTitle={t('HomePage.TodayOrders')}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            viewMore
            viewMorePath='today-orders'

        />
    )
}

export default TodayOrders;