import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getShippers = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/shipper?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.SHIPPERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_SHIPPERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getShipperById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/shipper/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_SHIPPER_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addShipper = (name, company, email, phone, password, passwordConfirm, address, image) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("name", name);
  inputsData.append("company", company);
  inputsData.append("email", email);
  inputsData.append("phone", phone);
  inputsData.append("address", address);
  inputsData.append("image", image);
  inputsData.append("password", password);
  inputsData.append("passwordConfirm", passwordConfirm);
  axios
    .post(`https://matjrna.co/api/v1/shipper`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_SHIPPER,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة سائق جديد" : "New shipper has been added");
    })
    .catch((error) => myError(error.response.data.message));
};

export const editShipper = (id, name, company, email, phone, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("name", name);
  inputsData.append("company", company);
  inputsData.append("email", email);
  inputsData.append("phone", phone);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/shipper/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_SHIPPER,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل معلومات السائق" : "Shipper's information has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteShipper = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/shipper/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_SHIPPER,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف السائق" : "Shipper has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
