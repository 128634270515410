import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";

export const getOrders = (currentPage, stat, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/order?page=${currentPage}&status=${stat}`)
    .then((res) => {
      dispatch({
        type: types.ORDERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ORDERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getAllOrders = (currentPage, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/order?page=${currentPage}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: types.ORDERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ALL_ORDERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getUserOrders = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/user/get/orders/${id}`)
    .then((res) => {
      dispatch({
        type: types.ORDERS_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ORDERS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getOrderById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/order/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_ORDER_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteOrderById = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/order/${id}`)
    .then((res) => {
      dispatch({
        type: types.DELETE_ORDER_BY_ID,
        payload: res.data
      });
      console.log(res);
    })
    .catch((error) => myError(error?.response?.message));
};

export const editOrderStatus = (id, status) => (dispatch) => {
  axios
    .patch(`https://matjrna.co/api/v1/order/${id}`, { status })
    .then((res) => {
      dispatch({
        type: types.UPDATE_ORDER,
        payload: res
      });
      myToast("Order status has been updated");
    })
    .catch((error) => myError(error?.response?.message));
};

export const assignToShipper = (orderId, shipperId) => (dispatch) => {
  axios
    .patch(`https://matjrna.co/api/v1/order/shipper/add-shipper`, { orderId, shipperId })
    .then((res) => {
      dispatch({
        type: types.UPDATE_ORDER,
        payload: res
      });
      myToast(res.data.message);
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteArticleCategory = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/articleCategory/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_ARTICLE_CAT,
        payload: res
      });
      myToast("Category was deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
