import React from "react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditAdmin from "./EditAdmin";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAdmins } from "../../redux/actions/adminsActions";
import Spinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";
import { useParams, useHistory } from "react-router";
import { Hint } from "../../components/Tooltips";

const AdminsTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pageNum } = useParams();
  const [show, setShow] = React.useState();
  const [id, setId] = React.useState(1);
  const [row, setRow] = React.useState();
  const admins = useSelector((state) => state.admins.admins);
  const updated = useSelector((state) => state.admins.updated);
  const loading = useSelector((state) => state.admins.loading);
  const [deleteShow, setDeleteShow] = React.useState();
  const allAdmins = admins?.data?.docs;
  const pagesNum = admins?.pagesNum;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState(false);
  React.useEffect(() => {
    if (paginated) {
      history.push(`/admins/${currentPage}`);
    }
  }, [currentPage, paginated, history]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum]);
  React.useEffect(() => {
    dispatch(getAdmins(currentPage));
  }, [currentPage, updated, dispatch]);
  const handleDeleteItem = (id) => {
    setId(id);
    setDeleteShow(true);
    console.log("DELEEETE");
  };
  const viewAdminHandling = (id) => {
    history.push(`/view-admin/${id}`);
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      <table className='table table-hover'>
        <Modal
          trueShow={show}
          content={<EditAdmin id={id} row={row} setShow={setShow} />}
          modalTitle={t("Actions.Edit") + " " + t("Module.Admins")}
          modalClosing={setShow}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Admin.Phone")}</th>
            <th scope='col'>{t("Admin.Email")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allAdmins?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/admin/${row?.image}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {row?.name}
                </td>
                <td className='table-cell'>{row.phone}</td>
                <td className='table-cell'>{row.email}</td>
                <td className='table-cell'>
                  <Hint
                    title='EditAdmin'
                    childs={
                      <MdEdit
                        onClick={() => {
                          setShow(1);
                          setId(row._id);
                          setRow(row);
                          setTimeout(() => {
                            setShow(true);
                          }, []);
                        }}
                        className='edit-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='DeleteAdmin'
                    childs={<MdDelete onClick={() => handleDeleteItem(row._id)} className='delete-icon-button' />}
                  />{" "}
                  <Hint
                    title='AdminProfile'
                    childs={<MdVisibility onClick={() => viewAdminHandling(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal content='Admins' itemId={id} deleteItem={deleteAdmin} setShow={setDeleteShow} show={deleteShow} />
    </>
  );
};

export default AdminsTable;
