import React from 'react';
import SubPage from '../../components/SubPage';
import { getCatById } from '../../redux/actions/catActions';
import MainSubContent from './MainSubContent';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/actions/loading';
import { useTranslation } from 'react-i18next';


const MainSub = () => {
    const {mainId} = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const lang = localStorage.getItem('i18nextLng')
    const categoryName = useSelector(state => state.categories.category)
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getCatById(mainId))
    }, [mainId])
    console.log('test Ehab',categoryName)
    return(
        <SubPage childs={<MainSubContent />} 
        pageTitle={`${lang === 'en'?categoryName?.nameEn: categoryName?.nameAr} / ${t("SideBar.SubCategories")}`}  />
    )
}

export default MainSub