import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import { useTranslation } from 'react-i18next';
import ViewSliderContent from './ViewSliderContent';

const ViewSlider = () => {
    const {t} = useTranslation();
    return(
        <SubPage
            childs={<ViewSliderContent />} 
            pageTitle={t('Tooltips.ViewSlider')}
        />
    )
}

export default ViewSlider;