import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { MdDelete, MdEdit, MdPrint } from "react-icons/md";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { deleteImage, getProductById } from "../../redux/actions/proudctsActions";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import EditProduct from "./EditProducts";
import { getCats } from "../../redux/actions/catActions";
import { getBrands } from "../../redux/actions/brandsActions";
import { getSubCats } from "../../redux/actions/subCatActions";
import DeleteModal from "../../components/DeleteModal";
import { Hint } from "../../components/Tooltips";
import ProductData from "./ProductData";
import EditFromInside from "./EditFromInside";

const ViewProductContent = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [image, setImage] = React.useState();
  const product = useSelector((state) => state.products.product);
  const updated = useSelector((state) => state.products.updated);
  const deleteImageData = { image: image, proId: product?._id };
  const [edit, setEdit] = React.useState(false);
  const componentRef = React.useRef();
  React.useEffect(() => {
    dispatch(getCats());
    dispatch(getBrands());
    dispatch(getSubCats());
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(getProductById(productId));
  }, [updated, dispatch, productId]);

  const handleDeleteImage = (image) => {
    setImage(image);
    setDeleteShow(true);
  };
  return (
    <div ref={componentRef} className='view-product-wrapper'>
      <Modal
        trueShow={show}
        content={<EditProduct setShow={setShow} id={product?._id} row={product} />}
        modalTitle={t("Actions.Edit") + " " + t("Labels.Products")}
      />
      <div style={{ flex: "1" }}>
        <Carousel className='product-carousel'>
          {product?.images?.map((image, index) => {
            return (
              <div key={index}>
                <Hint
                  title='DeleteImage'
                  childs={<MdDelete className='delete-icon-button' onClick={() => handleDeleteImage(image)} />}
                />
                <img alt='img' src={`https://matjrna.co/imgs/product/${image}`} />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div style={{ flex: "1" }} className=' view-product-properties'>
        <div className='view-product-edit'>
          <ReactToPrint trigger={() => <MdPrint className='edit-icon-button' />} content={() => componentRef.current} />
          <MdEdit className='edit-icon-button' onClick={() => setEdit(true)} />
        </div>
        <div>
          {edit ? (
            <div className='edit-from-inside'>
              <EditFromInside setEdit={setEdit} row={product} id={product?._id} />
            </div>
          ) : (
            <ProductData row={product} />
          )}
        </div>
      </div>
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        itemId={deleteImageData}
        deleteItem={deleteImage}
        content='Image'
      />
    </div>
  );
};

export default ViewProductContent;
