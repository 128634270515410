import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editDiscount } from '../../redux/actions/discountsActions';


const EditDiscount = ({ setShow, id, row }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [name, setName] = React.useState(row.name);
    const [type, setType] = React.useState(row.type);
    const [value, setValue] = React.useState(row.value);
    const [startDate, setStartDate] = React.useState(row.startDate?.substring(0, 19));
    const [endDate, setEndDate] = React.useState(row.endDate?.substring(0, 19));
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editDiscount(id, { name, type, value, startDate, endDate }))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Name")}</label>
                    <input
                        placeholder={t("Discounts.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Type")}</label>
                    <select
                        placeholder={t("Discounts.Type")}
                        type="text"
                        className="custom-select app-select col-sm-6"
                        onChange={(e) => setType(e.target.value)}
                        defaultValue={type}
                    >
                        <option value='percent'>{t("Discounts.Percentage")}</option>
                        <option value='cash'>{t("Discounts.Cash")}</option>
                    </select>
                </div>
                <div className='col-sm-12'>
                    <label>{t("Discounts.Value")}</label>
                    <input
                        placeholder={t("Discounts.Value")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setValue(e.target.value)}
                        defaultValue={value}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.From")}</label>
                    <input
                        placeholder={t("Discounts.From")}
                        type="datetime-local"
                        className="form-control app-input col-sm-6 date-input"
                        onChange={(e) => setStartDate(e.target.value)}
                        defaultValue={startDate}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.To")}</label>
                    <input
                        placeholder={t("Discounts.To")}
                        type="datetime-local"
                        className="form-control app-input col-sm-6 date-input"
                        onChange={(e) => setEndDate(e.target.value)}
                        defaultValue={endDate}
                    ></input>
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditDiscount;