import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getListOfProducts, getSellCodeChilds, searchForProducts } from "../../redux/actions/proudctsActions";
import { getCats } from "../../redux/actions/catActions";
import { getListOfSubCats, getSubInMain } from "../../redux/actions/subCatActions";
import { editCoupon, getCouponById } from "../../redux/actions/couponsActions";
import { useHistory, useParams } from "react-router";
const EditPromoCodeContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.products.products?.data?.products);
  const mainCategories = useSelector((state) => state.categories.categories?.data?.docs);
  const subCategories = useSelector((state) => state.subCategories.subForMain?.data?.subCategories);
  const coupon = useSelector((state) => state.coupons.coupon);
  const childs = useSelector((state) => state.sellCode.products?.data?.docs);
  const updated = useSelector((state) => state.sellCode.updated);
  const history = useHistory();
  const { promoId } = useParams();
  const [title, setTitle] = React.useState(coupon?.title);
  const [selectAll, setSelectAll] = React.useState("dis");
  const [name, setName] = React.useState(coupon?.name);
  const [type, setType] = React.useState(coupon?.type);
  const [value, setValue] = React.useState(coupon?.value);
  const [startDate, setStartDate] = React.useState(coupon?.startDate);
  const [endDate, setEndDate] = React.useState(coupon?.endDate);
  const [limit, setLimit] = React.useState(coupon?.limit);
  const [limitPerUser, setLimitPerUser] = React.useState(coupon?.limitPerUser);
  const [codFeeOff, setCodFeeOff] = React.useState(coupon?.codFeeOff);
  const [deliveryFeeOff, setDeliveryFeeOff] = React.useState(coupon?.deliveryFeeOff);
  const [includedProducts, setIncludedProducts] = React.useState(coupon?.includedProducts);
  const [excludedProducts, setExcludedProducts] = React.useState(coupon?.excludedProducts);
  const [includedCategories, setIncludedCategories] = React.useState(coupon?.includedCategories);
  const [discountExcluded, setDiscountExcluded] = React.useState(coupon?.discountExcluded);
  const [discountIncludedOnly, setDiscountIncludedOnly] = React.useState(coupon?.discountIncludedOnly);
  const [includedP, setIncludedP] = React.useState(false);
  const [includedC, setIncludedC] = React.useState(false);
  const [excludedP, setExcludedP] = React.useState(false);
  const [mainCategory, setMainCategory] = React.useState(mainCategories ? mainCategories[0]._id : null);
  const [searchContent, setSearchContent] = React.useState("");
  const listOfProducts = useSelector((state) => state.products.listOfProducts?.data?.docs);
  const listOfCats = useSelector((state) => state.sellCode.listOfSubCats?.data?.docs);
  const [active, setActive] = React.useState(coupon?.active);
  const [paymentType, setPaymentType] = React.useState(coupon?.payWithCard);
  React.useEffect(() => {
    setIncludedProducts(coupon?.includedProducts);
    setExcludedProducts(coupon?.excludedProducts);
    setIncludedCategories(coupon?.includedCategories);
    setActive(coupon?.active);
  }, [coupon]);

  // console.log("ACTIVE", active)

  const [childProducts, setChildProducts] = React.useState([]);
  const [action, setAction] = React.useState("add");

  console.log(childProducts);
  console.log(action);

  React.useEffect(() => {
    setChildProducts(childs?.map((row) => row._id));
  }, [updated, childs]);
  React.useEffect(() => {
    if (includedP) {
      if (action === "add") {
        setIncludedProducts(includedProducts?.concat(childProducts));
      } else {
        setIncludedProducts(includedProducts?.filter((item) => !childProducts.includes(item)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childProducts]);

  React.useEffect(() => {
    if (excludedP) {
      if (action === "add") {
        setExcludedProducts(excludedProducts?.concat(childProducts));
      } else {
        setExcludedProducts(excludedProducts?.filter((item) => !childProducts.includes(item)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childProducts]);

  console.log("CCCCCCCC", includedCategories);
  console.log("XXXXXXXX", excludedProducts);

  React.useEffect(() => {
    dispatch(
      getListOfProducts({
        list: coupon?.includedProducts?.length > 0 ? coupon?.includedProducts : coupon?.excludedProducts
      })
    );
    dispatch(getListOfSubCats({ list: coupon?.includedCategories ?? null }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedProducts, coupon]);

  React.useEffect(() => {
    if (coupon?.includedProducts?.length > 0) {
      setIncludedP(true);
    }
    if (coupon?.includedCategories?.length > 0) {
      setIncludedC(true);
    }
    if (coupon?.excludedProducts?.length > 0) {
      setExcludedP(true);
    }
    if (
      coupon?.excludedProducts?.length === 0 &&
      coupon?.includedCategories?.length === 0 &&
      coupon?.includedProducts?.length === 0 &&
      coupon?.discountIncludedOnly === false &&
      coupon?.discountExcluded === false
    ) {
      setSelectAll(true);
    }
  }, [coupon]);

  console.log("SSSSSSSSSSS", selectAll);

  React.useEffect(() => {
    dispatch(getCats());
    dispatch(getCouponById(promoId));
    setDeliveryFeeOff(coupon?.deliveryFeeOff);
    setCodFeeOff(coupon?.codFeeOff);
  }, [dispatch, promoId, coupon?.deliveryFeeOff, coupon?.codFeeOff]);
  React.useEffect(() => {
    dispatch(getSubInMain(mainCategory, 1, 5000));
  }, [mainCategory, dispatch]);
  const setAllProductsHandling = () => {
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setDiscountExcluded(false);
    setDiscountIncludedOnly(false);
    setIncludedP(false);
    setExcludedP(false);
    setIncludedC(false);
  };

  const DiscountIncludedHandling = () => {
    setDiscountIncludedOnly(true);
    setDiscountExcluded(false);
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setIncludedP(false);
    setExcludedP(false);
    setIncludedC(false);
  };

  const DiscountExcludedHandling = () => {
    setDiscountIncludedOnly(false);
    setDiscountExcluded(true);
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setIncludedP(false);
    setExcludedP(false);
    setIncludedC(false);
  };

  const ProductsIncludedHandling = () => {
    setDiscountIncludedOnly(false);
    setDiscountExcluded(false);
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setIncludedP(true);
    setExcludedP(false);
    setIncludedC(false);
  };

  const CategoriesIncludedHandling = () => {
    setDiscountIncludedOnly(false);
    setDiscountExcluded(false);
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setIncludedP(false);
    setExcludedP(false);
    setIncludedC(true);
  };

  const ProductsExcludedHandling = () => {
    setDiscountIncludedOnly(false);
    setDiscountExcluded(false);
    setIncludedProducts([]);
    setExcludedProducts([]);
    setIncludedCategories([]);
    setIncludedP(false);
    setExcludedP(true);
    setIncludedC(false);
  };

  const handleIncludedProductCheck = (e, id) => {
    if (e.target.checked) {
      dispatch(getSellCodeChilds(id));
      setAction("add");
    } else {
      dispatch(getSellCodeChilds(id));
      setAction("delete");
    }
  };

  const handleExcludedProductsCheck = (e, id) => {
    if (e.target.checked) {
      dispatch(getSellCodeChilds(id));
      setAction("add");
    } else {
      dispatch(getSellCodeChilds(id));
      setAction("delete");
    }
  };

  const handleIncludedCatsCheck = (e, id) => {
    if (e.target.checked) {
      setIncludedCategories(includedCategories.concat([id]));
    } else {
      setIncludedCategories(includedCategories.filter((product) => product !== id));
    }
  };

  const handleExistedExcluded = (e, id) => {
    if (e.target.checked) {
      setExcludedProducts(excludedProducts.concat([id]));
    } else {
      setExcludedProducts(excludedProducts.filter((product) => product !== id));
    }
  };

  const handleExistedIncluded = (e, id) => {
    if (e.target.checked) {
      setIncludedProducts(includedProducts?.concat([id]));
    } else {
      setIncludedProducts(includedProducts?.filter((product) => product !== id));
      console.log(includedProducts, "INCLUDED");
      console.log("THEID", id);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchForProducts({ q: searchContent }, 1));
  };

  const handleSubmit = () => {
    dispatch(
      editCoupon(promoId, {
        title,
        name,
        type,
        value,
        payWithCard: paymentType,
        limit,
        startDate,
        endDate,
        limitPerUser,
        codFeeOff,
        deliveryFeeOff,
        includedProducts,
        excludedProducts,
        includedCategories,
        discountIncludedOnly,
        discountExcluded,
        active
      })
    );
    history.push("/coupons/1");
  };

  // console.log('CCCCCCCCCCC', listOfCats)

  return (
    <div>
      <div className='coupons-flex'>
        <span className='code-confirmation'>{t("Discounts.CodeInformation")}</span>
        <div>
          <div className='toggle-button-wrapper'>
            <label class='switch'>
              <input onClick={() => setActive(!active)} defaultChecked={coupon?.active} type='checkbox' />
              <span class='slider round'></span>
            </label>
            <span className='active-coupon'>{t("Discounts.ActiveCoupon")}</span>
          </div>
        </div>
      </div>

      <div className='hr2' />

      <form className='promo-form'>
        <div className='row'>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.Name")}</label>
            <input
              placeholder={t("Discounts.Name")}
              type='text'
              className='form-control app-input col-sm-6'
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={coupon?.title}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.Code")}</label>
            <input
              placeholder={t("Discounts.Code")}
              type='text'
              className='form-control app-input col-sm-6'
              onChange={(e) => setName(e.target.value)}
              defaultValue={coupon?.name}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.Type")}</label>
            <select
              placeholder={t("Discounts.Type")}
              type='text'
              className='custom-select app-select col-sm-6'
              onChange={(e) => setType(e.target.value)}
              value={coupon?.type}>
              <option value='percent'>{t("Discounts.Percentage")}</option>
              <option value='cash'>{t("Discounts.Cash")}</option>
            </select>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.PaymentType")}</label>
            <select
              placeholder={t("Discounts.PaymentType")}
              type='text'
              className='custom-select app-select col-sm-6'
              onChange={(e) => setPaymentType(e.target.value)}
              value={coupon?.payWithCard}>
              <option value='card'>{t("Discounts.Card")}</option>
              <option value='cash'>{t("Discounts.Cashs")}</option>
              <option value='none'>{t("Discounts.None")}</option>
            </select>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.Value")}</label>
            <input
              placeholder={t("Discounts.Value")}
              type='text'
              className='form-control app-input col-sm-6'
              onChange={(e) => setValue(e.target.value)}
              defaultValue={coupon?.value}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.From")}</label>
            <input
              placeholder={t("Discounts.From")}
              type='datetime-local'
              className='form-control app-input col-sm-6 date-input'
              onChange={(e) => setStartDate(e.target.value)}
              defaultValue={coupon?.startDate?.substring(0, 19)}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.To")}</label>
            <input
              placeholder={t("Discounts.To")}
              type='datetime-local'
              className='form-control app-input col-sm-6 date-input'
              onChange={(e) => setEndDate(e.target.value)}
              defaultValue={coupon?.endDate?.substring(0, 19)}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.Limit")}</label>
            <input
              placeholder={t("Discounts.Limit")}
              type='text'
              className='form-control app-input col-sm-6 date-input'
              onChange={(e) => setLimit(e.target.value)}
              defaultValue={coupon?.limit}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.LimitPerUser")}</label>
            <input
              placeholder={t("Discounts.LimitPerUser")}
              type='text'
              className='form-control app-input col-sm-6 date-input'
              onChange={(e) => setLimitPerUser(e.target.value)}
              defaultValue={coupon?.limitPerUser}></input>
          </div>
          <div className='col-sm-6'>
            <label className='cou-label'>{t("Discounts.AdditionalOptions")}</label>
            <div className='flex-radio'>
              <input
                className='radio'
                type='checkbox'
                defaultChecked={coupon?.deliveryFeeOff}
                onClick={() => setDeliveryFeeOff(!deliveryFeeOff)}
              />
              <label>{t("Discounts.FreeDelivery")}</label>
            </div>
            <div className='flex-radio'>
              <input
                className='radio'
                type='checkbox'
                defaultChecked={coupon?.codFeeOff}
                onClick={() => setCodFeeOff(!codFeeOff)}
              />
              <label>{t("Discounts.CODFree")}</label>
            </div>
          </div>
        </div>
      </form>

      <span className='code-confirmation'>{t("Discounts.Products")}</span>
      <div className='hr2' />
      <div className='promo-form'>
        <Form.Label as='legend' column sm={2}>
          <span className='cou-label'>{t("Discounts.ApplyCouponOn")}</span>
        </Form.Label>
        <Col sm={10}>
          <div className='flex-radio'>
            <input
              className='radio'
              checked={
                coupon?.includedProducts?.length === 0 &&
                coupon?.includedCategories?.length === 0 &&
                coupon?.excludedProducts?.length === 0 &&
                coupon?.discountIncludedOnly === false &&
                coupon?.discountExcluded === false
                  ? true
                  : false
              }
              type='radio'
              name='formHorizontalRadios'
              id='formHorizontalRadios1'
              onClick={() => setAllProductsHandling()}
            />
            <label>{t("Discounts.AllProducts")}</label>
          </div>
          <div className='flex-radio'>
            <input
              className='radio'
              checked={includedP}
              type='radio'
              name='formHorizontalRadios'
              id='formHorizontalRadios2'
              onClick={() => ProductsIncludedHandling()}
            />
            <label>{t("Discounts.SpecificProducts")}</label>
          </div>
          {includedP ? (
            <div>
              <form className='search-coupon' onSubmit={handleSearch}>
                <input
                  onChange={(e) => setSearchContent(e.target.value)}
                  className='search-input'
                  type='text'
                  placeholder='Search'
                />
                <button type='submit' className='search-button'>
                  <FaSearch />
                </button>
              </form>
              {listOfProducts?.map((product, index) => {
                return (
                  <div className='flex-coupon'>
                    <img
                      className='product-coupon'
                      src={`https://matjrna.co/imgs/product/${product.imageCover}`}
                      alt='img'
                    />
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check defaultChecked={true} onChange={(e) => handleExistedIncluded(e, product._id)} />
                  </div>
                );
              })}
              {allProducts?.map((product, index) => {
                return (
                  <div className='flex-coupon'>
                    <img
                      className='product-coupon'
                      src={`https://matjrna.co/imgs/product/${product.imageCover}`}
                      alt='img'
                    />
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check onChange={(e) => handleIncludedProductCheck(e, product._id)} />
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className='flex-radio'>
            <input
              className='radio'
              type='radio'
              defaultChecked={includedC}
              name='formHorizontalRadios'
              id='formHorizontalRadios3'
              onClick={() => CategoriesIncludedHandling()}
            />
            <label>{t("Discounts.SpecificCategories")}</label>
          </div>
          {includedC ? (
            <div>
              <label className='cou-label'>{t("Module.MainCategories")}</label>
              <select
                required
                className='custom-select app-select mr-sm-2'
                id='inlineFormCustomSelect'
                onChange={(e) => setMainCategory(e.target.value)}>
                <option>Choose Main Category</option>
                {mainCategories?.map((row, index) => {
                  return (
                    <option value={row?._id}>
                      {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                    </option>
                  );
                })}
              </select>

              {listOfCats?.map((product, index) => {
                return (
                  <div id={index} className='flex-coupon p-2'>
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check
                      defaultChecked={true}
                      id={index}
                      onChange={(e) => handleIncludedCatsCheck(e, product._id)}
                    />
                  </div>
                );
              })}

              {subCategories?.map((product, index) => {
                return (
                  <div id={index} className='flex-coupon p-2'>
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check id={index} onChange={(e) => handleIncludedCatsCheck(e, product._id)} />
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className='flex-radio'>
            <input
              className='radio'
              type='radio'
              defaultChecked={excludedP}
              name='formHorizontalRadios'
              id='formHorizontalRadios4'
              onClick={() => ProductsExcludedHandling()}
            />
            <label>{t("Discounts.AllProductsExcept")}</label>
          </div>
          {excludedP ? (
            <div>
              <form className='search-coupon' onSubmit={handleSearch}>
                <input
                  onChange={(e) => setSearchContent(e.target.value)}
                  className='search-input'
                  type='text'
                  placeholder='Search'
                />
                <button type='submit' className='search-button'>
                  <FaSearch />
                </button>
              </form>
              {listOfProducts?.map((product, index) => {
                return (
                  <div className='flex-coupon'>
                    <img
                      className='product-coupon'
                      src={`https://matjrna.co/imgs/product/${product.imageCover}`}
                      alt='img'
                    />
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check defaultChecked={true} onChange={(e) => handleExistedExcluded(e, product._id)} />
                  </div>
                );
              })}
              {allProducts?.map((product, index) => {
                return (
                  <div className='flex-coupon'>
                    <img
                      className='product-coupon'
                      src={`https://matjrna.co/imgs/product/${product.imageCover}`}
                      alt='img'
                    />
                    <span className=''>{product?.nameEn}</span>
                    <Form.Check onChange={(e) => handleExcludedProductsCheck(e, product._id)} />
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className='flex-radio'>
            <input
              className='radio'
              type='radio'
              name='formHorizontalRadios'
              defaultChecked={coupon?.discountExcluded}
              id='formHorizontalRadios5'
              onClick={() => DiscountExcludedHandling()}
            />
            <label>{t("Discounts.AllExceptDiscountsProducts")}</label>
          </div>
          <div className='flex-radio'>
            <input
              className='radio'
              type='radio'
              defaultChecked={coupon?.discountIncludedOnly}
              name='formHorizontalRadios'
              id='formHorizontalRadios6'
              onClick={() => DiscountIncludedHandling()}
            />
            <label>{t("Discounts.DiscountsProducts")}</label>
          </div>
        </Col>
      </div>
      <button onClick={() => handleSubmit()} className='save-button' type='button'>
        {t("Buttons.Save")}
      </button>
    </div>
  );
};

export default EditPromoCodeContent;
