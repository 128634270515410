import { ADD_PRODUCTS, GET_LIST_OF_PRODUCTS, GET_MAIN_CATEGORY_PRODUCTS, GET_PRODUCTS, GET_PRODUCT_BY_ID, GET_SUB_CATEGORY_PRODUCTS, LOADING, PRODUCT_LOADING } from '../actions/types'

const initialState = {
    products: [],
    mainCategoryProducts: [],
    subCategoryProducts: [],
    product: [],
    updated: [],
    loading: true,
    listOfProducts: []
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false
            };
        case GET_LIST_OF_PRODUCTS:
            return {
                ...state,
                listOfProducts: action.payload,
                loading: false
            };
        case GET_MAIN_CATEGORY_PRODUCTS:
            return {
                ...state,
                mainCategoryProducts: action.payload,
                loading: false
            };
        case GET_SUB_CATEGORY_PRODUCTS:
            return {
                ...state,
                subCategoryProducts: action.payload,
                loading: false
            };
        case GET_PRODUCT_BY_ID:
            return {
                ...state,
                product: action.payload?.data?.product,
                loading: false
            };
        case ADD_PRODUCTS:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case PRODUCT_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default productsReducer;