import React from 'react';
import ProductsTable from './ProductsContent';
import { useTranslation } from 'react-i18next';
import AddProduct from './AddProduct';
import { useSelector, useDispatch } from 'react-redux';
import { getBestSellers } from '../../redux/actions/proudctsActions';
import { setLoading } from '../../redux/actions/loading';
import { useHistory, useParams } from 'react-router';
import SubPage from '../../components/SubPage';

const BestSellers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pageNum } = useParams();
    const history = useHistory();
    const [show, setShow] = React.useState();
    const [search, setSearch] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const allProducts = useSelector(state => state.products.products?.data?.docs)
    const pagesNum = useSelector(state => state.products.products?.pagesNum)
    const updated = useSelector(state => state.products.updated)
    React.useEffect(() => {
        dispatch(setLoading());
        dispatch(getBestSellers(currentPage))
    }, [currentPage, updated, dispatch])
    const searchAction = () => {
        history.push(`/products/search-about/${search}/1`)
    }
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        if (paginated) {
            history.push(`/best-sellers/${currentPage}`)
        }
    }, [currentPage, paginated, history])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    return (
        <SubPage
            modalContent={<AddProduct setShow={setShow} />}
            childs={<ProductsTable pagination paginated={paginated} setPaginated={setPaginated} currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} theProducts={allProducts} />}
            pageTitle={t("Product.BestSellers")}
            buttonTitle={t("Labels.Products")}
            addModalClosing={show}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Products")}
            searchContent={search}
            setSearchContent={setSearch}
            searchAction={searchAction}
        />
    )
}

export default BestSellers;