import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdDelete, MdEdit } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MyModal from '../../components/Modal';
import { editCompetition, getCompetitionById } from '../../redux/actions/competitionsActions';
import EditQuestion from './EditQuestion';
import AddQuestion from './AddQuestion';
import { Hint } from '../../components/Tooltips'

const ViewCompetitionContent = () => {
    const {t} = useTranslation();
    const {competitionId} = useParams();
    const [show, setShow] = React.useState();
    const [addShow, setAddShow] = React.useState();
    const [ques, setQues] = React.useState();
    const [editedQues, setEditedQues] = React.useState();
    const dispatch = useDispatch();
    const competition = useSelector(state => state.competitions.competition)
    const updated = useSelector(state => state.competitions.updated)
    const compToEdit = competition;
    const filteredQues = compToEdit?.questions?.filter(theQues => theQues?._id !== editedQues?._id)
    const allQuestions = filteredQues?.concat([editedQues]);
    React.useEffect(() => {
        dispatch(getCompetitionById(competitionId))
    }, [updated, dispatch, competitionId])
    const handleEdit = (row) => {
        setShow(1)
        setTimeout(() => {
            setShow(true)
        }, [])
        setQues(row)
    }
    const handleAddQues = () => {
        setAddShow(1)
        setTimeout(() => {
            setAddShow(true)
        }, [])
    }
    const theEditedQuestion = {
        name: competition?.name,
        startDate: competition?.startDate,
        endDate: competition?.endDate,
        questions: allQuestions,
    }

    const handleDeleteQues = (row) => {
        const filtered = compToEdit?.questions?.filter(theQues => theQues?._id !== row?._id)
        const editAfterDelete = {
            name: competition?.name,
            startDate: competition?.startDate,
            endDate: competition?.endDate,
            questions: filtered,
        }
        console.log('FILTERED FROM IN', filteredQues)
        dispatch(editCompetition(competition?._id, editAfterDelete, 'Question has been deleted'))
    }
    return (
        <div className='admin-profile'>
            <MyModal trueShow={addShow} modalTitle={t('Tooltips.AddQuestion')} content={<AddQuestion compAfterEdit={theEditedQuestion} question={editedQues} setQuestion={setEditedQues} id={competition?._id} row={ques} setShow={setAddShow} />} />
            <MyModal trueShow={show} modalTitle={t('Tooltips.EditQuestion')} content={<EditQuestion compAfterEdit={theEditedQuestion} question={editedQues} setQuestion={setEditedQues} id={competition?._id} row={ques} setShow={setShow} />} />
            <h2 className='text-center'>{competition?.name}</h2>
            <hr className='comp-hr' />
            <h5 className='text-center questions'>Questions</h5>
            <Hint title='AddQuestion' childs={
                <AiOutlinePlus className='edit-icon-button' onClick={handleAddQues} />
            } />
            {
                competition?.questions?.map((ques, index) => {
                    return(
                        <div key={index}>
                            <p className='text-center ques-header'>
                                <span>{index + 1}-</span> <span>{ques.header}</span> 
                                <Hint title='EditQuestion' childs={
                                    <MdEdit size={25} className='edit-icon-button' onClick={() => handleEdit(ques)} />
                                } />
                                <span> </span>
                                <Hint title='DeleteQuestion' childs={
                                    <MdDelete size={25} className='delete-icon-button' onClick={() => { handleDeleteQues(ques) }} />
                                } />
                            </p>
                            {
                                ques?.answers?.map((answer, index) => {
                                    return(
                                        <p key={index} className={`text-center ${answer?.is_right? 'right-answer': 'wrong-answer'}`}>
                                            <span>{index+1}-</span> <span>{answer?.content}</span>
                                        </p>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ViewCompetitionContent;