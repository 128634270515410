import React from 'react'
import SubPage from '../../components/SubPage';
import ProductsContent from '../Products/ProductsContent';
import { useParams } from 'react-router';
import { getMainCategoryProducts } from '../../redux/actions/proudctsActions';
import { useDispatch, useSelector } from 'react-redux';
import { getCatById } from '../../redux/actions/catActions';
import { setLoading } from '../../redux/actions/loading';
import { useTranslation } from 'react-i18next';

const MainCategoryProducts = () => {
    const {mainId} = useParams();
    const {t} = useTranslation();
    const lang = localStorage.getItem('i18nextLng')
    const [currentPage, setCurrentPage] = React.useState(1);
    const products = useSelector(state => state.products.mainCategoryProducts?.data?.docs)
    const pagesNum = useSelector(state => state.products.mainCategoryProducts?.pagesNum)
    const category = useSelector(state => state.categories.category)
    const catName = lang === 'en'? category?.nameEn : category?.nameAr
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getMainCategoryProducts(mainId, currentPage))
    }, [currentPage, dispatch, mainId])
    React.useEffect(() => {
        dispatch(getCatById(mainId))
    }, [dispatch, mainId])
    return(
            <SubPage
                childs={<ProductsContent pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} theProducts={products} />}
                pageTitle={`${catName} / ${t("Module.Products")}`}
            />
    )
}

export default MainCategoryProducts;