import React from 'react';
import { MdEdit, MdDelete, MdVisibility } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
// import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteTerm, getTerms } from '../../redux/actions/termsActions';
import Spinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal'
import EditTerm from './EditTerm';
import { Hint } from '../../components/Tooltips';

const TermsContent = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const lang = localStorage.getItem('i18nextLng')
    const [show, setShow] = React.useState();
    // const [currentPage, setCurrentPage] = React.useState(1);
    const currentPage = 1
    const [id, setId] = React.useState();
    const row = '';
    const [deleteShow, setDeleteShow] = React.useState();
    // const [paginated, setPaginated] = React.useState(false);
    const terms = useSelector(state => state.terms.terms)
    const loading = useSelector(state => state.terms.loading)
    const updated = useSelector(state => state.terms.updated)
    const allTerms = terms?.data?.terms;
    // const pagesNum = terms?.pagesNum;
    React.useEffect(() => {
        dispatch(getTerms(currentPage))
    }, [currentPage, updated, dispatch])
    const handleDelete = (id) => {
        setId(id)
        setDeleteShow(true)
    }
    const handleEdit = (id) => {
        history.push(`/edit-term/${id}`)
    }
    const viewSliderHandling = (id) => {
        history.push(`/view-term/${id}`)
    }
    return (
        loading? <Spinner /> :
            <>
                <table className="table table-hover">
                    <Modal
                        trueShow={show}
                        content={<EditTerm id={id} row={row} setShow={setShow} />}
                        modalTitle={t("Actions.Edit") + ' ' + t("Module.Terms")}
                        modalClosing={setShow}
                    />
                    <thead>
                        <tr>
                            <th scope="col">{t("Terms.About")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allTerms?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{lang === 'ar'? row.aboutAr : row.aboutEn}</td>
                                        <td className='table-cell' >
                                            <Hint title='EditTerm' childs={
                                                <MdEdit onClick={() => {
                                                    handleEdit(row._id)
                                                }} className='edit-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='DeleteTerm' childs={
                                                <MdDelete onClick={() => handleDelete(row._id)} className='delete-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='ViewTerm' childs={
                                                <MdVisibility onClick={() => viewSliderHandling(row._id)} className='edit-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                // <AppPagination
                //     currentPage={currentPage}
                //     setCurrentPage={setCurrentPage}
                //     paginated={paginated}
                //     setPaginated={setPaginated}
                //     pagesNum={pagesNum}
                // />
                }
                <DeleteModal 
                    itemId={id}
                    deleteItem={deleteTerm}
                    content='Terms'
                    show={deleteShow}
                    setShow={setDeleteShow}
                />
            </>
    )
}

export default TermsContent;