import React from 'react'
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function DeleteModal({ show, setShow, content, modalTitle, modalClosing, deleteItem, itemId }) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    React.useEffect(() => {
        setShow(modalClosing)
    }, [modalClosing, setShow])


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >

                <div className='title-close-wrapper'>
                    <Modal.Title>
                        <span>{t('Actions.DeleteConfirmation')}</span>
                    </Modal.Title>
                    <MdClose onClick={() => setShow(false)} className='close-modal' />
                </div>
                <hr />

                <Modal.Body>
                    <div>
                        <p className='delete-modal-text'>{t('Actions.Sure')} {t(`Module.${content}`)} </p>
                        <div className='delete-buttons-wrapper'>
                            <button className='cancel-delete' onClick={() => setShow(false)}>{t("Buttons.Cancel")}</button>
                            <button onClick={() => {
                                dispatch(deleteItem(itemId))
                                setShow(false)
                            }} className='confirm-delete'>{t("Buttons.Confirm")}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default DeleteModal