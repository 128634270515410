import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const lang = localStorage.getItem('i18nextLng')


export function myToast(text) {
    toast(<span className='notification-text'>{text}</span>, {
        position: lang === 'ar' ? toast.POSITION.BOTTOM_LEFT : toast.POSITION.BOTTOM_RIGHT,
        progressStyle: { color: "#1976d2", background: "#1976d2" },
    });
}

export function myError(text) {
    toast(text, {
        position: lang === 'ar' ? toast.POSITION.BOTTOM_LEFT : toast.POSITION.BOTTOM_RIGHT,
        progressStyle: { color: "red", background: "red" },
    });
}