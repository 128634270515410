import React from 'react';
import { MdEdit, MdVisibility,MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import AppPagination from '../../components/Pagination';
import { useHistory, useParams } from 'react-router';
import AppSpinner from '../../components/Spinner/Spinner'
import { useDispatch } from 'react-redux';
import {getShippers} from '../../redux/actions/shippersActions';
import Modal from '../../components/Modal'
import EditOrder from './EditOrder';
import { Hint } from '../../components/Tooltips';
import { deleteOrderById } from '../../redux/actions/ordersActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const OrdersTable = ({allOrders, loading, pagesNum, currentPage, setCurrentPage, ifOrders, show, setShow}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [row, setRow] = React.useState();
    const [id, setId] = React.useState();
    const {pageNum, orderStatus} = useParams();
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        dispatch(getShippers())
        if (paginated) {
            history.push(`/orders/${currentPage}/${orderStatus}`)
        }
    }, [currentPage, paginated, history, orderStatus, dispatch])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    const viewOrder = (id) => {
        history.push(`/view-order/${id}`)
    }
    const deleteOrder=(id)=>{
        // console.log(id)
        dispatch(deleteOrderById(id))
        // history.go(0)
    }
    const handleEdit = (row) => {
        setId(row._id)
        setRow(row)
        setShow(1);
        setTimeout(() => {
            setShow(true)
        }, [])
    }
    const submit = (id) => {
        confirmAlert({
          title: 'Confirm to Delete order',
          message: 'Are you sure to do delete this order.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => deleteOrder(id)
            },
            {
              label: 'No',
              onClick: () => console.log('Clicked No')
            }
          ]
        });
      };
    return (
       loading? <AppSpinner /> : 
            <>
                <Modal
                    trueShow={show}
                    content={<EditOrder row={row} id={id} setShow={setShow} />}
                    modalTitle={t("Actions.Edit") + ' ' + t("Module.Orders")}
                    modalClosing={show}
                />
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("Order.OrderNumber")}</th>
                            {ifOrders? <th scope="col">{t("Order.CustomerName")}</th> : null}
                            <th scope="col">{t("Order.OrderDate")}</th>
                            <th scope="col">{t("Order.PaymentMethod")}</th>
                            <th scope="col">{t("Order.Status")}</th>
                            <th scope="col">{t("Order.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allOrders?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row?.orderId}</td>
                                        {ifOrders ? <td className='table-cell' >{row?.customerId?.name}</td> : null}
                                        <td className='table-cell' >{new Date(row?.createdAt).toDateString()}</td>
                                        <td className='table-cell' >{row?.methodOfPayment}</td>
                                        <td className='table-cell' >{row?.status}</td>
                                        <td className='table-cell' >
                                        <Hint title='EditOrder' childs={
                                                <MdEdit onClick={() => handleEdit(row)} className='edit-icon-button' />
                                        } />
                                        {' '} 
                                        <Hint title='ViewOrder' childs={
                                                <MdVisibility onClick={() => viewOrder(row._id)} className='edit-icon-button' />
                                        } />
                                         {' '} 
                                        <Hint title='DeleteOrder' childs={
                                                <MdDelete onClick={() => submit(row._id)} className='delete-icon-button' />
                                        } /> 
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    ifOrders ? <AppPagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        paginated={paginated}
                        setPaginated={setPaginated}
                        pagesNum={pagesNum}
                    /> : null
                }
            </>
    )
}

export default OrdersTable;