import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import { LOGIN_ERROR, LOGIN } from "./types";
const lang = localStorage.getItem("i18nextLng");
toast.configure();
export const login = (adminData) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/admin/login`, adminData)
    .then((res) => {
      dispatch({
        type: LOGIN,
        payload: res.data
      });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("adminId", res.data.data?.admin._id);
      localStorage.setItem("adminName", res.data.data?.admin.name);
      localStorage.setItem("adminData", res.data.data);
      localStorage.setItem("authadjasoidsaf", true);
      myToast(lang === "ar" ? "لقد قمت بسجيل الدخول بنجاح" : "You logged in successfully");
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.data
      });
      myError(error?.response?.data?.message);
    });
};
