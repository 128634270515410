import { GET_ARTICLE_CATS, GET_ARTICLE_CAT_BY_ID, ADD_ARTICLE_CAT, ARTICLE_CAT_LOADING } from '../actions/types'

const initialState = {
    articleCategories: [],
    articleCategory: [],
    updated: [],
    loading: true
}

const articleCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLE_CATS:
            return {
                ...state,
                articleCategories: action.payload,
                loading: false
            };
        case GET_ARTICLE_CAT_BY_ID:
            return {
                ...state,
                articleCategory: action.payload?.data?.articleCategory,
                loading: false
            };
        case ADD_ARTICLE_CAT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case ARTICLE_CAT_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default articleCategoriesReducer;