import React from 'react';
import { BsList } from 'react-icons/bs'
import { SideData } from '../data/SideMenu';
import Logo from '../assets/Logo.png';
import Routes from '../routes';
import { BrowserRouter, Link } from 'react-router-dom';
import '../I18next.js';
import i18n from '../I18next.js';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import { BiLogOutCircle } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg'
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const SideMenu = () => {
    window.addEventListener('DOMContentLoaded', event => {

        // Toggle the side navigation
        const sidebarToggle = document.body.querySelector('#sidebarToggle');
        const pageContent = document.getElementById('the-content')
        if (sidebarToggle) {
            // Uncomment Below to persist sidebar toggle between refreshes
            // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            //     document.body.classList.toggle('sb-sidenav-toggled');
            // }
            sidebarToggle.addEventListener('click', event => {
                event.preventDefault();
                localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
                pageContent.classList.toggle('page-content');
            });

        }
    });
    const adminNameReducer = useSelector(state => state.login?.user?.data?.admin?.name)
    const adminIdReducer = useSelector(state => state.login?.user?.data?.admin?._id)
    const adminId = localStorage.getItem('adminId')
    const adminName = localStorage.getItem('adminName')
    const [open, setOpen] = React.useState(false)
    const nameOfAdmin = adminName?.length == null ?  adminNameReducer: adminName;
    const idOfAdmin = adminId?.length == null ? adminIdReducer: adminId;

    const logout = () => {
        localStorage.removeItem('adminName')
        localStorage.removeItem('adminId')
        localStorage.removeItem('token')
        localStorage.removeItem('authadjasoidsaf')
        window.location.reload()
    }
   
    return (
        <body className={open ? 'sb-sidenav-toggled' : null}>
        <BrowserRouter>
            <div>
                <ToastContainer rtl={localStorage.getItem('i18nextLng') === 'ar'? true: false} />
                <div className="d-flex" id="wrapper">
                    {/* SideBar */}
                        <div className={`border-end bg-white sidebar-wrapping ${open ? 'opened-side' : null}`} id="sidebar-wrapper">
                        <div className="sidebar-heading border-bottom bg-light"></div>
                        <div className='my-side-bar' style={{ overflowY: 'scroll', position: 'fixed', width: '15rem' }}>
                            {
                                SideData.map((section, index) => {
                                    return (
                                        <Link key={index} to={section.path} className='app-link'>
                                            <a
                                                className={`list-group-item list-group-item-action list-group-item-light p-3 side-items`}
                                                href="#a"
                                            >
                                                <section.icon className='section-icon' /> <span>{section.title}</span>
                                            </a>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div className="list-group list-group-flush">
                            <div>

                            </div>
                        </div>
                    </div>
                    {/* Page Content Wrapper */}
                    <div id="page-content-wrapper">
                        {/* Top Bar */}
                        <nav className="navbar navbar-expand-lg fixed-top border-bottom navbar navbar-expand-lg navbar navbar-light">
                            <div className="container-fluid navbar-content-wrapper">
                                <div>
                                    <BsList onClick={() => setOpen(!open)} style={{display: 'inline'}} size={41} className='toggle-icon' id="sidebarToggle" />
                                    <ul style={{display: 'inline'}} className="navbar-nav mr-auto mt-2 mt-lg-0">
                                        <Link to='/'>
                                            <img src={Logo} alt='logo' className='nav-logo' />
                                        </Link>
                                    </ul>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <DropdownButton id="dropdown-item-button" title={nameOfAdmin}>
                                        <Link className='link' to={`/view-admin/${idOfAdmin}`}>
                                            <Dropdown.Item className='dropdown-item-nav' as="button"> <CgProfile /> View Profile</Dropdown.Item>
                                        </Link>
                                        <Dropdown.Item onClick={logout} className='dropdown-item-nav' as="button"> <BiLogOutCircle /> Logout</Dropdown.Item>
                                    </DropdownButton>
                                    <span className='lang-switch splitter'>|</span>
                                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                        {localStorage.getItem('i18nextLng') === 'en' ?
                                            <h3 className='lang-switch' onClick={() => {
                                                i18n.changeLanguage('ar')
                                                window.location.reload();
                                            }}>العربية</h3> :
                                            <h3 className='lang-switch' onClick={() => {
                                                i18n.changeLanguage('en')
                                                window.location.reload();
                                            }}>English</h3>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        {/*Page Content */}
                        <div id='the-content' className="container-fluid side-content">
                            <Routes />
                        </div>
                    </div>
                </div>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/js/bootstrap.bundle.min.js"></script>

            </div>
        </BrowserRouter>
        </body>
    )
}

export default SideMenu;