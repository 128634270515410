const initialState = {
     newsLetter: [],
     loading: false
 }
 
 const newsLetterReducer = (state = initialState, action) => {
     switch (action.type) {
          
          default:
               return state;
     } 

 
 }
 
 export default newsLetterReducer;