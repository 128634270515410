import React from 'react';
import { useSelector } from 'react-redux';
import SideMenu from './layouts/SideMenu';
import Login from './modules/Login/Login';

const Application = () => {
    const logged = useSelector(state => state.login.logged)
    return(
        localStorage.getItem('authadjasoidsaf') === 'true' || logged === true ?
            <SideMenu />
            :
            <Login />
    )
}

export default Application;