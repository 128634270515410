import { LOADING, CARTS_LOADING, GET_CARTS, GET_CART_BY_ID } from '../actions/types'

const initialState = {
    carts: [],
    cart: [],
    updated: [],
    loading: true
}

const cartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARTS:
            return {
                ...state,
                carts: action.payload,
                loading: false
            };
        case GET_CART_BY_ID:
            return {
                ...state,
                cart: action.payload?.data?.cart,
                loading: false
            };
        case CARTS_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default cartsReducer;