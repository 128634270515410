import React from "react";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import { getDiscountProducts, removeDiscount } from "../../redux/actions/proDiscountsActions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import DeleteModal from "../../components/DeleteModal";

const DiscountsProductsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const discountProducts = useSelector((state) => state.productsDiscount.productsDiscount);
  const loading = useSelector((state) => state.productsDiscount.loading);
  const updated = useSelector((state) => state.productsDiscount.updated);
  const allDiscountProducts = discountProducts?.data?.docs;
  const pagesNum = discountProducts?.pagesNum;
  const [deleteShow, setDeleteShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [paginated, setPaginated] = React.useState(false);
  const [id, setId] = React.useState();
  React.useEffect(() => {
    dispatch(getDiscountProducts(currentPage));
  }, [currentPage, updated, dispatch]);
  const handleRemove = (id) => {
    setId(id);
    setDeleteShow(true);
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th scope='col'>{t("Module.Products")}</th>
            <th scope='col'>{t("Discounts.Type")}</th>
            <th scope='col'>{t("Module.Discounts")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allDiscountProducts?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/product/${row?.morphId?.imageCover}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.morphId?.nameEn : row.morphId?.nameAr}
                </td>
                <td className='table-cell'>{row?.morphType}</td>
                <td className='table-cell'>{row?.discountId?.name}</td>
                <td className='table-cell'>
                  <MdDelete onClick={() => handleRemove(row?.morphId?._id)} className='delete-icon-button' />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal
        itemId={id}
        deleteItem={removeDiscount}
        modalTitle='Remove Discount'
        content='Remove Discount'
        show={deleteShow}
        setShow={setDeleteShow}
      />
    </>
  );
};

export default DiscountsProductsTable;
