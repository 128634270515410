import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setLoading } from '../../redux/actions/loading';
import CartsContent from './CartsContent';
import { getCarts } from '../../redux/actions/cartsActions';

const Carts = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {pageNum} = useParams();
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const [show, setShow] = React.useState(false);
    const carts = useSelector(state => state.carts.carts)
    const loading = useSelector(state => state.carts.loading)
    const updated = useSelector(state => state.carts.updated)
    const allOrders = carts?.data?.carts;
    const pagesNum = 2;
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getCarts())
    }, [updated, currentPage, dispatch])
    return(
        <BasePage 
            childs={<CartsContent show={show} setShow={setShow} currentPage={currentPage} setCurrentPage={setCurrentPage} allOrders={allOrders} loading={loading} pagesNum={pagesNum} />} 
            pageTitle={t("SideBar.Carts")}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            
        />
    )
}

export default Carts;