import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editAdmin } from '../../redux/actions/adminsActions';


const EditAdmin = ({ setShow, id, row }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [name, setName] = React.useState(row.name);
    const [country, setCountry] = React.useState(row.country);
    const [phone, setPhone] = React.useState(row.phone);
    const [email, setEmail] = React.useState(row.email);
    const [role, setRole] = React.useState(row.role);
    const [address, setAddress] = React.useState(row.address);
    const [gender, setGender] = React.useState(row.gender);
    const [image, setImage] = React.useState(row.image);
    const oldImage = row.image;
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editAdmin(id, name, country, phone, email, role, address, gender, image, oldImage))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Admin.Name")}</label>
                    <input
                        placeholder={t("Admin.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Email")}</label>
                    <input
                        placeholder={t("Admin.Email")}
                        type="email"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={email}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Phone")}</label>
                    <input
                        placeholder={t("Admin.Phone")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setPhone(e.target.value)}
                        defaultValue={phone}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Country")}</label>
                    <input
                        placeholder={t("Admin.Country")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setCountry(e.target.value)}
                        defaultValue={country}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Role")}</label>
                    <select
                        placeholder={t("Admin.Role")}
                        type="text"
                        className="custom-select app-select"
                        onChange={(e) => setRole(e.target.value)}
                        defaultValue={role}
                    >
                        <option value='admin'>{t('Admin.Admin')}</option>
                        <option value='moderator'>{t('Admin.Moderator')}</option>
                        <option value='data-entry'>{t('Admin.DataEntry')}</option>
                    </select>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Admin.Gender")}</label>
                    <select
                        placeholder={t("Admin.Gender")}
                        type="text"
                        className="custom-select app-select"
                        onChange={(e) => setGender(e.target.value)}
                        defaultValue={gender}
                    >
                        <option value='male'>{t("Admin.Male")}</option>
                        <option value='female'>{t("Admin.Female")}</option>
                    </select>
                </div>
                <div className='col-sm-12'>
                    <label>{t("Admin.Address")}</label>
                    <input
                        placeholder={t("Admin.Address")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setAddress(e.target.value)}
                        defaultValue={address}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditAdmin;