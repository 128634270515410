import React from 'react'
import SubPage from '../../components/SubPage';
import ProductsContent from '../Products/ProductsContent';
import { useHistory, useParams } from 'react-router';
import { getSubCategoryProducts } from '../../redux/actions/proudctsActions';
import { useDispatch, useSelector } from 'react-redux';
import { getCatById } from '../../redux/actions/catActions';
import { setLoading } from '../../redux/actions/loading';
import { getSubCatById } from '../../redux/actions/subCatActions';
import { useTranslation } from 'react-i18next';

const MainSubProducts = () => {
    const { subId, mainId } = useParams();
    const {t} = useTranslation();
    const history = useHistory();
    const lang = localStorage.getItem('i18nextLng')
    const [currentPage, setCurrentPage] = React.useState(1);
    const products = useSelector(state => state.products.subCategoryProducts?.data?.docs)
    const pagesNum = useSelector(state => state.products.subCategoryProducts?.pagesNum)
    const category = useSelector(state => state.categories.category)
    const subCat = useSelector(state => state.subCategories.subCategory)
    const catName = lang === 'en'? category?.nameEn : category?.nameAr;
    const subCatName = lang === 'en' ? subCat?.nameEn : subCat?.nameAr
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getSubCategoryProducts(subId, currentPage))
    }, [currentPage, dispatch, subId])
    React.useEffect(() => {
        setTimeout(() => {
            dispatch(getCatById(mainId))
            dispatch(getSubCatById(subId))
        }, [200])
    }, [ dispatch, subId, mainId])
    const handleMainClick = () => {
        history.push(`/category/${mainId}/subcategories`)
    }
    return (
        <SubPage
            childs={<ProductsContent pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} theProducts={products} />}
            pageTitle={<span>
                <span className='back-link' onClick={handleMainClick}>{catName} </span>
                <span>/ {subCatName} </span>
                <span>/ {t("SideBar.Products")} </span>
            </span>}
        />
    )
}

export default MainSubProducts;