import React from 'react'
import SubPage from '../../components/SubPage';
import ProductsContent from '../Products/ProductsContent';
import { useParams } from 'react-router';
import { getSubCategoryProducts } from '../../redux/actions/proudctsActions';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/actions/loading';
import { getSubCatById } from '../../redux/actions/subCatActions';
import { useTranslation } from 'react-i18next';

const SubCategoryProducts = () => {
    const { subId } = useParams();
    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(1);
    const products = useSelector(state => state.products.subCategoryProducts?.data?.docs)
    const pagesNum = useSelector(state => state.products.subCategoryProducts?.pagesNum)
    const subCategory = useSelector(state => state?.subCategories.subCategory);
    const lang = localStorage.getItem('i18nextLng')
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getSubCategoryProducts(subId, currentPage))
        dispatch(getSubCatById(subId))
    }, [currentPage, dispatch, subId])
    React.useEffect(() => {
        dispatch(getSubCatById(subId))
    }, [dispatch, subId])
    return (
        <SubPage
            childs={<ProductsContent pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} theProducts={products} />}
            pageTitle={`${lang === 'en'? subCategory?.nameEn : subCategory?.nameAr} / ${t("SideBar.Products")}`}
        />
    )
}

export default SubCategoryProducts;