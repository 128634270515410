import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsCamera } from 'react-icons/bs'

const CardImagePicker = ({ onChange }) => {
    const { t } = useTranslation();
    return (
        <>
            <label for="file" className="file-button">{t("Article.CardImage")} <BsCamera className='file-input-icon' /></label>
            <input onChange={onChange} id="file" className='file-input' type="file"></input>
        </>
    )
}

export default CardImagePicker;