import React from 'react';
import BasePage from '../../components/BasePage';
import { useTranslation } from 'react-i18next';
import CompetitionsContent from './CompetitionsContent';
import AddCompetition from './AddCompetition';

const Competitions = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
   
    return(
        <BasePage 
            modalContent={<AddCompetition setShow={setShow} />}
            childs={<CompetitionsContent />} 
            pageTitle={t("SideBar.Competitions")} 
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Competitions")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Competitions")}
        />
    )
}

export default Competitions;