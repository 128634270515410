import React from 'react';
import { MdVisibility } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import AppPagination from '../../components/Pagination';
import { useHistory, useParams } from 'react-router';
import AppSpinner from '../../components/Spinner/Spinner'
import { useDispatch } from 'react-redux';
import {getShippers} from '../../redux/actions/shippersActions';
import { Hint } from '../../components/Tooltips';
// import { Hint } from '../../components/Tooltips';

const CartsContent = ({allOrders, loading, pagesNum, currentPage, setCurrentPage, ifOrders}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    
    const {pageNum, orderStatus} = useParams();
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        dispatch(getShippers())
        if (paginated) {
            history.push(`/orders/${currentPage}/${orderStatus}`)
        }
    }, [currentPage, paginated, history, orderStatus, dispatch])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    // const viewOrder = (id) => {
    //     history.push(`/view-order/${id}`)
    // }
    const handleView = (id) => {
        history.push(`view-cart/${id}`)
    }
    return (
       loading? <AppSpinner /> : 
            <>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("Carts.CustomerName")}</th>
                            <th scope="col">{t("Carts.CustomerNumber")}</th>
                            <th scope="col">{t("Carts.CustomerEmail")}</th>
                            <th scope="col">{t("Product.Actions")}</th>
                            {/* <th scope="col">{t("Order.Actions")}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allOrders?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{row?.userId?.name}</td>
                                        <td className='table-cell' >{row?.userId?.phone}</td>
                                        <td className='table-cell' >{row?.userId?.email}</td>
                                        <td className='table-cell' >
                                            <Hint title='ViewCart' childs={
                                                <MdVisibility onClick={() => handleView(row._id)} className='edit-icon-button' />
                                            } />
                                        </td>
                                        {/* <td className='table-cell' >
                                        <Hint title='ViewOrder' childs={
                                                <MdVisibility onClick={() => viewOrder(row._id)} className='edit-icon-button' />
                                        } />
                                        </td> */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    ifOrders ? <AppPagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        paginated={paginated}
                        setPaginated={setPaginated}
                        pagesNum={pagesNum}
                    /> : null
                }
            </>
    )
}

export default CartsContent;