import { GET_TERMS, ADD_TERM, TERMS_LOADING, GET_TERM_BY_ID } from '../actions/types'

const initialState = {
    terms: [],
    term: [],
    updated: [],
    loading: true
}

const termsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TERMS:
            return {
                ...state,
                terms: action.payload,
                loading: false
            };
        case GET_TERM_BY_ID:
            return {
                ...state,
                term: action.payload?.data?.term,
                loading: false
            };
        case ADD_TERM:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case TERMS_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default termsReducer;