import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubPage from '../../components/SubPage';
import ViewInquiryContent from './ViewInquiryContent';

const ViewInquiry = () => {
    return(
        <SubPage
            childs={<ViewInquiryContent />} 
            pageTitle='View Inquiry'
        />
    )
}

export default ViewInquiry;