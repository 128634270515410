import { ADD_PRODUCT_DISCOUNT, DISCOUNTS_PRODUCTS_LOADING, GET_PRODUCTS_DISCOUNT } from '../actions/types'

const initialState = {
    productsDiscount: [],
    updated: [],
    loading: true
}

const productsDiscountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS_DISCOUNT:
            return {
                ...state,
                productsDiscount: action.payload,
                loading: false
            };
        case ADD_PRODUCT_DISCOUNT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case DISCOUNTS_PRODUCTS_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default productsDiscountReducer;