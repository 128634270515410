import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addCoupon } from '../../redux/actions/couponsActions';


const AddCoupon = ({ setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [name, setName] = React.useState();
    const [type, setType] = React.useState('percent');
    const [value, setValue] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [limit, setLimit] = React.useState();
    const [paymentType,setPaymentType]=React.useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addCoupon({name, type, value,payWithCard:paymentType, startDate, endDate, limit}))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Name")}</label>
                    <input
                        placeholder={t("Discounts.Name")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Type")}</label>
                    <select
                        placeholder={t("Discounts.Type")}
                        type="text"
                        className="custom-select app-select col-sm-6"
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option value='percent'>{t("Discounts.Percentage")}</option>
                        <option value='cash'>{t("Discounts.Cash")}</option>
                    </select>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.PaymentType")}</label>
                    <select
                        placeholder={t("Discounts.PaymentType")}
                        type="text"
                        className="custom-select app-select col-sm-6"
                        onChange={(e) => setPaymentType(e.target.value)}
                    >
                        <option value='card'>{t("Discounts.Card")}</option>
                        <option value='cash'>{t("Discounts.Cashs")}</option>
                    </select>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Value")}</label>
                    <input
                        placeholder={t("Discounts.Value")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setValue(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.Limit")}</label>
                    <input
                        placeholder={t("Discounts.Limit")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setLimit(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.From")}</label>
                    <input
                        placeholder={t("Discounts.From")}
                        type="datetime-local"
                        className="form-control app-input col-sm-6 date-input"
                        onChange={(e) => setStartDate(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Discounts.To")}</label>
                    <input
                        placeholder={t("Discounts.To")}
                        type="datetime-local"
                        className="form-control app-input col-sm-6 date-input"
                        onChange={(e) => setEndDate(e.target.value)}
                    ></input>
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default AddCoupon;