import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/actions/notificationsActions';

const NotificationsContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = React.useState();
    const [text, setText] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addNotification({title, text}))
    }
    return (
        <>
        <div className='notifications-wrapper'>
            <div>
                <h4 className='text-center'>Push Notification</h4>
                <form className='notifications-form' onSubmit={handleSubmit}>
                    <input
                        placeholder={t("Slider.Title")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setTitle(e.target.value)}
                        autoFocus
                    ></input>
                    <input
                        placeholder={t("Notifications.Text")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setText(e.target.value)}
                    ></input>
                    <button type='submit' className='save-button'>{t("Notifications.Push")}</button>
            </form>
            </div>
        </div>
        </>
    )
}

export default NotificationsContent;