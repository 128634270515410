import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdEdit, MdPrint } from 'react-icons/md'
import logo from '../../assets/Logo.png'
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderById } from '../../redux/actions/ordersActions';
import { useParams } from 'react-router';
import EditOrder from './EditOrder';
import Modal from '../../components/Modal'
import { getShippers } from '../../redux/actions/shippersActions';

const ViewOrderContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [rendered, setRendered] = React.useState(false)
    const [desktopAddres, setDesktopAddres] = React.useState();
    const {orderId} = useParams();
    const componentRef = React.useRef();
    const [show, setShow] = React.useState(false);
    const order = useSelector(state => state?.orders?.order)
    const updated = useSelector(state => state?.orders?.updated)
    const myAddress = JSON.parse(order?.shipToAddress ? order?.shipToAddress : '{"name":"John", "age":30, "city":"New York"}');
    setTimeout(() => {
        setRendered(true)
    }, [1500])
    React.useEffect(() => {
        if(rendered) {
            if(order?.shipToAddress?.slice(0, 1) !== '{') {
                setDesktopAddres(JSON.parse(myAddress ? myAddress : '{"name":"John", "age":30, "city":"New York"}'))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rendered])
    const dummyObject = {};
    let arr = [];

    console.log(desktopAddres,order)

    for (const key of Object.keys(order?.cart?.items ? order?.cart?.items : dummyObject)) {
        arr.push(order?.cart?.items ? order?.cart?.items[key] : null)
    }
    const arrayOfDiscounts = [arr.map(row => row?.item?.discountType === 'cash' ?
        row?.item?.discount :
        (row?.item?.price * 100 / (100 - row?.item?.discount)) - row?.item?.price
    )]
    const myDiscounts = arrayOfDiscounts[0].reduce((a, b) => a + b, 0);
    const arrayOfPrices = [arr.map(row => row?.item?.price)];
    const myPrices = arrayOfPrices[0].reduce((a, b) => a + b, 0);
    const coupon = myPrices - order?.cart?.totalPrice;

    React.useEffect(() => {
        dispatch(getOrderById(orderId))
        dispatch(getShippers())
    }, [updated, dispatch, orderId])
    const handleEdit = () => {
        setShow(1);
        setTimeout(() => {
            setShow(true)
        }, [])
    }
    console.log('order=>', order)
    return (
        <>
            <div className='view-order-body' ref={componentRef} >
                <Modal
                    trueShow={show}
                    content={<EditOrder row={order} id={order?._id} setShow={setShow} />}
                    modalTitle='Edit Order'
                    modalClosing={show}
                />
                <div className='view-order-page logo-wrapper-orders'>
                    <div className='view-product-wrapper'>
                        <div className='order-half-screen col-12 col-print-6'>
                            <img alt='img' src={logo} className='order-logo' />
                        </div>
                        <div className='order-half-screen col-12 col-print-6 view-order-right' style={{position: 'relative'}}>
                            <div className='view-order-edit'>
                                <ReactToPrint
                                    trigger={() => (
                                            <MdPrint className='edit-icon-button' />
                                    )}
                                    content={() => componentRef.current}
                                />
                                
                                <MdEdit onClick={handleEdit} className='edit-icon-button ' />
                            </div>
                        </div>
                    </div>

                    <div className='view-product-wrapper order-properties'>
                        <div className='order-half-screen col-12 col-print-6'>
                            <div className='order-texts-wrapper'>
                                <p>#{order?.orderId}</p>
                                <p>{t("Order.PaymentMethod")}: {order?.methodOfPayment}</p>
                                <p>{t("Order.OrderedVia")}: {order?.origin === 'mobile'? t("Order.Mobile") : t("Order.Website")}</p>
                            </div>
                        </div>
                        <div className='order-half-screen col-12 col-print-6 view-order-right'>
                            <div className='order-status-wrapper'>
                                <p>{t("Order.OrderStatus")}: <span>{order?.status}</span></p>
                                <p>{t("Order.OrderDate")}: <span>{new Date(order?.createdAt).toDateString()}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className='hr'></div>

                    <div className='view-product-wrapper order-properties'>
                        <div className='order-half-screen col-12 col-print-6'>
                            <div className='shipper-data-wrapper'>
                                <p className='shipper-info'>{t("Order.ShipperInfo")}</p>
                                <p className='shipper-properties'>{order?.shipperId?.name}</p>
                                <p className='shipper-properties'>{order?.shipperId?.email}</p>
                                <p className='shipper-properties'>{order?.shipperId?.phone}</p>
                            </div>
                        </div>
                        <div className='order-half-screen col-12 col-print-6 view-order-right'>
                            <div>
                                <p className='customer-info'>{t("Order.CustomerInfo")}</p>
                                <p className='shipper-properties'>{order?.shipToName}</p>
                                <p className='shipper-properties'>{order?.shipToAddress?.includes('{') ?
                                    order?.shipToAddress?.slice(0, 1) === '{'?
                                 `${myAddress.home}, ${myAddress.street}, ${myAddress.district}, ${myAddress.city}` : 
                                 `${desktopAddres?.street}, ${desktopAddres?.city}, ${desktopAddres?.government}, ${desktopAddres?.country}`
                                 : order?.shipToAddress}</p>
                                <p className='shipper-properties'>{order?.customerId?.email}</p>
                                <p className='shipper-properties'>{order?.customerId?.phone}</p>
                            </div>
                        </div>
                    </div>

                    <table className="table table-hover order-table">
                        <thead>
                            <tr>
                                <th scope="col">{t("Order.ItemName")}</th>
                                <th scope="col">{t("Product.SellCode")}</th>
                                <th scope="col">{t("Product.Sizes")}</th>
                                <th scope="col">{t("Order.Color")}</th>
                                <th scope="col">{t("Order.UnitPrice")}</th>
                                <th scope="col">{t("Order.Unit")}</th>
                                <th scope="col">{t("Order.Total")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                arr?.map((row, index) => {
                                    return(
                                        <tr key={row}>
                                            <td>{localStorage.getItem('i18nextLng') === 'en'? row.item.nameEn : row.item.nameAr}</td>
                                            <td>{row.item.saleCode}</td>
                                            <td>{row.item.size}</td>
                                            <td>{row.item.color}</td>
                                            <td> {/* PRICE */}
                                                {row?.item?.discount ? (
                                                row?.item?.discountType === "cash" ? (
                                                    <><span style={{ color: "#999", fontSize: "10px" }}>
                                                            {(row?.item?.discount + row?.item?.price).toFixed(2)}
                                                        </span>
                                                        <span style={{ fontWeight: "bold", marginLeft: "2px" }}>
                                                            {row?.item?.price.toFixed(2)}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <> <span style={{ color: "#999", fontSize: "10px" }}>
                                                            {(
                                                                (row?.item?.price * 100) /
                                                                (100 - row?.item?.discount)
                                                            ).toFixed(2)}
                                                        </span>
                                                        <span style={{ fontWeight: "bold", marginLeft: "2px" }}>
                                                            {row?.item?.price?.toFixed(2)}
                                                        </span>
                                                    </>
                                                )
                                            ) : (
                                                row?.item?.price?.toFixed(2)
                                            )}
                                            </td> {/* PRICE */}
                                            <td>{row.quantity}</td>
                                            <td>{row.price?.toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <div className='price-terms-wrapper'>
                        <div className='center-children'>
                            <div className='prices-widget'>
                                <div>
                                    <p>{t("Order.Subtotal")}: {order?.cart?.totalPrice?.toFixed(2)} <span className='currency'>{t("Currency.Currency")}</span></p>
                                    <p>{t("Order.DeliveryFees")}: {order?.deliveryCost?.toFixed(2)} <span className='currency'>{t("Currency.Currency")}</span></p>
                                   {<p>{t("Order.CODFees")}: {order?.CODFee?.toFixed(2)||'0.00'} <span className='currency'>{t("Currency.Currency")}</span></p>}
                                    {coupon > 0 ? <p>{t("Module.Coupons")}: {coupon?.toFixed(2)} <span className='currency'>{t("Currency.Currency")}</span> ({order?.cart?.discount?.name})</p> : null}
                                    {myDiscounts > 0? <p>{t("Module.Discounts")}: {myDiscounts.toFixed(2)} <span className='currency'>{t("Currency.Currency")}</span></p> : null}
                                    <p>{t("Order.Grandtotal")}: {order?.totalCost} <span className='currency'>{t("Currency.Currency")}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className='policy-widget'>
                                <div>
                                    <p className='bold'>{t("Terms.ReturnPolicy")}</p>
                                    <p>• {t("Order.FiveDays")}</p>
                                    <p className='bold'>{t("Order.ExchangePolicy")}</p>
                                    <p>• {t("Order.ThreeDays")}</p>
                                    <p>• {t("Order.BillCopy")}</p>
                                    <p>• {t("Order.IncludedVat")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewOrderContent;