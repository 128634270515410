import { GET_USERS, GET_USER_BY_ID, USERS_LOADING, DELETE_USER, LOADING } from '../actions/types'

const initialState = {
    users: [],
    user: [],
    updated: [],
    loading: true
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                user: action.payload?.data?.user,
                loading: false
            };
        case DELETE_USER:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case USERS_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default usersReducer;