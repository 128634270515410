import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSliderById } from "../../redux/actions/slidersActions";
import { useParams } from "react-router";
import MyModal from "../../components/Modal";
import EditSliders from "./EditSlider";

const ViewSliderContent = () => {
  const { t } = useTranslation();
  const { sliderId } = useParams();
  const [show, setShow] = React.useState();
  const dispatch = useDispatch();
  const slider = useSelector((state) => state.sliders.slider);
  const updated = useSelector((state) => state.sliders.updated);
  React.useEffect(() => {
    dispatch(getSliderById(sliderId));
  }, [updated, dispatch, sliderId]);
  const handleEdit = () => {
    setShow(1);
    setTimeout(() => {
      setShow(true);
    }, []);
  };
  return (
    <div className='admin-profile'>
      <MyModal
        trueShow={show}
        modalTitle='Edit Slider'
        content={<EditSliders id={slider?._id} row={slider} setShow={setShow} />}
      />
      <div className='admdin-image-wrapper'>
        <img alt='img' src={`https://matjrna.co/imgs/slider/${slider?.image}`} className='slider-image' />
      </div>
      <div className=' view-product-properties'>
        <div className='view-product-edit'>
          <div></div>
          <MdEdit onClick={handleEdit} className='edit-icon-button' />
        </div>
        <div className='admin-information'>
          <p>
            <span>{t("Slider.Title")}:</span> {slider?.title}
          </p>
          <p>
            <span>{t("Slider.Description")}:</span> {slider?.description}
          </p>
          <p>
            <span>{t("Slider.Link")}:</span> {slider?.link}
          </p>
          <p>
            <span>{t("Slider.MobileUrl")}:</span> {slider?.mobileURL}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewSliderContent;
