import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppSpinner from '../../components/Spinner/Spinner';

const WidgetContent = () => {
    const {t} = useTranslation();
    const orders = useSelector(state => state.orders.allOrders)
    const allOrders = orders?.data?.orders?.length;
    const theOrders = orders?.data?.orders;
    const customerPages = useSelector(state => state.users.users?.pagesNum)
    const loading = useSelector(state => state.orders.loading)
    let arr = theOrders?.map(order => order?.totalCost);
    const earnings = arr?.reduce((a, b) => a + b, 0);
    console.log('ORDERS NUUMMMM', allOrders)
    return(
        loading? <AppSpinner /> :
            <div className='row widgets-wrapper'>
                <div className='col-md-14 col-12 single-widget' >
                    <div>
                        <p className='widget-header text-center'>{t('SideBar.Orders')}</p>
                        <p className='widget-content text-center'>{allOrders}</p>
                    </div>
                </div>
                <div className='col-md-14 col-12 single-widget' >
                    <div>
                        <p className='widget-header text-center'>{t("HomePage.Earnings")}</p>
                        <p className='widget-content text-center'>{earnings?.toFixed(2)} <span className='currency'>{t("Currency.Currency")}</span></p>
                    </div>
                </div>
                <div className='col-md-14 col-12 single-widget' >
                    <div>
                        <p className='widget-header text-center'>{t("SideBar.Users")}</p>
                        <p className='widget-content text-center'>{5 * customerPages}</p>
                    </div>
                </div>
            </div>
    )
}

export default WidgetContent;