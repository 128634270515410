import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import { addTerm } from '../../redux/actions/termsActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';


const AddTermContent = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const editor = useRef(null)
    const [contentEn, setContentEn] = useState('');
    const [contentAr, setContentAr] = useState('');
    const [aboutEn, setAboutEn] = useState('');
    const [aboutAr, setAboutAr] = useState('');

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    const handleSubmit = () => {
        dispatch(addTerm({ termsAndConditionsAr: contentAr, termsAndConditionsEn: contentEn, aboutAr, aboutEn }))
        history.push('/terms/1')
    }
    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>{t("Lang.English")}</Tab>
                    <Tab>{t("Lang.Arabic")}</Tab>
                </TabList>

                <TabPanel>
                    <input onChange={(e) => setAboutEn(e.target.value)} autoFocus className='form-control app-input' placeholder='About En' />
                    <div>
                        <JoditEditor
                            ref={editor}
                            value={contentEn}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newcontentEn => setContentEn(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                            onChange={newcontentEn => { }}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <input onChange={(e) => setAboutAr(e.target.value)} autoFocus className='form-control app-input' placeholder='About Ar' />
                    <div>
                        <JoditEditor
                            ref={editor}
                            value={contentAr}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newcontentEn => setContentAr(newcontentEn)} // preferred to use only this option to update the contentEn for performance reasons
                            onChange={newcontentEn => { }}
                        />
                    </div>
                </TabPanel>
            </Tabs>
            <button className='save-button mt-2' onClick={handleSubmit}>{t("Buttons.Save")}</button>
        </>
    );
}

export default AddTermContent;