import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import Application from './Auth';
if(localStorage.getItem('i18nextLng') === 'ar') {
  import('./indexAr.css')
} else if(localStorage.getItem('i18nextLng') !== 'ar') {
  import('./index.css')
}

function App() {
  return (
    <Suspense fallback='Loadding...'>
      <Provider store={store}>
      <div className="App">
        <Application />
      </div>
      </Provider>
    </Suspense>
  );
}

export default App;
