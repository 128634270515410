import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getAdmins = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/admin?page=${currentPage}`)
    .then((res) => {
      dispatch({
        type: types.LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_ADMINS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getAdminById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/admin/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_ADMIN_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addAdmin =
  (name, country, phone, email, role, address, gender, password, passwordConfirm, image) => (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("name", name);
    inputsData.append("country", country);
    inputsData.append("role", role);
    inputsData.append("gender", gender);
    inputsData.append("email", email);
    inputsData.append("phone", phone);
    inputsData.append("address", address);
    inputsData.append("image", image);
    inputsData.append("password", password);
    inputsData.append("passwordConfirm", passwordConfirm);
    axios
      .post(`https://matjrna.co/api/v1/admin`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_ADMIN,
          payload: res
        });
        myToast(lang === "ar" ? "تم إضافة مشرف جديد" : "New admin has been added");
      })
      .catch((error) => myError(error.response.data.message));
  };

export const editAdmin = (id, name, country, phone, email, role, address, gender, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("name", name);
  inputsData.append("country", country);
  inputsData.append("role", role);
  inputsData.append("gender", gender);
  inputsData.append("email", email);
  inputsData.append("phone", phone);
  inputsData.append("address", address);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/admin/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_ADMIN,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل معلومات المشرف" : "Admin's information has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteAdmin = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/admin/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_ADMIN,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف المشرف" : "Admin has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
