import React from 'react'
import ImagePicker from '../../components/ImagePicker';
import { useTranslation } from 'react-i18next';
import { addSlider } from '../../redux/actions/slidersActions';
import { useDispatch } from 'react-redux';


const EditSliders = ({ setShow }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = React.useState();
    const [description, setDescription] = React.useState();
    const [link, setLink] = React.useState();
    const [mobileURL, setMobileURL] = React.useState();
    const [image, setImage] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addSlider(title, description, link, mobileURL, image))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Slider.Title")}</label>
                    <input
                        placeholder={t("Slider.Title")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setTitle(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Slider.Link")}</label>
                    <input
                        placeholder={t("Slider.Link")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setLink(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Slider.Description")}</label>
                    <input
                        placeholder={t("Slider.Description")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setDescription(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Slider.MobileUrl")}</label>
                    <input
                        placeholder={t("Slider.MobileUrl")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setMobileURL(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <ImagePicker onChange={(e) => setImage(e.target.files[0])} />
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditSliders;