import React from "react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { FaPercentage } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import EditProducts from "./EditProducts";
import AppPagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getSellCodeProducts } from "../../redux/actions/proudctsActions";
import { getBrands } from "../../redux/actions/brandsActions";
import { getCats } from "../../redux/actions/catActions";
import { getSubCats } from "../../redux/actions/subCatActions";
import AppSpinner from "../../components/Spinner/Spinner";
import { useParams, useHistory } from "react-router";
import DeleteModal from "../../components/DeleteModal";
import ApplyDiscounts from "../../components/ApplyDiscount";
import { getDiscounts } from "../../redux/actions/discountsActions";
import { Hint } from "../../components/Tooltips";
import { setLoading } from "../../redux/actions/loading";
import { FiPlus } from "react-icons/fi";
import AddSameSell from "./AddSameSell";

const SellCodeContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sellCode, pageNum } = useParams();
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.loading);
  const updated = useSelector((state) => state.products.updated);
  const allProducts = products?.data?.docs;
  const pagesNum = products?.pagesNum;
  const [show, setShow] = React.useState();
  const [id, setId] = React.useState();
  const [row, setRow] = React.useState();
  const [deleteShow, setDeleteShow] = React.useState();
  const [addShow, setAddShow] = React.useState();
  const [discountShow, setDiscountShow] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(pageNum);
  const [paginated, setPaginated] = React.useState(false);
  React.useEffect(() => {
    if (paginated) {
      history.push(`/sell-code/${sellCode}/${currentPage}`);
    }
  }, [currentPage, paginated, history, sellCode]);
  React.useEffect(() => {
    setCurrentPage(parseInt(pageNum));
  }, [pageNum, setCurrentPage]);
  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getSellCodeProducts(sellCode, currentPage));
  }, [currentPage, updated, dispatch, sellCode]);
  React.useEffect(() => {
    dispatch(getBrands());
    dispatch(getCats());
    dispatch(getSubCats());
    dispatch(getDiscounts(1, 1000));
  }, [dispatch]);
  const viewProductHandling = (id) => {
    history.push(`/view-product/${id}`);
  };
  const handleEdit = (row) => {
    setId(row._id);
    setShow(1);
    setRow(row);
    setTimeout(() => {
      setShow(true);
    }, []);
  };
  const deleteItem = (id) => {
    setId(id);
    setDeleteShow(true);
  };
  const handleDiscountApply = (id) => {
    setDiscountShow(true);
    setId(id);
  };
  const handleAddWithSell = (row) => {
    setId(row._id);
    setAddShow(1);
    setRow(row);
    setTimeout(() => {
      setAddShow(true);
    }, []);
  };
  return loading ? (
    <AppSpinner />
  ) : (
    <>
      <table className='table table-hover'>
        <ApplyDiscounts
          itemId={id}
          modalTitle={t("Tooltips.ApplyDiscount")}
          type='Product'
          show={discountShow}
          setShow={setDiscountShow}
        />
        <Modal
          trueShow={show}
          content={<EditProducts setShow={setShow} id={id} row={row} />}
          modalTitle={t("Actions.Edit") + " " + t("Labels.Products")}
        />
        <Modal
          trueShow={addShow}
          content={<AddSameSell setShow={setAddShow} id={id} row={row} />}
          modalTitle={t("Actions.Add") + " " + t("Labels.Products")}
        />
        <thead>
          <tr>
            <th scope='col'>{t("Admin.Name")}</th>
            <th scope='col'>{t("Product.Price")}</th>
            <th scope='col'>{t("Product.Colors")}</th>
            <th scope='col'>{t("Product.Sizes")}</th>
            <th scope='col'>{t("Product.Category")}</th>
            <th scope='col'>{t("Admin.Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {allProducts?.map((row, index) => {
            return (
              <tr key={index}>
                <td className='table-cell'>
                  {" "}
                  <img
                    alt='img'
                    src={`https://matjrna.co/imgs/product/${row.imageCover}`}
                    className='table-image rounded-circle"'
                  />{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? row.nameEn : row.nameAr}
                </td>
                <td className='table-cell'>{row?.price}</td>
                <td className='table-cell'>{row.color}</td>
                <td className='table-cell'>{row.size}</td>
                <td className='table-cell'>{row.mainCategory?.nameEn}</td>
                <td className='table-cell'>
                  <Hint
                    title='ApplyDiscount'
                    childs={<FaPercentage onClick={() => handleDiscountApply(row._id)} className='edit-icon-button' />}
                  />{" "}
                  <Hint
                    title='EditProduct'
                    childs={<MdEdit onClick={() => handleEdit(row)} className='edit-icon-button' />}
                  />{" "}
                  <Hint
                    title='AddSameSell'
                    childs={<FiPlus onClick={() => handleAddWithSell(row)} className='edit-icon-button' />}
                  />{" "}
                  <Hint
                    title='DeleteProduct'
                    childs={
                      <MdDelete
                        onClick={() => {
                          deleteItem(row._id);
                        }}
                        className='delete-icon-button'
                      />
                    }
                  />{" "}
                  <Hint
                    title='ViewProduct'
                    childs={<MdVisibility onClick={() => viewProductHandling(row._id)} className='edit-icon-button' />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <AppPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginated={paginated}
        setPaginated={setPaginated}
        pagesNum={pagesNum}
      />
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        itemId={id}
        deleteItem={deleteProduct}
        content='Products'
      />
    </>
  );
};

export default SellCodeContent;
