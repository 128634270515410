import { GET_LIST_OF_CATEGORIES, GET_SELL_CODE_PRODUCTS, LOADING } from '../actions/types'

const initialState = {
    products: [],
    loading: true,
    updated: null
}

const sellCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SELL_CODE_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false,
                updated: Math.random()
            };
        case GET_LIST_OF_CATEGORIES:
            return {
                ...state,
                listOfSubCats: action.payload,
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default sellCodeReducer;