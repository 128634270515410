import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getBrands = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/brand?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.BRAND_LOADING,
        payload: ""
      });
      dispatch({
        type: types.GET_BRAND,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getBrandById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/brand/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_BRAND_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addBrand = (nameEn, nameAr, image) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("nameEn", nameEn);
  inputsData.append("nameAr", nameAr);
  inputsData.append("image", image);
  axios
    .post(`https://matjrna.co/api/v1/brand`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_BRAND,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة علامة تجارية" : "New brand has been added");
    })
    .catch((error) => myError(error?.response?.message));
};

export const editBrand = (id, nameEn, nameAr, image, oldImage) => (dispatch) => {
  const inputsData = new FormData();
  inputsData.append("nameEn", nameEn);
  inputsData.append("nameAr", nameAr);
  inputsData.append("image", image);
  inputsData.append("old_image", oldImage);
  axios
    .patch(`https://matjrna.co/api/v1/brand/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_BRAND,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل المنتج" : "Brand has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteBrand = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/brand/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_BRAND,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف العلامة التتجارية" : "Brand was deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
