import React from 'react';
import BasePage from '../../components/BasePage';
import AdminsTable from './AdminsContent';
import { useTranslation } from 'react-i18next';
import AddAdmin from './AddAdmin';

const Admins = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
   
    return(
        <BasePage 
            modalContent={<AddAdmin setShow={setShow} />}
            childs={<AdminsTable />} 
            pageTitle={t("SideBar.Admins")} 
            addButton
            addModalClosing={show}
            buttonTitle={t("Labels.Admins")}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.Admins")}
        />
    )
}

export default Admins;