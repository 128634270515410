import React from 'react';
import './spinner.css'
function AppSpinner() {
    return (
        <div className='spinner-wrapper'>
            <div class="loader">Loading...</div>
        </div>
    )
}

export default AppSpinner