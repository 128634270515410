import { LOADING, GET_SUB_CATS, GET_SUB_CAT_BY_ID, ADD_SUB_CAT, SUB_LOADING, GET_SUB_IN_MAIN} from '../actions/types'

const initialState = {
    subCategories: [],
    subCategory: [],
    subForMain: [],
    updated: [],
    loading: true
}

const subCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUB_CATS:
            return {
                ...state,
                subCategories: action.payload,
                loading: false,
                listOfSubCats: []
            };
        case GET_SUB_IN_MAIN:
            return {
                ...state,
                subForMain: action.payload,
                loading:false
            };
        case GET_SUB_CAT_BY_ID:
            return {
                ...state,
                subCategory: action.payload?.data?.category,
                loading: false
            };
        case ADD_SUB_CAT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case SUB_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default subCategoriesReducer;