import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addCity } from '../../redux/actions/citiesActions';


const AddCity = ({ setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [nameEn, setNameEn] = React.useState();
    const [nameAr, setNameAr] = React.useState();
    const [fastCharge, setFastCharge] = React.useState();
    const [normalCharge, setNormalCharge] = React.useState();
    const [cod, setCod] = React.useState();
    const [daysToShip, setDaysToShip] = React.useState();
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [bostaCode, setBostaCode] = React.useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addCity(nameEn, nameAr, fastCharge, normalCharge, cod, daysToShip, bostaCode, startDate, endDate))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-6'>
                    <label>{t("Category.NameAr")}</label>
                    <input
                        required
                        placeholder={t("Category.NameAr")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => setNameAr(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("Category.NameAr")}</label>
                    <input
                        required
                        placeholder={t("Category.NameEn")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setNameEn(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.FastCharge")}</label>
                    <input
                        required
                        placeholder={t("City.FastCharge")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setFastCharge(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.NormalCharge")}</label>
                    <input
                        required
                        placeholder={t("City.NormalCharge")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setNormalCharge(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.ShippingDays")}</label>
                    <input
                        required
                        placeholder={t("City.ShippingDays")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setDaysToShip(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.CashOnDelivery")}</label>
                    <input
                        required
                        placeholder={t("City.CashOnDelivery")}
                        type="number"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setCod(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.StartDate")}</label>
                    <input
                        required
                        placeholder={t("City.StartDate")}
                        type="date"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setStartDate(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-6'>
                    <label>{t("City.EndDate")}</label>
                    <input
                        required
                        placeholder={t("City.EndDate")}
                        type="date"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setEndDate(e.target.value)}
                    ></input>
                </div>
                <div className='col-sm-12'>
                    <label>{t("City.BostaCode")}</label>
                    <input
                        required
                        placeholder={t("City.BostaCode")}
                        type="text"
                        className="form-control app-input col-sm-6"
                        onChange={(e) => setBostaCode(e.target.value)}
                    ></input>
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default AddCity;