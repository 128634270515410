import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editCompetition } from '../../redux/actions/competitionsActions';


const EditQuestion = ({ setShow, id, row, setQuestion, compAfterEdit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [header, setHeader] = React.useState(row.header);
    const [answers, setAnswers] = React.useState(row.answers);
    const [answer, setAnswer] = React.useState();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editCompetition(id, compAfterEdit, 'Question has been edited'))
        setShow(1)
        setTimeout(() => {
            setShow(false)
        }, [])
    }
    const handleDeleteItem = (answ) => {
        setAnswers(answers.filter(val => val !== answ))
        console.log('New Arr', answers)
    }
    const handleCheckboxChange = (e) => {
            answers.map(correctAnswer => correctAnswer.content === e.target.value ? correctAnswer.is_right = true : setQuestion({ header: header, answers: answers, _id: row._id }))
            answers.map(correctAnswer => correctAnswer.content !== e.target.value ? correctAnswer.is_right = false : setQuestion({ header: header, answers: answers, _id: row._id }))
    }
    console.log(
        'COMPPPPP AFTER EDITT', compAfterEdit
    )
    const addToTodo = () => {
        if (answer === '') {
            return
        } else {
            answers.push(answer)
            console.log(answers)
            setAnswer('')
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-sm-12'>
                    <input
                        placeholder='Header'
                        type="text"
                        className="form-control app-input col-sm-6"
                        autoFocus
                        onChange={(e) => {
                            setHeader(e.target.value)
                            setQuestion({header: header, answers: answers, _id: row._id})
                        }}
                        defaultValue={header}
                    ></input>
                </div>
                <div className='add-input-wrapper'>
                    <input className='form-control app-input' fullWidth placeholder={t('Competitions.Answer')} value={answer?.content}
                        onChange={(e) => { setAnswer({ content: e.target.value }) }} autoComplete="nameAr" />
                    <button
                        id='addbutton'
                        className='add-answer-button'
                        type='button'
                        onClick={() => {
                            addToTodo();
                            setQuestion({ header: header, answers: answers });
                            setAnswer({ content: '' })
                        }}
                    >
                        {t('Actions.Add')}
                    </button>
                </div>
                <div>
                    {
                        answers?.map((answ) => {
                            return (
                                <span className='text-center' style={{ marginRight: '15px', fontSize: '16px' }}>
                                    {answ.content}
                                    <input value={answ.content} className='comp-checkbox' defaultChecked={answ.is_right? true : false}  type='radio' name='edit-ques' onClick={handleCheckboxChange} />
                                    <span onClick={() => { handleDeleteItem(answ) }}
                                        style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}> &times;
                                    </span>
                                </span>
                            )
                        })
                    }
                </div>
            </div>
            <button className='save-button' type='submit'>{t("Buttons.Save")}</button>
        </form>
    )
}

export default EditQuestion;