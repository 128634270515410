import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getCats = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/category/c/main?page=${currentPage}&limit=10`)
    .then((res) => {
      dispatch({
        type: types.CAT_LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_CAT,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getCatById = (id) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/category/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_CATEGORY_BY_ID,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addCat =
  (nameEn, nameAr, type, image, active, metaDescriptionEn, metaDescriptionAr, metaKeywordEn, metaKeywordAr) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("type", type);
    inputsData.append("active", active);
    inputsData.append("image", image);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);
    axios
      .post(`https://matjrna.co/api/v1/category`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_CAT,
          payload: res
        });
        myToast(lang === "ar" ? "تم إضافة قسم جديد" : "New category has been added");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const editCat =
  (
    id,
    nameEn,
    nameAr,
    type,
    image,
    active,
    oldImage,
    metaDescriptionEn,
    metaDescriptionAr,
    metaKeywordEn,
    metaKeywordAr
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("type", type);
    inputsData.append("active", active);
    inputsData.append("image", image);
    inputsData.append("old_image", oldImage);
    inputsData.append("metaDescriptionEn", metaDescriptionEn);
    inputsData.append("metaDescriptionAr", metaDescriptionAr);
    inputsData.append("metaKeywordEn", metaKeywordEn);
    inputsData.append("metaKeywordAr", metaKeywordAr);
    axios
      .patch(`https://matjrna.co/api/v1/category/${id}`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_CAT,
          payload: res
        });
        myToast(lang === "ar" ? "تم تعديل القسم" : "Category has been edited");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const deleteCat = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/category/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_CAT,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف القسم" : "Category has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
