import React from 'react';
import { MdEdit, MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal'
import EditCity from './EditCity';
import AppPagination from '../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getCities, deleteCity } from '../../redux/actions/citiesActions';
import AppSpinner from '../../components/Spinner/Spinner';
import DeleteModal from '../../components/DeleteModal';
import { useHistory, useParams } from 'react-router';
import { Hint } from '../../components/Tooltips';
const CitiesTabe = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {pageNum} = useParams();
    const dispatch = useDispatch();
    const cities = useSelector(state => state.cities.cities)
    const loading = useSelector(state => state.cities.loading)
    const updated = useSelector(state => state.cities.updated)
    const allCities = cities?.data?.cities;
    const pagesNum = cities?.pagesNum;
    const [id, setId] = React.useState(1)
    const [row, setRow] = React.useState();
    const [show, setShow] = React.useState();
    const [deleteShow, setDeleteShow] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(pageNum);
    const [paginated, setPaginated] = React.useState(false);
    React.useEffect(() => {
        if (paginated) {
            history.push(`/cities/${currentPage}`)
        }
    }, [currentPage, paginated, history])
    React.useEffect(() => {
        setCurrentPage(parseInt(pageNum))
    }, [pageNum, setCurrentPage])
    React.useEffect(() => {
        dispatch(getCities(currentPage))
    }, [currentPage, updated, dispatch])
    return (
       loading? <AppSpinner /> : 
            <>
                <table className="table table-hover">
                    <Modal
                        trueShow={show}
                        content={<EditCity row={row} id={id} setShow={setShow} />}
                        modalClosing={show}
                        modalTitle={t("Actions.Edit") + ' ' + t("Module.Cities")} />
                    <thead>
                        <tr>
                            <th scope="col">{t("Admin.Name")}</th>
                            <th scope="col">{t("City.FastCharge")}</th>
                            <th scope="col">{t("City.NormalCharge")}</th>
                            <th scope="col">{t("City.ShippingDays")}</th>
                            <th scope="col">{t("City.CashOnDelivery")}</th>
                            <th scope="col">{t("City.BostaCode")}</th>
                            <th scope="col">{t("Admin.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allCities?.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='table-cell' >{localStorage.getItem('i18nextLng') === 'en' ? row.nameEn : row.nameAr}</td>
                                        <td className='table-cell' >{row.fastCharge}</td>
                                        <td className='table-cell' >{row.normalCharge}</td>
                                        <td className='table-cell' >{row?.daysToShip}</td>
                                        <td className='table-cell' >{row.CODFee}</td>
                                        <td className='table-cell' >{row?.bostaCode}</td>
                                        <td className='table-cell' >
                                            <Hint title='EditCity' childs={
                                                <MdEdit onClick={() => {
                                                    setId(row._id)
                                                    setShow(1)
                                                    setRow(row)
                                                    setTimeout(() => {
                                                        setShow(true)
                                                    }, [])
                                                }} className='edit-icon-button' />
                                            } />
                                            {' '}
                                            <Hint title='DeleteCity' childs={
                                                <MdDelete onClick={() => {
                                                    setId(row._id)
                                                    setDeleteShow(true)
                                                }} className='delete-icon-button' />
                                            } />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        <DeleteModal show={deleteShow} setShow={setDeleteShow} content='Cities' deleteItem={deleteCity} itemId={id} />
                    </tbody>
                </table>
                <AppPagination paginated={paginated} setPaginated={setPaginated} currentPage={currentPage} setCurrentPage={setCurrentPage} pagesNum={pagesNum} />
            </>
    )
}

export default CitiesTabe;