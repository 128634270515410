import React from 'react';
import BasePage from '../../components/BasePage';
import AddCategory from './AddCategory';
import CategoriesTable  from './CategoriesContent';
import { useTranslation } from 'react-i18next';

const Categories = () => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState();
    return(
        <BasePage 
            modalContent={<AddCategory setShow={setShow} />} 
            childs={<CategoriesTable />} 
            pageTitle={t("SideBar.MainCategories")}
            buttonTitle={t("Labels.MainCategories")}
            addButton
            addModalClosing={show}
            modalTitle={t("Actions.Add") + ' ' + t("Labels.MainCategories")}
        />
    )
}

export default Categories;