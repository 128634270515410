import axios from "axios";
import { myError, myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getCoupons = (currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.co/api/v1/coupon?page=${currentPage}&sort=descending`)
    .then((res) => {
      dispatch({
        type: types.LOADING,
        payload: res.data
      });
      dispatch({
        type: types.GET_COUPONS,
        payload: res.data
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getCouponById = (id) => (dispatch) => {
  axios.get(`https://matjrna.co/api/v1/coupon/${id}`).then((res) => {
    dispatch({
      type: types.GET_COUPON_BY_ID,
      payload: res.data
    });
  });
};

export const addCoupon = (discountData) => (dispatch) => {
  axios
    .post(`https://matjrna.co/api/v1/coupon`, discountData)
    .then((res) => {
      dispatch({
        type: types.ADD_COUPON,
        payload: res
      });
      myToast(lang === "ar" ? "تم إضافة كود خصم جديد" : "New coupon has been added");
    })
    .catch((error) => myError(error.response.data.message));
};

export const editCoupon = (id, inputsData) => (dispatch) => {
  axios
    .patch(`https://matjrna.co/api/v1/coupon/${id}`, inputsData)
    .then((res) => {
      dispatch({
        type: types.ADD_COUPON,
        payload: res
      });
      myToast(lang === "ar" ? "تم تعديل كود الخصم" : "Coupon has been edited");
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteCoupon = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.co/api/v1/coupon/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_COUPON,
        payload: res
      });
      myToast(lang === "ar" ? "تم حذف كود الخصم" : "Coupon has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};
