import React from 'react'
import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser'

const ProductData = ({row}) => {
    const lang = localStorage.getItem('i18nextLng')
    const {t} = useTranslation();
    return(
        <div>
            <p className='product-title'>{lang === 'ar' ? row?.nameAr : row?.nameEn}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Price")}:</span> {row?.price} <span className='currency'>{t("Currency.Currency")}</span></p>
            <p className='product-property'><span className='propery-title'>{t("Product.Brand")}:</span> {lang === 'ar'? row?.brand?.nameAr :row?.brand?.nameEn}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Main")}:</span> {lang === 'ar'? row?.mainCategory?.nameAr :row?.mainCategory?.nameEn}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Sub")}:</span> {lang === 'ar'? row?.subCategory?.nameAr :row?.subCategory?.nameEn}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Colors")}:</span> {row?.color}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Sizes")}:</span> {row?.size}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Stocks")}:</span> {row?.stocks}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.VideoLink")}:</span> {row?.video}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.SellCode")}:</span> {row?.saleCode}</p>
            <p className='product-property'><span className='propery-title'>{t("Product.Description")}:</span> {
                row?.descriptionAr?.length > 2 ?
                    parse(lang === 'ar' ? row?.descriptionAr : row?.descriptionEn) : null
            }</p>
        </div>
    )
}

export default ProductData;