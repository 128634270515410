import React from 'react';
import BasePage from '../../components/BasePage';
import CustomersTable from '../Customers/CustomersContent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../redux/actions/usersActions';

const LatestCustomers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [paginated, setPaginated] = React.useState(false);
    const customers = useSelector(state => state.users.users)
    const loading = useSelector(state => state.users.loading)
    const updated = useSelector(state => state.users.updated)
    const allCustomers = customers?.data?.docs;
    const pagesNum = customers?.pagesNum;
    React.useEffect(() => {
        dispatch(getUsers(1, 5))
    }, [ updated, dispatch])
    return (
        <BasePage
            childs={<CustomersTable pagesNum={pagesNum} loading={loading} allCustomers={allCustomers} paginated={paginated} setPaginated={setPaginated} />}
            pageTitle={t('HomePage.LatestCustomers')}
            viewMore
            viewMorePath='customers/1'
        />
    )
}

export default LatestCustomers;