import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCartById } from '../../redux/actions/cartsActions';

const ViewCartContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {cartId} = useParams();
    const componentRef = React.useRef();
    const cart = useSelector(state => state?.carts?.cart)
    const updated = useSelector(state => state?.carts?.updated)
    const dummyObject = {};
    let arr = [];

    for (const key of Object.keys(cart?.items ? cart?.items : dummyObject)) {
        arr.push(cart?.items ? cart?.items[key] : null)
    }
  

    React.useEffect(() => {
        dispatch(getCartById(cartId))
    }, [updated, dispatch, cartId])
 
    
    return (
        <>
            <div className='view-order-body' ref={componentRef} >
                <div>
                    <h4 className='text-center bold mb-1'>{t("Order.CustomerInfo")}</h4>
                    <p className='mb-1 text-center'>Ahmed Hassan</p>
                    <p className='mb-1 text-center'>Ahmed7sn3amer@gmail.com</p>
                    <p className='mb-1 text-center'>+201068907939</p>
                    <p className='mb-1 text-center mb-3'><span>{new Date(cart?.createdAt).toDateString()}</span></p>

                    <div className='hr'></div>


                    <table class="table table-hover order-table">
                        <thead>
                            <tr>
                                <th scope="col">{t("Order.ItemName")}</th>
                                <th scope="col">{t("Product.SellCode")}</th>
                                <th scope="col">{t("Product.Sizes")}</th>
                                <th scope="col">{t("Order.Color")}</th>
                                <th scope="col">{t("Order.UnitPrice")}</th>
                                <th scope="col">{t("Order.Unit")}</th>
                                <th scope="col">{t("Order.Total")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                arr?.map((row, index) => {
                                    return(
                                        <tr>
                                            <td>{localStorage.getItem('i18nextLng') === 'en'? row?.item.nameEn : row?.item.nameAr}</td>
                                            <td>{row?.item.saleCode}</td>
                                            <td>{row?.item.size}</td>
                                            <td>{row?.item.color}</td>
                                            <td> {/* PRICE */}
                                                {row?.item?.discount ? (
                                                row?.item?.discountType === "cash" ? (
                                                    <>
                                                        <span style={{ color: "#999", fontSize: "10px" }}>
                                                            {(row?.item?.discount + row?.item?.price).toFixed(2)}
                                                        </span>
                                                        <span style={{ fontWeight: "bold", marginLeft: "2px" }}>
                                                            {row?.item?.price.toFixed(2)}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span style={{ color: "#999", fontSize: "10px" }}>
                                                            {(
                                                                (row?.item?.price * 100) /
                                                                (100 - row?.item?.discount)
                                                            ).toFixed(2)}
                                                        </span>
                                                        <span style={{ fontWeight: "bold", marginLeft: "2px" }}>
                                                            {row?.item?.price?.toFixed(2)}
                                                        </span>
                                                    </>
                                                )
                                            ) : (
                                                row?.item?.price?.toFixed(2)
                                            )}
                                            </td> {/* PRICE */}
                                            <td>{row?.quantity}</td>
                                            <td>{row?.price?.toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <div className='price-terms-wrapper-cart'>
                        <div className='center-children'>
                            <div className='prices-widget-cart'>
                                <div>
                                    <p className='mb-0'>{t("Carts.TotalPrice")}: {cart?.totalPrice} <span className='currency'>{t("Currency.Currency")}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewCartContent;